import styled from 'styled-components';

export const InfoBar = styled.div`
  position: fixed;
  bottom: 0;
  left: 59px;
  right: 0;
  z-index: 5;
  color: #fff;
  line-height: 40px;
  text-align: center;
  background: #e60000;
  cursor: pointer;
  padding: 0 20px;

  @media (max-width: 1200px) {
    left: 0;
  }
`;