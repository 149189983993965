import React, { useEffect, useCallback, useState, useRef, useContext } from 'react';

import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import '../../i18n';

import CardGroup from '../../components/CardGroup';
import CardIndicator from '../../components/CardIndicator';
import BackgroundNew from '../../components/BackgroundNew';
import FilterContainer from '../../components/FilterContainer';
import ComposedGraphBarLine from '../../components/ComposedGraphBarLine';
import LTVModalExplanation from '../../components/CustomModal/ModalLTVExplanation';
import ModalPageInformation from '../../components/CustomModal/ModalPageInformation';

import { Container } from './styles';

import { isValidAvailableFilter } from '../../utils/validationUtils';
import { DIMENSIONS_OPTONS} from '../../options/filterOptions';
import { getSelectedInitialDateRange, formatDate } from '../../utils/dateUtils';
import { formatValueToMoney, formatValueToNumber, formatValueToPercentage, customRound } from '../../utils/metricsUtils';

import {
    getCRMDailyMetrics,
    getCRMBigNumbersMetrics,
    getCRMAverageTimeBetweenPurchasesMetrics
} from '../../services/dashboardApiService';

import { AuthContext } from '../../contexts';

const AudienceOverview = () => {
    const { t } = useTranslation();
    const { timezone, currency } = useContext(AuthContext);

    const [dateRange, setDateRange] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [selectedDimension, setSelectedDimension] = useState(DIMENSIONS_OPTONS.day);
    const [crmData, setCRMData] = useState(null);
    const [bigNumbersData, setBigNumbersData] = useState(null);
    const [originsOptions, setOriginsOptions] = useState([]);
    const [salesChannelsOptions, setSalesChannelsOptions] = useState([]);
    const [vtexAffiliatesOptions, setVtexAffiliatesOptions] = useState([]);
    const [selectedOriginsOptions, setSelectedOriginsOptions] = useState([]);
    const [selectedSalesChannelsOptions, setSelectedSalesChannelsOptions] = useState([]);
    const [selectedVtexAffiliatesOptions, setSelectedVtexAffiliatesOptions] = useState([]);
    const [showLTVModalExplanation, setShowLTVModalExplanation] = useState(false);
    const [openModalInformation, setOpenModalInformation] = useState(false);

    const [newCustomerAndRebuyersRevenueShareGraphFullScreen, setNewCustomerAndRebuyersRevenueShareGraphFullScreen] = useState(false);
    const [newCustomersAndRebuyersRevenueGraphFullScreen, setNewCustomersAndRebuyersRevenueGraphFullScreen] = useState(false);
    const [newCustomersAndRebuyersQuantityGraphFullScreen, setNewCustomersAndRebuyersQuantityGraphFullScreen] = useState(false);
    const [ltvAndCACGraphFullScreen, setLtvAndCACGraphFullScreen] = useState(false);
    const [platformAverageTicketAndTimeBetweenPurchasesGraphFullScreen, setPlatformAverageTicketAndTimeBetweenPurchasesGraphFullScreen] = useState(false);

    const [graphDataLabelEnabled, setGraphDataLabelEnabled] = useState(false);

    const cardNewCustomersAndRebuyersRevenueRef = useRef(null);
    const cardNewCustomerAndRebuyersRevenueShareRef = useRef(null);
    const cardNewCustomersAndRebuyersQuantityRef = useRef(null);
    const cardLtvAndCACRef = useRef(null);
    const cardPlatformAverageTicketAndTimeBetweenPurchasesRef = useRef(null);


    const handleApplyFilter = async () => {
        const [startDate, endDate] = dateRange;
        const origins = selectedOriginsOptions.length > 0 ? [...selectedOriginsOptions] : null;
        const salesChannels = selectedSalesChannelsOptions.length > 0 ? [...selectedSalesChannelsOptions] : null;
        const vtexAffiliates = selectedVtexAffiliatesOptions.length > 0 ? [...selectedVtexAffiliatesOptions] : null;

        await getCRMDataMetrics(
            formatDate(startDate),
            formatDate(endDate),
            origins,
            salesChannels,
            vtexAffiliates
        );
    };

    const getCRMDataMetrics = useCallback(async (
        initialDate,
        finalDate,
        origins = null,
        salesChannels = null,
        vtexAffiliates = null
    ) => {
        setIsLoading(true);

        try {
            const responses = await Promise.all([
              getCRMAverageTimeBetweenPurchasesMetrics(
                initialDate,
                finalDate,
                timezone,
                origins,
                salesChannels,
                vtexAffiliates
              ),
              getCRMDailyMetrics(
                initialDate,
                finalDate,
                timezone,
                origins,
                salesChannels,
                vtexAffiliates
              ),
              getCRMBigNumbersMetrics(
                initialDate,
                finalDate,
                timezone,
                origins,
                salesChannels,
                vtexAffiliates
              )
            ]);

            const responseAverageTimeBetweenPurchases = responses[0];
            const responseDailyMetrics = responses[1];
            const responseBigNumbers = responses[2];

            if (!responseAverageTimeBetweenPurchases || !responseDailyMetrics || !responseBigNumbers) {
                setCRMData(null);
                setBigNumbersData(null);
                setOriginsOptions([]);
                setSalesChannelsOptions([]);
                setVtexAffiliatesOptions([]);

                return;
            }

            const allBigNumbers = {...responseBigNumbers, ...responseAverageTimeBetweenPurchases.big_numbers}
            setBigNumbersData({
                average_time_between_purchases: allBigNumbers.average_time_between_purchases,
                total_new_customers_count: allBigNumbers.new_customers.all_period.customers,
                total_rebuying_customers_count: allBigNumbers.rebuying_customers.all_period.customers,
                total_ltv: allBigNumbers.total_ltv,
                total_cac: allBigNumbers.total_cac,
                detail_calculation_indicators: allBigNumbers.detail_calculation_indicators
            });

            setOriginsOptions(responseDailyMetrics.available_origins_to_filter);
            setSalesChannelsOptions(responseDailyMetrics.available_marketplace_channels_to_filter);

            if (isValidAvailableFilter(responseDailyMetrics.available_affiliates_to_filter)) {
                setVtexAffiliatesOptions(responseDailyMetrics.available_affiliates_to_filter);
            } else {
                setVtexAffiliatesOptions([])
            }

            const unifiedData = getUnifiedData(
                responseDailyMetrics.results, 
                responseAverageTimeBetweenPurchases.results,
                allBigNumbers
            );
            setCRMData(unifiedData)
        } catch (error) {
            toast.error(error.message);
            setCRMData(null);
            setBigNumbersData(null);
            setOriginsOptions([]);
            setSalesChannelsOptions([]);
            setVtexAffiliatesOptions([]);
        } finally {
          setIsLoading(false);
        }
    }, [timezone]);

    const getUnifiedData = (
        crmDailyDataResults, 
        averageTimeBetweenPurchasesDailyResults, 
        crmBigNumbers
    ) => {
        const dailyData = [];

        const unifiedCrmDataGroupedByMonthly = {};
        const unifiedCrmDataGroupedByYearly = {};
        const rebuyingCustomersGroupedByMonthly = {};
        const newCustomersGroupedByMonthly = {};
        const rebuyingCustomersGroupedByYearly = {};
        const newCustomersGroupedByYearly = {};

        crmDailyDataResults.forEach(crmData => {
            const averageTimeBetweenPurchasesData = averageTimeBetweenPurchasesDailyResults.find(
                averageTimeBetweenPurchasesData => averageTimeBetweenPurchasesData.date === crmData.date
            );
            const dailyTotalRevenue = crmData.new_customers_revenue + crmData.rebuying_revenue;
            
            dailyData.push(
                {
                    period: crmData.date,
                    newCustomersRevenue: crmData.new_customers_revenue,
                    rebuyersRevenue: crmData.rebuying_revenue,
                    newCustomersAmountOrders: crmData.new_customers_amount_orders,
                    rebuyersAmountOrders: crmData.rebuying_amount_orders,
                    newCustomersQuantity: crmData.new_customers_count,
                    rebuyersQuantity: crmData.rebuying_count,
                    investment: crmData.investment,
                    ltv: crmData.ltv,
                    cac: crmData.cac,
                    platformAverageTicket: crmData.platform_average_ticket,
                    averageTimeBetweenPurchases: averageTimeBetweenPurchasesData.average_time_between_purchases,
                    ordersQuantityPerCustomer: crmData.orders_quantity_per_customer,
                    averageCustomerRelationship: crmData.average_customer_relationship,
                    rebuyersRevenuePercentage: dailyTotalRevenue > 0 ?  Number(crmData.rebuying_revenue / dailyTotalRevenue * 100).toFixed(2) : 0,
                    newCustomersRevenuePercentage: dailyTotalRevenue > 0 ? Number(crmData.new_customers_revenue / dailyTotalRevenue * 100).toFixed(2) : 0
                }
            );

            const createDefaultData = () => ({
                newCustomersRevenue: 0,
                rebuyersRevenue: 0,
                newCustomersAmountOrders: 0,
                rebuyersAmountOrders: 0,
                newCustomersQuantity: 0,
                rebuyersQuantity: 0,
                investment: 0.0,
                ltv: 0.0,
                cac: 0.0,
                platformAverageTicket: 0.0,
                averageTimeBetweenPurchases: [],
                ordersQuantityPerCustomer: 0.0,
                averageCustomerRelationship: 0.0,
                rebuyersRevenuePercentage: 0.0,
                newCustomersRevenuePercentage: 0.0,
            });
            
            const monthlyKey = crmData.date.substring(0, 7);
            if (!unifiedCrmDataGroupedByMonthly[monthlyKey]) {
                unifiedCrmDataGroupedByMonthly[monthlyKey] = {period: monthlyKey, ...createDefaultData()};
            }

            const yearlyKey = crmData.date.substring(0, 4);
            if (!unifiedCrmDataGroupedByYearly[yearlyKey]) {
                unifiedCrmDataGroupedByYearly[yearlyKey] = {period: yearlyKey, ...createDefaultData()};
            }

            unifiedCrmDataGroupedByMonthly[monthlyKey].investment += crmData.investment;
            unifiedCrmDataGroupedByMonthly[monthlyKey].averageCustomerRelationship = crmData.average_customer_relationship;
            unifiedCrmDataGroupedByMonthly[monthlyKey].ordersQuantityPerCustomer = crmData.orders_quantity_per_customer;
            unifiedCrmDataGroupedByMonthly[monthlyKey].averageTimeBetweenPurchases.push(averageTimeBetweenPurchasesData.average_time_between_purchases);

            unifiedCrmDataGroupedByYearly[yearlyKey].investment += crmData.investment;
            unifiedCrmDataGroupedByYearly[yearlyKey].averageCustomerRelationship = crmData.average_customer_relationship;
            unifiedCrmDataGroupedByYearly[yearlyKey].ordersQuantityPerCustomer = crmData.orders_quantity_per_customer;
            unifiedCrmDataGroupedByYearly[yearlyKey].averageTimeBetweenPurchases.push(averageTimeBetweenPurchasesData.average_time_between_purchases);
        });

        const groupByKey = (group, data, keyFormatter) => {
            data.forEach(data => {
                const key = keyFormatter(data);
                group[key] = data;
            });
        }

        const yearFormatter = (data) => `${data.year}`;
        const monthFormatter = (data) => `${data.year}-${String(data.month).padStart(2, '0')}`;

        groupByKey(rebuyingCustomersGroupedByMonthly, crmBigNumbers.rebuying_customers.monthly, monthFormatter);
        groupByKey(newCustomersGroupedByMonthly, crmBigNumbers.new_customers.monthly, monthFormatter);
        groupByKey(rebuyingCustomersGroupedByYearly, crmBigNumbers.rebuying_customers.yearly, yearFormatter);
        groupByKey(newCustomersGroupedByYearly, crmBigNumbers.new_customers.yearly, yearFormatter);

        const calculateAggregatedLTV = (
            platformAverageTicket,
            ordersQuantityPerCustomer,
            averageCustomerRelationship
        ) => {
            return averageCustomerRelationship >= 1
                ? Number((platformAverageTicket * ordersQuantityPerCustomer * averageCustomerRelationship)).toFixed(2)
                : Number((platformAverageTicket * ordersQuantityPerCustomer)).toFixed(2)
        }

        const finalizeData = (unifiedCrmDataGroup, rebuyingCustomersGrouped, newCustomersGrouped) => {
            Object.keys(unifiedCrmDataGroup).forEach(key => {
                const unifiedCrmData = unifiedCrmDataGroup[key];
                const rebuyingCustomersData = rebuyingCustomersGrouped[key];
                const newCustomersData = newCustomersGrouped[key];

                if (rebuyingCustomersData) {
                    unifiedCrmData.rebuyersRevenue = rebuyingCustomersData.revenue;
                    unifiedCrmData.rebuyersAmountOrders = rebuyingCustomersData.amount_orders;
                    unifiedCrmData.rebuyersQuantity = rebuyingCustomersData.customers;
                }

                if (newCustomersData) {
                    unifiedCrmData.newCustomersRevenue = newCustomersData.revenue;
                    unifiedCrmData.newCustomersAmountOrders = newCustomersData.amount_orders;
                    unifiedCrmData.newCustomersQuantity = newCustomersData.customers;
                }

                const totalRevenue = unifiedCrmData.newCustomersRevenue + unifiedCrmData.rebuyersRevenue;
                const totalOrders = unifiedCrmData.newCustomersAmountOrders + unifiedCrmData.rebuyersAmountOrders;
                unifiedCrmData.platformAverageTicket = totalOrders > 0 ? totalRevenue / totalOrders : 0.0;

                unifiedCrmData.cac = unifiedCrmData.newCustomersQuantity > 0 ? unifiedCrmData.investment / unifiedCrmData.newCustomersQuantity : 0.0;
                
                unifiedCrmData.averageTimeBetweenPurchases = customRound(unifiedCrmData.averageTimeBetweenPurchases.reduce((a, b) => a + b, 0) / unifiedCrmData.averageTimeBetweenPurchases.length);

                unifiedCrmData.ltv = calculateAggregatedLTV(
                    unifiedCrmData.platformAverageTicket,
                    unifiedCrmData.ordersQuantityPerCustomer,
                    unifiedCrmData.averageCustomerRelationship
                );

                unifiedCrmData.rebuyersRevenuePercentage = totalRevenue > 0 ? Number(unifiedCrmData.rebuyersRevenue / totalRevenue * 100).toFixed(2) : 0;
                unifiedCrmData.newCustomersRevenuePercentage = totalRevenue > 0 ? Number(unifiedCrmData.newCustomersRevenue / totalRevenue * 100).toFixed(2) : 0;
            });
        }

        finalizeData(unifiedCrmDataGroupedByMonthly, rebuyingCustomersGroupedByMonthly, newCustomersGroupedByMonthly);
        finalizeData(unifiedCrmDataGroupedByYearly, rebuyingCustomersGroupedByYearly, newCustomersGroupedByYearly);

        return {
            daily: dailyData,
            monthly: Object.values(unifiedCrmDataGroupedByMonthly),
            yearly: Object.values(unifiedCrmDataGroupedByYearly)
        };
    }

    const formatCRMDataForGraph = (data, dimension) => {
        if(!data) { return null}

        if (dimension === DIMENSIONS_OPTONS.day) {
            return data.daily;
        } else if (dimension === DIMENSIONS_OPTONS.month) {
            return data.monthly;
        } else if (dimension === DIMENSIONS_OPTONS.year) {
            return data.yearly;
        }
    };

    const getCardIndicatorData = (data) => {
        return {
          keys: ["average_time_between_purchases", "total_new_customers_count", "total_rebuying_customers_count", "total_ltv", "total_cac"],
          titles: {
            average_time_between_purchases: t('common.average_time_between_purchases'),
            total_new_customers_count: t('common.new_customers'),
            total_rebuying_customers_count: t('common.returning_customers'),
            total_ltv: t('common.ltv'),
            total_cac: t('common.cac')
          },
          types: {
            average_time_between_purchases: 'decimal',
            total_new_customers_count: 'decimal',
            total_rebuying_customers_count: 'decimal',
            total_ltv: 'currency',
            total_cac: 'currency'
          },
          displayFlexSpaceOcupation: {
            average_time_between_purchases: 1,
            total_new_customers_count: 1,
            total_rebuying_customers_count: 1,
            total_ltv: 1,
            total_cac: 1
          },
          metricInfoConfig: {
            average_time_between_purchases: {
              visible: false,
              onClick: null
            },
            total_new_customers_count: {
              visible: false,
              onClick: null
            },
            total_rebuying_customers_count: {
                visible: false,
                onClick: null
              },
            total_ltv: {
              visible: true,
              onClick: () => setShowLTVModalExplanation(true)
            },
            total_cac: {
              visible: false,
              onClick: null
            }
          },
          data: data
        }
    }

    const getSecondaryFilters = () => {
        return [
            {
                title: `${t('common.source')}`,
                options: originsOptions,
                value: selectedOriginsOptions,
                setValue: setSelectedOriginsOptions
            },
            {
                title: t('common.sales_channel'),
                options: salesChannelsOptions,
                value: selectedSalesChannelsOptions,
                setValue: setSelectedSalesChannelsOptions
            },
            {
                title: `${t('common.affiliates_vtex')}`,
                options: vtexAffiliatesOptions,
                value: selectedVtexAffiliatesOptions,
                setValue: setSelectedVtexAffiliatesOptions
            }
        ]
      }

      const onRenderComponent = useCallback(async () => {
        const { initialDateFilter, finalDateFilter } = getSelectedInitialDateRange();
        setDateRange([initialDateFilter, finalDateFilter]);

        await getCRMDataMetrics(
          formatDate(initialDateFilter),
          formatDate(finalDateFilter)
        );
      }, [getCRMDataMetrics]);

      useEffect(() => {
        onRenderComponent();
      }, [onRenderComponent]);

    return (
        <BackgroundNew
          titlePage={t('menu.audience_overview')}
          showInformation={true}
          informationTooltipTitle={t('common.click_to_learn_more')}
          informationOnClick={() => setOpenModalInformation(true)}
        >
            <FilterContainer
                selectedDateRange={dateRange}
                setDateRange={setDateRange}
                onClickFilter={handleApplyFilter}
                showSecondaryFilters={true}
                secondaryFilters={getSecondaryFilters()}
            />
            <CardIndicator
                data={getCardIndicatorData(bigNumbersData)}
                currency={currency}
                isLoading={isLoading}
            />
            <CardGroup
                title={t('audienceOverview.new_vs_returning_customers_revenue_platform')}
                tagIdentifierColor="#00CCAE"
                isLoading={isLoading}
                ref={cardNewCustomersAndRebuyersRevenueRef}
            >
                <ComposedGraphBarLine
                    xDataKey="period"
                    data={crmData}
                    dimension={selectedDimension}
                    showPrimaryLineYAxis={true}
                    showSecondaryLineYAxis={true}
                    primaryLineYAxisConfig={{
                        name: t('common.new_customers'),
                        dataKey: "newCustomersRevenue"
                    }}
                    secondaryLineYAxisConfig={{
                        name: t('common.returning_customers'),
                        dataKey: "rebuyersRevenue"
                    }}
                    formatData={formatCRMDataForGraph}
                    formaterLineValue={value => formatValueToMoney(value, currency)}
                    graphMarginConfig={{
                        top: 20,
                        right: 60,
                        left:0,
                        bottom: 20
                    }}
                    showDimensionController={true}
                    setSelectedDimension={setSelectedDimension}
                    disabledDimensionController={crmData === null || crmData.length === 0}
                    showFullScreenOption={true}
                    fullScreenEnabled={newCustomersAndRebuyersRevenueGraphFullScreen}
                    setFullScreenEnabled={setNewCustomersAndRebuyersRevenueGraphFullScreen}
                    showExportPDFButton={true}
                    graphComponentRefForPDF={cardNewCustomersAndRebuyersRevenueRef}
                    showDataLabel={true}
                    dataLabelEnabled={graphDataLabelEnabled}
                    setDataLabelEnabled={setGraphDataLabelEnabled}
                    lineDataLabelEnabled={true}
                    lineDataValueIsCurrency={true}
                />
            </CardGroup>
            <Container>
                <CardGroup
                    title={t('audienceOverview.share_new_vs_returning_customers_revenue_platform')}
                    tagIdentifierColor="#00CCAE"
                    isLoading={isLoading}
                    customClassName="cardGroupContainerFirst"
                    ref={cardNewCustomerAndRebuyersRevenueShareRef}
                >
                    <ComposedGraphBarLine
                        xDataKey="period"
                        data={crmData}
                        dimension={selectedDimension}
                        showPrimaryBarYAxis={true}
                        showSecondaryBarYAxis={true}
                        secondaryBarIsStacked={true}
                        primaryBarYAxisConfig={{
                            name: t('common.new_customers'),
                            dataKey: "newCustomersRevenuePercentage"
                        }}
                        secondaryBarYAxisConfig={{
                            name: t('common.returning_customers'),
                            dataKey: "rebuyersRevenuePercentage"
                        }}
                        formatData={formatCRMDataForGraph}
                        formaterBarValue={formatValueToPercentage}
                        graphMarginConfig={{
                            top: 20,
                            right: -30,
                            left: 5,
                            bottom: 20
                        }}
                        showDimensionController={true}
                        setSelectedDimension={setSelectedDimension}
                        disabledDimensionController={crmData === null || crmData.length === 0}
                        showFullScreenOption={true}
                        fullScreenEnabled={newCustomerAndRebuyersRevenueShareGraphFullScreen}
                        setFullScreenEnabled={setNewCustomerAndRebuyersRevenueShareGraphFullScreen}
                        showExportPDFButton={true}
                        graphComponentRefForPDF={cardNewCustomerAndRebuyersRevenueShareRef}
                        showDataLabel={true}
                        dataLabelEnabled={graphDataLabelEnabled}
                        setDataLabelEnabled={setGraphDataLabelEnabled}
                        barDataLabelEnabled={true}
                        barDataValueIsPercentage={true}
                    />
                </CardGroup>
                <CardGroup
                    title={t('audienceOverview.new_vs_returning_customers_quantity')}
                    tagIdentifierColor="#00CCAE"
                    isLoading={isLoading}
                    customClassName="cardGroupContainerSecond"
                    ref={cardNewCustomersAndRebuyersQuantityRef}
                >
                    <ComposedGraphBarLine
                        xDataKey="period"
                        data={crmData}
                        dimension={selectedDimension}
                        showPrimaryBarYAxis={true}
                        showSecondaryBarYAxis={true}
                        secondaryBarIsStacked={true}
                        primaryBarYAxisConfig={{
                            name: t('common.new_customers'),
                            dataKey: "newCustomersQuantity"
                        }}
                        secondaryBarYAxisConfig={{
                            name: t('common.returning_customers'),
                            dataKey: "rebuyersQuantity"
                        }}
                        formatData={formatCRMDataForGraph}
                        formaterBarValue={formatValueToNumber}
                        graphMarginConfig={{
                            top: 20,
                            right: -30,
                            left:0,
                            bottom: 20
                        }}
                        showDimensionController={true}
                        setSelectedDimension={setSelectedDimension}
                        disabledDimensionController={crmData === null || crmData.length === 0}
                        showFullScreenOption={true}
                        fullScreenEnabled={newCustomersAndRebuyersQuantityGraphFullScreen}
                        setFullScreenEnabled={setNewCustomersAndRebuyersQuantityGraphFullScreen}
                        height="44.0vh"
                        showExportPDFButton={true}
                        graphComponentRefForPDF={cardNewCustomersAndRebuyersQuantityRef}
                        showDataLabel={true}
                        dataLabelEnabled={graphDataLabelEnabled}
                        setDataLabelEnabled={setGraphDataLabelEnabled}
                        barDataLabelEnabled={true}
                    />
                </CardGroup>
            </Container>
            <Container>
                <CardGroup
                    title={t('audienceOverview.ltv_vs_cac')}
                    tagIdentifierColor="#00CCAE"
                    isLoading={isLoading}
                    customClassName="cardGroupContainerFirst"
                    ref={cardLtvAndCACRef}
                >
                    <ComposedGraphBarLine
                        xDataKey="period"
                        data={crmData}
                        dimension={selectedDimension}
                        showPrimaryBarYAxis={true}
                        showSecondaryLineYAxis={true}
                        primaryBarYAxisConfig={{
                            name: t('common.cac'),
                            dataKey: "cac"
                        }}
                        secondaryLineYAxisConfig={{
                            name: t('common.ltv'),
                            dataKey: "ltv"
                        }}
                        formatData={formatCRMDataForGraph}
                        formaterBarValue={value => formatValueToMoney(value, currency)}
                        formaterLineValue={value => formatValueToMoney(value, currency)}
                        graphMarginConfig={{
                            top: 20,
                            right: 50,
                            left:30,
                            bottom: 20
                        }}
                        showDimensionController={true}
                        setSelectedDimension={setSelectedDimension}
                        disabledDimensionController={crmData === null || crmData.length === 0}
                        showFullScreenOption={true}
                        fullScreenEnabled={ltvAndCACGraphFullScreen}
                        setFullScreenEnabled={setLtvAndCACGraphFullScreen}
                        showExportPDFButton={true}
                        graphComponentRefForPDF={cardLtvAndCACRef}
                        showDataLabel={true}
                        dataLabelEnabled={graphDataLabelEnabled}
                        setDataLabelEnabled={setGraphDataLabelEnabled}
                        barDataLabelEnabled={true}
                        barDataValueIsCurrency={true}
                        lineDataLabelEnabled={true}
                        lineDataValueIsCurrency={true}
                    />
                </CardGroup>
                <CardGroup
                    title={t('audienceOverview.average_ticket_vs_avg_time_between_purchases')}
                    tagIdentifierColor="#00CCAE"
                    isLoading={isLoading}
                    customClassName="cardGroupContainerSecond"
                    ref={cardPlatformAverageTicketAndTimeBetweenPurchasesRef}
                >
                    <ComposedGraphBarLine
                        xDataKey="period"
                        data={crmData}
                        dimension={selectedDimension}
                        showPrimaryBarYAxis={true}
                        showSecondaryLineYAxis={true}
                        secondaryLineYAxisConfig={{
                            name: t('audienceOverview.average_ticket_approved_platform'),
                            dataKey: "platformAverageTicket"
                        }}
                        primaryBarYAxisConfig={{
                            name: t('common.average_time_between_purchases'),
                            dataKey: "averageTimeBetweenPurchases"
                        }}
                        formatData={formatCRMDataForGraph}
                        formaterBarValue={formatValueToNumber}
                        formaterLineValue={value => formatValueToMoney(value, currency)}
                        graphMarginConfig={{
                            top: 20,
                            right: 40,
                            left:0,
                            bottom: 20
                        }}
                        showDimensionController={true}
                        setSelectedDimension={setSelectedDimension}
                        disabledDimensionController={crmData === null || crmData.length === 0}
                        showFullScreenOption={true}
                        fullScreenEnabled={platformAverageTicketAndTimeBetweenPurchasesGraphFullScreen}
                        setFullScreenEnabled={setPlatformAverageTicketAndTimeBetweenPurchasesGraphFullScreen}
                        showExportPDFButton={true}
                        graphComponentRefForPDF={cardPlatformAverageTicketAndTimeBetweenPurchasesRef}
                        showDataLabel={true}
                        dataLabelEnabled={graphDataLabelEnabled}
                        setDataLabelEnabled={setGraphDataLabelEnabled}
                        barDataLabelEnabled={true}
                        lineDataLabelEnabled={true}
                        lineDataValueIsCurrency={true}
                    />
                </CardGroup>
            </Container>
            <LTVModalExplanation
              open={showLTVModalExplanation}
              handleClose={() => setShowLTVModalExplanation(false)}
              ltvDetailCalculation={bigNumbersData ? bigNumbersData.detail_calculation_indicators.ltv : null}
            />
            <ModalPageInformation
                title={t('audienceOverview.understand_audience_overview_analysis')}
                open={openModalInformation}
                handleClose={() => setOpenModalInformation(false)}
                videoUrl={"https://www.youtube.com/embed/b7zFJsMZ6yM?si=tRBZ3IdaO0VfQZsR"}
            />
        </BackgroundNew>
    );
}

export default AudienceOverview;
