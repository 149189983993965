
export const sortTableData = (rows, column, orderDirection) => {
    const isAscending = orderDirection === 'asc';
    return [...rows].sort((a, b) => {
        const valueA = a[column] || '';
        const valueB = b[column] || '';

        if (typeof valueA === 'string' && typeof valueB === 'string') {
            return isAscending 
                ? valueA.localeCompare(valueB, undefined, { sensitivity: 'base' })
                : valueB.localeCompare(valueA, undefined, { sensitivity: 'base' });
        }

        if (valueA === valueB) {
            return 0;
        }
        
        return isAscending 
            ? (valueA > valueB ? 1 : -1)
            : (valueA > valueB ? -1 : 1);
    });
};
