import React, { useState, useEffect, useRef, useCallback, useContext } from "react";

import { useTranslation } from "react-i18next";
import "../../../i18n";

import { FiSearch } from "react-icons/fi";
import { IoIosCloseCircle, IoIosArrowForward } from "react-icons/io";

import connectorLastSearchIcon from "../../../assets/connectors_last_search.svg";
import settingsIcon from "../../../assets/settings.svg";
import notificationIcon from "../../../assets/notification.svg";
import originalBisoLogo from "../../../assets/originalLogo.svg";
import { ReactComponent as MobileMenuIcon } from "../../../assets/menu.svg";

import { BisoTooltip } from "../../Tooltip";

import { routes } from "../../../routes";

import {
  AppBarContainer,
  SearchBar,
  SearchInput,
  ActionContainer,
  LastScreenButton,
  IconContainer,
  IconImage,
  Profile,
  SettingsMenu,
  SettingsMenuListItems,
  SettingsMenuItem,
  SearchContentResults,
  SearchContentResultsListDescription,
  SearchContentResultsList,
  SearchContentResultsItem,
  SearchContentResultsItemDescription,
  SearchContentResultsItemOptionSubDescription,
  ButtonMobileMenu,
  LogoMobileMenu,
} from "./styled";
import SelectCompany from "../SelectCompany";
import { AuthContext } from "../../../contexts";

const AppBar = ({
  mobileModeActive,
  isExpanded,
  setIsExpanded,
  menuExpandedWidth,
  menuWidth,
  isCurrentRoute,
  isAnyCurrentRouteMatching,
  navigateToPage,
  returnToLastScreen,
  profileInitials,
  pageSettings,
  lastScreenName,
}) => {
  const { isCompanyOwnerUser } = useContext(AuthContext);
  const { t } = useTranslation();
  const [settingsMenuVisible, setSettingsMenuVisible] = useState(false);
  const [settingsMenuPosition, setSettingsMenuPosition] = useState({ top: 0, left: 0 });
  const settingsMenuRef = useRef(null);
  const settingsIconRef = useRef(null);

  const [searchBarValue, setSearchBarValue] = useState("");
  const [searchBarContentResults, setSearchBarContentResults] = useState([]);
  const [searchBarContentResultsVisible, setSearchBarContentResultsVisible] = useState(false);
  const [searchBarPosition, setSearchBarPosition] = useState({ top: 0, left: 0 });
  const searchBarRef = useRef(null);
  const searchBarContentResultsRef = useRef(null);

  const searchPagesResults = useCallback(
    (searchValue) => {
      const searchResults = Object.values(pageSettings).filter((page) => {
        if (
          page.name.toLowerCase().includes(searchValue.toLowerCase()) ||
          page.hierarchyDetails.toLowerCase().includes(searchValue.toLowerCase())
        )
          return true;

        if (page.keywords) {
          for (let keyword of page.keywords) {
            if (keyword.includes(searchValue.toLowerCase())) {
              return true;
            }
          }
        }

        return false;
      });
      setSearchBarContentResults(searchResults);
    },
    [pageSettings]
  );

  const toggleSearchBar = () => {
    if (searchBarRef.current) {
      const rect = searchBarRef.current.getBoundingClientRect();
      setSearchBarPosition({ top: rect.bottom + window.scrollY, left: rect.left + window.scrollX });
    }
    setSearchBarContentResultsVisible(true);
  };

  const toggleSettingsMenu = () => {
    if (settingsIconRef.current) {
      const rect = settingsIconRef.current.getBoundingClientRect();
      setSettingsMenuPosition({ top: rect.bottom + window.scrollY, left: rect.left + window.scrollX });
    }
    setSettingsMenuVisible(!settingsMenuVisible);
  };

  const handleClickOutside = (event) => {
    if (settingsMenuRef && settingsMenuRef.current && !settingsMenuRef.current.contains(event.target)) {
      setSettingsMenuVisible(false);
    }

    if (
      searchBarContentResultsRef &&
      searchBarContentResultsRef.current &&
      !searchBarContentResultsRef.current.contains(event.target)
    ) {
      setSearchBarContentResultsVisible(false);
    }
  };

  const onClickNavigateTo = (route) => {
    navigateToPage(route);

    setSettingsMenuVisible(false);
    setSearchBarContentResultsVisible(false);
    setSearchBarValue("");
  };

  useEffect(() => {
    if (searchBarValue) {
      searchPagesResults(searchBarValue);
    }
  }, [searchBarValue, searchPagesResults]);

  useEffect(() => {
    if (settingsMenuVisible || searchBarContentResultsVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [settingsMenuVisible, searchBarContentResultsVisible]);

  return (
    <>
      <AppBarContainer
        isExpanded={isExpanded}
        expandedWidth={menuExpandedWidth}
        normalWidth={menuWidth}
        isMobile={mobileModeActive}
      >
        {mobileModeActive && (
          <ButtonMobileMenu onClick={() => setIsExpanded(!isExpanded)} style={{ marginRight: 56 }}>
            <MobileMenuIcon />
          </ButtonMobileMenu>
        )}
        {mobileModeActive && <LogoMobileMenu src={originalBisoLogo} alt="Biso" />}
        {!mobileModeActive && (
          <SearchBar isExpanded={isExpanded} ref={searchBarRef}>
            <FiSearch color="#6F767E" size={19} />
            <SearchInput
              type="text"
              placeholder={t("appBar.find_desired_option")}
              value={searchBarValue}
              onChange={(e) => setSearchBarValue(e.target.value)}
              onFocus={toggleSearchBar}
              onClick={toggleSearchBar}
            />
            {searchBarValue && (
              <IoIosCloseCircle
                style={{
                  cursor: "pointer",
                  marginLeft: "10px",
                }}
                color="#9A9FA5"
                size={19}
                onClick={(_) => setSearchBarValue("")}
              />
            )}
          </SearchBar>
        )}

        <ActionContainer isMobile={mobileModeActive}>
          <SelectCompany isMobile={mobileModeActive} />
          {!mobileModeActive && (
            <BisoTooltip title={t("appBar.go_to_last_accessed_screen")}>
              <LastScreenButton onClick={returnToLastScreen} disabled={!lastScreenName}>
                {lastScreenName ? lastScreenName : t("appBar.last_accessed_screen")}
              </LastScreenButton>
            </BisoTooltip>
          )}
          <BisoTooltip title={t("appBar.settings")}>
            <IconContainer
              isSelected={isAnyCurrentRouteMatching([routes.connectionsAndChannels, routes.users])}
              isMobile={mobileModeActive}
              onClick={toggleSettingsMenu}
              ref={settingsIconRef}
            >
              <IconImage isMobile={mobileModeActive} src={settingsIcon} alt="Settings" />
            </IconContainer>
          </BisoTooltip>
          <BisoTooltip title={t("appBar.view_notifications")}>
            <IconContainer
              isSelected={isCurrentRoute(routes.notifications)}
              isMobile={mobileModeActive}
              onClick={(_) => onClickNavigateTo(routes.notifications)}
            >
              <IconImage isMobile={mobileModeActive} src={notificationIcon} alt="Notifications" />
            </IconContainer>
          </BisoTooltip>
          {!mobileModeActive && (
            <>
              <BisoTooltip title={t("appBar.view_profile")}>
                <Profile
                  isSelected={isCurrentRoute(routes.profile)}
                  onClick={(_) => onClickNavigateTo(routes.profile)}
                >
                  {profileInitials}
                </Profile>
              </BisoTooltip>
              <BisoTooltip title={t("appBar.data_update")}>
                <IconContainer
                  isSelected={isCurrentRoute(routes.dataSyncInfo)}
                  onClick={(_) => onClickNavigateTo(routes.dataSyncInfo)}
                >
                  <IconImage
                    style={{
                      width: "40px",
                      height: "40px",
                    }}
                    src={connectorLastSearchIcon}
                    alt="Data sync"
                  />
                </IconContainer>
              </BisoTooltip>
            </>
          )}
        </ActionContainer>
      </AppBarContainer>
      {settingsMenuVisible && (
        <SettingsMenu
          ref={settingsMenuRef}
          style={{
            top: `${settingsMenuPosition.top + 20}px`,
            left: `${settingsMenuPosition.left - (mobileModeActive ? 65 : 55)}px`,
          }}
        >
          <SettingsMenuListItems>
            {isCompanyOwnerUser && (
              <SettingsMenuItem
                isSelected={isCurrentRoute(routes.users)}
                onClick={() => onClickNavigateTo(routes.users)}
              >
                {t("appBar.manage_users")}
              </SettingsMenuItem>
            )}
            <SettingsMenuItem
              isSelected={isCurrentRoute(routes.connectionsAndChannels)}
              onClick={() => onClickNavigateTo(routes.connectionsAndChannels)}
            >
              {t("appBar.connections_and_channels")}
            </SettingsMenuItem>
            <SettingsMenuItem
              isSelected={isCurrentRoute(routes.notificationManagement)}
              onClick={() => onClickNavigateTo(routes.notificationManagement)}
            >
              {t("appBar.notification_management")}
            </SettingsMenuItem>
            <SettingsMenuItem
              isSelected={isCurrentRoute(routes.preferences)}
              onClick={() => onClickNavigateTo(routes.preferences)}
            >
              {t("appBar.preferences")}
            </SettingsMenuItem>
          </SettingsMenuListItems>
        </SettingsMenu>
      )}
      {searchBarValue && searchBarContentResultsVisible && (
        <SearchContentResults
          ref={searchBarContentResultsRef}
          style={{ top: `${searchBarPosition.top + 5}px`, left: `${searchBarPosition.left}px` }}
        >
          <SearchContentResultsList>
            <SearchContentResultsListDescription>{t("appBar.go_to")}</SearchContentResultsListDescription>
            {searchBarContentResults &&
              searchBarContentResults.map((page) => (
                <SearchContentResultsItem key={page.id} onClick={() => onClickNavigateTo(page.route)}>
                  <SearchContentResultsItemDescription>
                    {page.name}
                    {page.hierarchyDetails && (
                      <SearchContentResultsItemOptionSubDescription>
                        {page.hierarchyDetails}
                      </SearchContentResultsItemOptionSubDescription>
                    )}
                  </SearchContentResultsItemDescription>
                  <IoIosArrowForward color="#9A9FA5" style={{ marginLeft: "5px" }} size={20} />
                </SearchContentResultsItem>
              ))}
            {searchBarContentResults && searchBarContentResults.length === 0 && (
              <SearchContentResultsItem>{t("appBar.no_results_found")}</SearchContentResultsItem>
            )}
          </SearchContentResultsList>
        </SearchContentResults>
      )}
    </>
  );
};

export default AppBar;
