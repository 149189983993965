import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import '../../../i18n';

import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Paper, TableSortLabel } from '@mui/material';
import { ContainerCategoryTable } from './styles';
import GraphController from '../../../components/GraphController';

import { IoIosArrowDown } from "react-icons/io";
import { MdSubdirectoryArrowRight } from "react-icons/md";
import { sortTableData } from '../../../utils/sortTableDataUtils';
import { formatValueToMoney, formatValueToNumber, formatValueToPercentage } from '../../../utils/metricsUtils';

const CustomCategoryTable = ({
  data,
  disabledDimensionController,
  showFullScreenOption = false,
  fullScreenEnabled = false,
  setFullScreenEnabled,
  defineComparativeValueColor,
  formatComparativeValue,
  handleExportCSVReport,
  showExportCSVReportButton,
  loadingExportCSVReportButton,
  currency,
  isSortable = true,
  columnSortConfig = {},
}) => {
  const { t } = useTranslation();

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [expandedCategories, setExpandedCategories] = useState([]);
  const [orderDirection, setOrderDirection] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  

  const handleSort = (property) => {
    const canNotSort = columnSortConfig[property] === false;
    if (!isSortable || canNotSort) return;

    const isAsc = orderBy === property && orderDirection === 'asc';
    setOrderDirection(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedData = isSortable
    ? data
      ? sortTableData(data.filter((item) => !item.parent_category), orderBy, orderDirection)
      : []
    : data;


  const handleCategoryClick = (category) => {
    if (expandedCategories.includes(category)) {
      setExpandedCategories(expandedCategories.filter((cat) => cat !== category));
    } else {
      setExpandedCategories([...expandedCategories, category]);
    }

    setSelectedCategory(category);
  };

  const renderSubcategories = (subcategories, depth = 0) => {
    if (!subcategories || subcategories.length === 0) {
      return null;
    }

    return (
      <>
        {subcategories.map((subcategory, index) => (
          <React.Fragment key={index}>
            <TableRow onClick={() => handleCategoryClick(subcategory)} style={{ cursor: 'pointer', backgroundColor: selectedCategory === subcategory ? '#F4F4F4' : '' }}>
                <TableCell
                  style={{ paddingLeft: `${depth * 20}px`, display: "flex", alignItems: "center" }}>
                    <MdSubdirectoryArrowRight size={15}/> {subcategory.name} {subcategory.sub_categories.length > 0 ? <IoIosArrowDown style={{marginLeft: "5px"}} size={15}/> : ""}
                </TableCell>
                <TableCell>{formatValueToMoney(subcategory.approved_revenue, currency)}</TableCell>
                <TableCell
                  style={{color: defineComparativeValueColor(subcategory.comparative_approved_revenue_previous_period)}}
                >
                  {formatComparativeValue(subcategory.comparative_approved_revenue_previous_period)}
                </TableCell>
                <TableCell
                  style={{color: defineComparativeValueColor(subcategory.comparative_approved_revenue_previous_year)}}
                >
                  {formatComparativeValue(subcategory.comparative_approved_revenue_previous_year)}
                </TableCell>
                <TableCell>{formatValueToPercentage(subcategory.approved_revenue_share)}</TableCell>
                <TableCell>{formatValueToNumber(subcategory.sold_quantity)}</TableCell>
                <TableCell>{formatValueToNumber(subcategory.sessions)}</TableCell>
                {/* <TableCell>{formatValueToPercentage(subcategory.conversion_rate)}</TableCell> */}
                <TableCell>{formatValueToNumber(subcategory.stock_quantity)}</TableCell>
                <TableCell>{formatValueToNumber(subcategory.stock_reserved_quantity)}</TableCell>
                <TableCell>{formatValueToPercentage(subcategory.margin_percentage)}</TableCell>
            </TableRow>
            {expandedCategories.includes(subcategory) && renderSubcategories(subcategory.sub_categories, depth + 1)}
          </React.Fragment>
        ))}
      </>
    );
  };

    const stylesComponents = {
        mainContainerFullScreen: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100vh',
            backgroundColor: 'white',
            zIndex: 1200
        },
        tableGroupFullScreen: {
            padding: '20px'
        },
        tableContainerFullScreen: {
            maxHeight: 'calc(100vh - 200px)',
        },
        tableFooter: {
            width: '100%',
            height: '30px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '20px'
        }
    }

  return (
    <ContainerCategoryTable style={fullScreenEnabled ? { ...stylesComponents.mainContainerFullScreen } : {}}>
      <div className="tableGroup" style={fullScreenEnabled ? { ...stylesComponents.tableGroupFullScreen } : {}}>
        <GraphController
          disabled={disabledDimensionController}
          showDimensionController={false}
          showFullScreenOption={showFullScreenOption}
          fullScreenEnabled={fullScreenEnabled}
          setFullScreenEnabled={setFullScreenEnabled}
          style={{ marginRight: '0px' }}
          handleExportCSVReport={handleExportCSVReport}
          showExportCSVReportButton={showExportCSVReportButton}
          loadingExportCSVReportButton={loadingExportCSVReportButton}  
        />
        <TableContainer component={Paper} className="tableContainer" style={fullScreenEnabled ? { ...stylesComponents.tableContainerFullScreen } : {}}>
          <Table className="table">
            <TableHead className="stickyHeader">
              <TableRow>
                <TableCell style={{ minWidth: 200, fontWeight: 'bold' }}>
                  <TableSortLabel
                    active={orderBy === 'name'}
                    direction={orderDirection}
                    onClick={() => handleSort('name')}
                    disabled={!isSortable || columnSortConfig.name === false}
                  >
                    {t('productPerformance.category')}
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ minWidth: 150, fontWeight: 'bold' }}>
                <TableSortLabel
                    active={orderBy === 'approved_revenue'}
                    direction={orderDirection}
                    onClick={() => handleSort('approved_revenue')}
                    disabled={!isSortable || columnSortConfig.approved_revenue === false}
                  >
                    {t('common.approved_revenue')}
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ minWidth: 170, fontWeight: 'bold' }}>
                <TableSortLabel
                    active={orderBy === 'comparative_approved_revenue_previous_period'}
                    direction={orderDirection}
                    onClick={() => handleSort('comparative_approved_revenue_previous_period')}
                    disabled={!isSortable || columnSortConfig.comparative_approved_revenue_previous_period === false}
                  >
                    {t('productPerformance.approved_revenue_previous_period_percent')}
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ minWidth: 170, fontWeight: 'bold' }}>
                <TableSortLabel 
                    active={orderBy === 'comparative_approved_revenue_previous_year'}
                    direction={orderDirection}
                    onClick={() => handleSort('comparative_approved_revenue_previous_year')}
                    disabled={!isSortable || columnSortConfig.comparative_approved_revenue_previous_year === false}
                  >
                    {t('productPerformance.approved_revenue_previous_year_percent')}
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ minWidth: 100, fontWeight: 'bold' }}>
                  <TableSortLabel
                    active={orderBy === 'approved_revenue_share'}
                    direction={orderDirection}
                    onClick={() => handleSort('approved_revenue_share')}
                    disabled={!isSortable || columnSortConfig.approved_revenue_share === false}
                  >
                    {t('productPerformance.representativeness')}
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ minWidth: 100, fontWeight: 'bold' }}>
                  <TableSortLabel 
                    active={orderBy === 'sold_quantity'}
                    direction={orderDirection}
                    onClick={() => handleSort('sold_quantity')}
                    disabled={!isSortable || columnSortConfig.sold_quantity === false}
                  >
                    {t('productPerformance.quantity_sold')}
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ minWidth: 100, fontWeight: 'bold' }}>
                  <TableSortLabel 
                    active={orderBy === 'sessions'}
                    direction={orderDirection}
                    onClick={() => handleSort('sessions')}
                    disabled={!isSortable || columnSortConfig.sessions === false}
                  >
                    {t('common.sessions')}
                  </TableSortLabel>
                </TableCell>
                {/* <TableCell style={{ minWidth: 100, fontWeight: 'bold' }}>{t('common.conversion_rate')}</TableCell> */}
                <TableCell style={{ minWidth: 100, fontWeight: 'bold' }}>
                  <TableSortLabel 
                    active={orderBy === 'stock_quantity'}
                    direction={orderDirection}
                    onClick={() => handleSort('stock_quantity')}
                    disabled={!isSortable || columnSortConfig.stock_quantity === false}
                  >
                    {t('productPerformance.stock')}
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ minWidth: 100, fontWeight: 'bold' }}>
                  <TableSortLabel 
                    active={orderBy === 'stock_reserved_quantity'}
                    direction={orderDirection}
                    onClick={() => handleSort('stock_reserved_quantity')}
                    disabled={!isSortable || columnSortConfig.stock_reserved_quantity === false}
                  >
                    {t('productPerformance.reserved_stock')}
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ minWidth: 100, fontWeight: 'bold' }}>
                  <TableSortLabel 
                    active={orderBy === 'margin_percentage'}
                    direction={orderDirection}
                    onClick={() => handleSort('margin_percentage')}
                    disabled={!isSortable || columnSortConfig.margin_percentage === false}
                  >
                    {t('productPerformance.margin')}
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="tableBody">
              {sortedData.map((category, index) => (
                <React.Fragment key={index}>
                  <TableRow onClick={() => handleCategoryClick(category)} style={{ cursor: 'pointer', backgroundColor: selectedCategory === category ? '#F4F4F4' : '' }}>
                    <TableCell className='firstTableCell'>{category.name} {category.sub_categories.length > 0 ? <IoIosArrowDown style={{marginLeft: "5px"}} size={15}/> : ""}</TableCell>
                    <TableCell>{formatValueToMoney(category.approved_revenue, currency)}</TableCell>
                    <TableCell style={{color: defineComparativeValueColor(category.comparative_approved_revenue_previous_period)}}
                    >
                      {formatComparativeValue(category.comparative_approved_revenue_previous_period)}
                    </TableCell>
                    <TableCell
                      style={{color: defineComparativeValueColor(category.comparative_approved_revenue_previous_year)}}
                    >
                      {formatComparativeValue(category.comparative_approved_revenue_previous_year)}
                    </TableCell>
                    <TableCell>{formatValueToPercentage(category.approved_revenue_share)}</TableCell>
                    <TableCell>{formatValueToNumber(category.sold_quantity)}</TableCell>
                    <TableCell>{formatValueToNumber(category.sessions)}</TableCell>
                    {/* <TableCell>{formatValueToPercentage(category.conversion_rate)}</TableCell> */}
                    <TableCell>{formatValueToNumber(category.stock_quantity)}</TableCell>
                    <TableCell>{formatValueToNumber(category.stock_reserved_quantity)}</TableCell>
                    <TableCell>{formatValueToPercentage(category.margin_percentage)}</TableCell>
                  </TableRow>
                  {expandedCategories.includes(category) && renderSubcategories(category.sub_categories, 2)}
                </React.Fragment>
              ))}
              {(!data || data.length === 0) && (
                  <TableRow key={0}>
                      <TableCell colSpan={10} style={{ textAlign: 'center' }}>{t('common.no_data')}</TableCell>
                  </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </ContainerCategoryTable>
  );
};

export default CustomCategoryTable;
