import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import Slide from "@mui/material/Slide";
import * as S from "./styles";
import BackgroundNew from "../../components/BackgroundNew";
import Loader from "../../components/Loader";
import { updateNotificationSetting, getCurrentUser } from "../../services/odinService"; 
import { useTranslation } from "react-i18next";
import "../../i18n";
import {  Campaign, BarChart, Insights } from '@mui/icons-material';
import {  AiFillFunnelPlot, AiFillProduct } from "react-icons/ai";
import { RiStockFill } from "react-icons/ri";
import { PiChartScatterBold } from "react-icons/pi";

const notificationIcons = {
    product_analysis_report: <AiFillProduct />,
    cro_analysis_report: <AiFillFunnelPlot />,
    campaign_media_analysis_report: <Campaign />,
    campaigns_analysis_report: <BarChart />,
    minimum_stock_analysis_report: <RiStockFill />,
    biso_insights: <Insights />,
    anomaly_detector: <PiChartScatterBold />,
  };

const notificationLabels = {
  product_analysis_report: "notificationManagement.smart_report_product_performance_title",
  cro_analysis_report: "notificationManagement.smart_report_cro_title",
  campaign_media_analysis_report: "notificationManagement.smart_report_campaign_media_title",
  campaigns_analysis_report: "notificationManagement.smart_report_campaign_title",
  minimum_stock_analysis_report: "notificationManagement.smart_report_minimum_stock_title",
  biso_insights: "notificationManagement.biso_insights_title",
  anomaly_detector: "notificationManagement.anomaly_detector_title",
};
const notificationDescription = {
    product_analysis_report: "notificationManagement.smart_report_product_performance_description",
    cro_analysis_report: "notificationManagement.smart_report_cro_description",
    campaign_media_analysis_report: "notificationManagement.smart_report_campaign_media_description",
    campaigns_analysis_report: "notificationManagement.smart_report_campaign_description",
    minimum_stock_analysis_report: "notificationManagement.smart_report_minimum_stock_description",
    biso_insights: "notificationManagement.biso_insights_description",
    anomaly_detector: "notificationManagement.anomaly_detector_description",
  };

  const notificationOrder = [
    "campaigns_analysis_report",
    "campaign_media_analysis_report",
    "cro_analysis_report",
    "product_analysis_report",
    "minimum_stock_analysis_report" 
  ];

function NotificationManagement() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const [notificationSettings, setNotificationSettings] = useState({});
  const [isUpdating, setIsUpdating] = useState(null); 

  const listNotifications = useCallback(async () => {
    setIsLoading(true);

    const response = await getCurrentUser(); 
    setNotificationSettings(response?.settings?.notification_settings || {});
  
    setIsLoading(false);
    
  }, []); 

  useEffect(() => {
    listNotifications();
  }, [listNotifications]);

  async function handleToggleNotification(notificationKey, isActive) {
    setIsUpdating(notificationKey);
  
    try {
      const updatedSettings = {
        ...notificationSettings,
        [notificationKey]: !isActive, 
      };
  
      await updateNotificationSetting(updatedSettings);
  
      setNotificationSettings(updatedSettings);
      toast.success(
        t("toast.notification_management")+(` ${!isActive ? t("toast.notification_active") : t("toast.notification_inactive")} ${t("toast.notification_success")}`)
      );

    } catch (error) {
      toast.error(t("toast.error_activating_notification"));
    } finally {
      setIsUpdating(null);
    }
  }

  return (
    <BackgroundNew
      titlePage={t("notificationManagement.notification_management")}
      subTitlePage={t("notificationManagement.notifications_manage")}
    >
      <S.Container>
        <div className="workSpace">
          {isLoading ? (
            <div className="loading">
              <Loader />
            </div>
          ) : (
            notificationSettings && Object.keys(notificationSettings).length > 0 && (
              Object.keys(notificationSettings)
                .sort((a, b) => notificationOrder.indexOf(a) - notificationOrder.indexOf(b))
                .map((key, index) => (
                  <Slide direction="up" in mountOnEnter unmountOnExit key={index}>
                    <div className="option">
                      <div className="notification">
                        <p className="word">{key.substring(0, 1)}</p>
                        <div className="icon">{notificationIcons[key]}</div>
                        <div className="descriptionOption">
                          <p className="titleOption">{t(notificationLabels[key])}</p>
                          <p className="description">{t(notificationDescription[key])}</p>
                        </div>
                      </div>
                      <div className="functions">
                        <div className="toggle-wrapper">
                          {isUpdating === key ? (
                            <Loader size={25}n containerStyles={{ width: "auto", height: "auto" }} />
                          ) : (
                            <S.OnOff
                              checked={notificationSettings[key]}
                              onChange={() => handleToggleNotification(key, notificationSettings[key])}
                              disabled={isUpdating === key}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </Slide>
                ))
            )
          )}
        </div>
      </S.Container>
    </BackgroundNew>
  );
}

export default NotificationManagement;
