import React, { useEffect, useCallback, useState, useRef, useContext } from 'react';

import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import '../../i18n';

import BackgroundNew from '../../components/BackgroundNew';
import FilterContainer from '../../components/FilterContainer';
import CardGroup from '../../components/CardGroup';
import ComposedGraphBarLine from '../../components/ComposedGraphBarLine';
import CardIndicator from '../../components/CardIndicator';
import ModalPageInformation from '../../components/CustomModal/ModalPageInformation';

import { Container } from './styles';

import { DIMENSIONS_OPTONS, paidMediaChannelsOptions} from '../../options/filterOptions';
import { getSelectedInitialDateRange, formatDate, getAgregatedKeyByDimension } from '../../utils/dateUtils';
import { formatValueToMoney, formatValueToPercentage, formatValueToNumber } from '../../utils/metricsUtils';

import { getMediaOverviewMetrics } from '../../services/dashboardApiService';

import { AuthContext } from '../../contexts';

const PaidMediaOverview = () => {
    const { t } = useTranslation();
    const { timezone, currency } = useContext(AuthContext);

    const [dateRange, setDateRange] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [selectedChannelsOptions, setSelectedChannelsOptions] = useState();
    const [campaignOptions, setCampaignOptions] = useState([]);
    const [selectedCampaignOptions, setSelectedCampaignOptions] = useState([]);
    const [selectedDimension, setSelectedDimension] = useState(DIMENSIONS_OPTONS.day);
    const [mediaData, setMediaData] = useState(null);
    const [bigNumbersData, setBigNumbersData] = useState(null);
    const [openModalInformation, setOpenModalInformation] = useState(false);

    const [transactionAndCPTGraphFullScreen, setTransactionAndCPTGraphFullScreen] = useState(false);
    const [CACGraphFullScreen, setCACGraphFullScreen] = useState(false);
    const [clicksAndCTRGraphFullScreen, setClicksAndCTRGraphFullScreen] = useState(false);
    const [usersAndNewUsersGraphFullScreen, setUsersAndNewUsersGraphFullScreen] = useState(false);
    const [revenueAndInvestmentAndROASGraphFullScreen, setRevenueAndInvestmentAndROASGraphFullScreen] = useState(false);

    const [graphDataLabelEnabled, setGraphDataLabelEnabled] = useState(false);
    
    const cardNewUsersAndUsersRef = useRef();
    const cardTransactionsAndCPTRef = useRef();
    const cardClickAndCTRRef = useRef();
    const cardEvolutionCACRef = useRef();
    const cardCapturedRevenueAndInvestmentAndRoasRef = useRef();

    const handleApplyFilter = async () => {
        const [startDate, endDate] = dateRange;
        const channels = selectedChannelsOptions
            ? selectedChannelsOptions.map(channel => channel.value)
            : paidMediaChannelsOptions[0].options.map(channel => channel.value);
        const campaigns = selectedCampaignOptions.length > 0 ? [...selectedCampaignOptions] : null;

        await getMediaDataMetrics(
            formatDate(startDate),
            formatDate(endDate),
            channels,
            campaigns
        );
    };

    const getMediaDataMetrics = useCallback(async (initialDate, finalDate, channels, campaigns) => {
        setIsLoading(true);

        try {
            const response = await getMediaOverviewMetrics(
                initialDate,
                finalDate,
                timezone,
                channels,
                campaigns
            );

            if (!response) {
                setMediaData(null);
                setBigNumbersData(null);
                setCampaignOptions([])

                return;
            }

            setMediaData(response.results)
            setBigNumbersData(response.big_numbers)
            setCampaignOptions(response.available_campaigns_to_filter)
        } catch (error) {
            toast.error(error.message);
            setMediaData(null);
            setBigNumbersData(null);
            setCampaignOptions([])
        } finally {
          setIsLoading(false);
        }
    }, [setMediaData, timezone]);

    const formatRevenueAndInvestmentAndROASData = (data, dimension) => {
        if(!data) { return null}

        const aggregatedData = {};

        data.forEach(entry => {
            const key = getAgregatedKeyByDimension(entry.date, dimension);
            if (!aggregatedData[key]) {
                aggregatedData[key] = {
                    date: key,
                    capturedRevenue: 0,
                    investment: 0,
                    roasPercentage: 0.0
                };
            }

            aggregatedData[key].capturedRevenue += entry.revenue;
            aggregatedData[key].investment += entry.investment;
            aggregatedData[key].roasPercentage += entry.roas_percentage;
        });

        if (dimension !== DIMENSIONS_OPTONS.day) {
            Object.keys(aggregatedData).forEach(key => {
                const roas = aggregatedData[key].investment > 0 ? aggregatedData[key].capturedRevenue / aggregatedData[key].investment : 0.0;

                aggregatedData[key].roasPercentage = Number((roas * 100).toFixed(2));
                aggregatedData[key].capturedRevenue = Number((aggregatedData[key].capturedRevenue).toFixed(2));
                aggregatedData[key].investment = Number((aggregatedData[key].investment).toFixed(2));
            })
        }

        return Object.values(aggregatedData);
    };

    const formatUsersAndNewUsersData = (data, dimension) => {
        if(!data) { return null}

        const aggregatedData = {};

        data.forEach(entry => {
            const key = getAgregatedKeyByDimension(entry.date, dimension);
            if (!aggregatedData[key]) {
                aggregatedData[key] = {
                    date: key,
                    newUsers: 0,
                    users: 0
                };
            }

            aggregatedData[key].newUsers += entry.new_users;
            aggregatedData[key].users += entry.users;
        });

        return Object.values(aggregatedData);
    };

    const formatCACData = (data, dimension) => {
        if(!data) { return null}

        const aggregatedData = {};

        data.forEach(entry => {
            const key = getAgregatedKeyByDimension(entry.date, dimension);
            if (!aggregatedData[key]) {
                aggregatedData[key] = {
                    date: key,
                    cac: 0.0,
                    investment: 0.0,
                    numberOfNewPlatformCustomers: 0
                };
            }

            aggregatedData[key].cac += entry.cac;
            aggregatedData[key].investment += entry.investment;
            aggregatedData[key].numberOfNewPlatformCustomers += entry.number_of_new_platform_customers;
        });

        if (dimension !== DIMENSIONS_OPTONS.day) {
            Object.keys(aggregatedData).forEach(key => {
                const cac = aggregatedData[key].numberOfNewPlatformCustomers > 0 ? aggregatedData[key].investment / aggregatedData[key].numberOfNewPlatformCustomers : 0.0;

                aggregatedData[key].cac = Number((cac).toFixed(2));
            })
        }

        return Object.values(aggregatedData);
    };

    const formatClicksAndCTRData = (data, dimension) => {
        if(!data) { return null}

        const aggregatedData = {};

        data.forEach(entry => {
            const key = getAgregatedKeyByDimension(entry.date, dimension);
            if (!aggregatedData[key]) {
                aggregatedData[key] = {
                    date: key,
                    clicks: 0.0,
                    impressions: 0.0,
                    ctr: 0.0
                };
            }

            aggregatedData[key].ctr += entry.ctr;
            aggregatedData[key].clicks += entry.clicks;
            aggregatedData[key].impressions += entry.impressions;
        });

        if (dimension !== DIMENSIONS_OPTONS.day) {
            Object.keys(aggregatedData).forEach(key => {
                const ctr = aggregatedData[key].impressions > 0 ? aggregatedData[key].clicks / aggregatedData[key].impressions : 0.0;

                aggregatedData[key].ctr = Number((ctr * 100).toFixed(2));
            })
        }

        return Object.values(aggregatedData);
    };

    const formatTransactionsAndCPTData = (data, dimension) => {
        if(!data) { return null}

        const aggregatedData = {};

        data.forEach(entry => {
            const key = getAgregatedKeyByDimension(entry.date, dimension);
            if (!aggregatedData[key]) {
                aggregatedData[key] = {
                    date: key,
                    investment: 0.0,
                    transactions: 0,
                    cpt: 0.0
                };
            }

            aggregatedData[key].investment += entry.investment;
            aggregatedData[key].transactions += entry.transactions;
            aggregatedData[key].cpt += entry.cpt;
        });

        if (dimension !== DIMENSIONS_OPTONS.day) {
            Object.keys(aggregatedData).forEach(key => {
                const cpt = aggregatedData[key].investment > 0 ? aggregatedData[key].investment / aggregatedData[key].transactions : 0.0;
                aggregatedData[key].cpt = Number(cpt).toFixed(2);
            })
        }

        return Object.values(aggregatedData);
    };

    const getCardIndicatorData = (data) => {
        return {
          keys: ["investment", "cpt", "roas_percentage", "cac", "ctr", "transactions"],
          titles: {
            investment: t('common.investment'),
            cpt: t('common.cpt'),
            roas_percentage: t('common.roas'),
            cac: t('common.cac'),
            ctr: t('common.ctr'),
            transactions: t('common.transactions')
          },
          types: {
            investment: 'currency',
            cpt: 'currency',
            roas_percentage: 'percent',
            cac: 'currency',
            ctr: 'percent',
            transactions: 'decimal'
          },
          displayFlexSpaceOcupation: {
            investment: 2,
            cpt: 1,
            roas_percentage: 1,
            cac: 1,
            ctr: 1,
            transactions: 1
          },
          data: data
        }
    }

    const onRenderComponent = useCallback(async () => {
        const channels = paidMediaChannelsOptions[0].options.map(channel => channel.value);
        const { initialDateFilter, finalDateFilter } = getSelectedInitialDateRange();
        setDateRange([initialDateFilter, finalDateFilter]);

        await getMediaDataMetrics(
          formatDate(initialDateFilter),
          formatDate(finalDateFilter),
          channels
        );
      }, [getMediaDataMetrics]);

      useEffect(() => {
        onRenderComponent();
      }, [onRenderComponent]);

    return (
        <BackgroundNew
            titlePage={t('menu.paid_media_overview')}
            showInformation={true}
            informationTooltipTitle={t('common.click_to_learn_more')}
            informationOnClick={() => setOpenModalInformation(true)}
        >
            <FilterContainer
                showChannelFilter={true}
                showMultiSelectFilter={true}
                multiSelectOptionsTitle={t('common.campaign')}
                selectedDateRange={dateRange}
                setDateRange={setDateRange}
                selectedChannelsOptions={selectedChannelsOptions}
                setSelectedChannelsOptions={setSelectedChannelsOptions}
                multiSelectOptions={campaignOptions}
                setSelectedMultiSelectValueOptions={setSelectedCampaignOptions}
                selectedMultiSelectValueOptions={selectedCampaignOptions}
                onClickFilter={handleApplyFilter}
                channelsOptions={paidMediaChannelsOptions}
            />
            <CardIndicator
                data={getCardIndicatorData(bigNumbersData)}
                currency={currency}
                isLoading={isLoading}
            />
            <CardGroup
                title={t('paidMediaOverview.captured_revenue_vs_investment_vs_roas')}
                tagIdentifierColor="#00CCAE"
                isLoading={isLoading}
                ref={cardCapturedRevenueAndInvestmentAndRoasRef}
            >
                <ComposedGraphBarLine
                    data={mediaData}
                    dimension={selectedDimension}
                    showPrimaryBarYAxis={true}
                    showSecondaryBarYAxis={true}
                    showSecondaryLineYAxis={true}
                    primaryBarYAxisConfig={{
                        name: t('common.captured_revenue'),
                        dataKey: "capturedRevenue"
                    }}
                    secondaryBarYAxisConfig={{
                        name: t('common.investment'),
                        dataKey: "investment"
                    }}
                    secondaryLineYAxisConfig={{
                        name: t('common.roas'),
                        dataKey: "roasPercentage"
                    }}
                    formatData={formatRevenueAndInvestmentAndROASData}
                    formaterBarValue={value => formatValueToMoney(value, currency)}
                    formaterLineValue={formatValueToPercentage}
                    graphMarginConfig={{
                        top: 20,
                        right: 20,
                        left:64,
                        bottom: 20
                    }}
                    domainLineYAxis={[0, 10000]}
                    showDimensionController={true}
                    setSelectedDimension={setSelectedDimension}
                    disabledDimensionController={mediaData === null || mediaData.length === 0}
                    showFullScreenOption={true}
                    fullScreenEnabled={revenueAndInvestmentAndROASGraphFullScreen}
                    setFullScreenEnabled={setRevenueAndInvestmentAndROASGraphFullScreen}
                    showExportPDFButton={true}
                    graphComponentRefForPDF={cardCapturedRevenueAndInvestmentAndRoasRef}
                    showDataLabel={true}
                    dataLabelEnabled={graphDataLabelEnabled}
                    setDataLabelEnabled={setGraphDataLabelEnabled}
                    barDataLabelEnabled={true}
                    barDataValueIsCurrency={true}
                    lineDataLabelEnabled={true}
                    lineDataValueIsPercentage={true}
                />
            </CardGroup>
            <Container>
                <CardGroup
                    title={t('paidMediaOverview.new_users_vs_users')}
                    tagIdentifierColor="#00CCAE"
                    isLoading={isLoading}
                    customClassName="cardGroupContainerFirst"
                    ref={cardNewUsersAndUsersRef}
                >
                    <ComposedGraphBarLine
                        data={mediaData}
                        dimension={selectedDimension}
                        showPrimaryBarYAxis={true}
                        showSecondaryBarYAxis={true}
                        secondaryBarIsStacked={true}
                        primaryBarYAxisConfig={{
                            name: t('common.new_users'),
                            dataKey: "newUsers"
                        }}
                        secondaryBarYAxisConfig={{
                            name: t('common.users'),
                            dataKey: "users"
                        }}
                        formatData={formatUsersAndNewUsersData}
                        formaterBarValue={formatValueToNumber}
                        graphMarginConfig={{
                            top: 20,
                            right: -34,
                            left:20,
                            bottom: 20
                        }}
                        showDimensionController={true}
                        setSelectedDimension={setSelectedDimension}
                        disabledDimensionController={mediaData === null || mediaData.length === 0}
                        showFullScreenOption={true}
                        fullScreenEnabled={usersAndNewUsersGraphFullScreen}
                        setFullScreenEnabled={setUsersAndNewUsersGraphFullScreen}
                        showExportPDFButton={true}
                        graphComponentRefForPDF={cardNewUsersAndUsersRef}
                        showDataLabel={true}
                        dataLabelEnabled={graphDataLabelEnabled}
                        setDataLabelEnabled={setGraphDataLabelEnabled}
                        barDataLabelEnabled={true}
                    />
                </CardGroup>
                <CardGroup
                    title={t('paidMediaOverview.acquisition_cost_progress_cac')}
                    tagIdentifierColor="#00CCAE"
                    isLoading={isLoading}
                    customClassName="cardGroupContainerSecond"
                    ref={cardEvolutionCACRef}
                >
                    <ComposedGraphBarLine
                        data={mediaData}
                        dimension={selectedDimension}
                        showPrimaryBarYAxis={true}
                        primaryBarYAxisConfig={{
                            name: t('common.cac'),
                            dataKey: "cac"
                        }}
                        formatData={formatCACData}
                        formaterBarValue={value => formatValueToMoney(value, currency)}
                        graphMarginConfig={{
                            top: 20,
                            right: -34,
                            left:30,
                            bottom: 20
                        }}
                        showDimensionController={true}
                        setSelectedDimension={setSelectedDimension}
                        disabledDimensionController={mediaData === null || mediaData.length === 0}
                        showFullScreenOption={true}
                        fullScreenEnabled={CACGraphFullScreen}
                        setFullScreenEnabled={setCACGraphFullScreen}
                        showExportPDFButton={true}
                        graphComponentRefForPDF={cardEvolutionCACRef}
                        showDataLabel={true}
                        dataLabelEnabled={graphDataLabelEnabled}
                        setDataLabelEnabled={setGraphDataLabelEnabled}
                        barDataLabelEnabled={true}
                        barDataValueIsCurrency={true}
                    />
                </CardGroup>
            </Container>
            <Container>
                <CardGroup
                    title={t('paidMediaOverview.clicks_vs_ctr')}
                    tagIdentifierColor="#00CCAE"
                    isLoading={isLoading}
                    customClassName="cardGroupContainerFirst"
                    ref={cardClickAndCTRRef}
                >
                    <ComposedGraphBarLine
                        data={mediaData}
                        dimension={selectedDimension}
                        showPrimaryBarYAxis={true}
                        showSecondaryLineYAxis={true}
                        primaryBarYAxisConfig={{
                            name: t('common.clicks'),
                            dataKey: "clicks"
                        }}
                        secondaryLineYAxisConfig={{
                            name: t('common.ctr'),
                            dataKey: "ctr"
                        }}
                        formatData={formatClicksAndCTRData}
                        formaterBarValue={formatValueToNumber}
                        formaterLineValue={formatValueToPercentage}
                        graphMarginConfig={{
                            top: 20,
                            right: 8,
                            left:20,
                            bottom: 20
                        }}
                        domainLineYAxis={[0, 50]}
                        showDimensionController={true}
                        setSelectedDimension={setSelectedDimension}
                        disabledDimensionController={mediaData === null || mediaData.length === 0}
                        showFullScreenOption={true}
                        fullScreenEnabled={clicksAndCTRGraphFullScreen}
                        setFullScreenEnabled={setClicksAndCTRGraphFullScreen}
                        showExportPDFButton={true}
                        graphComponentRefForPDF={cardClickAndCTRRef}
                        showDataLabel={true}
                        dataLabelEnabled={graphDataLabelEnabled}
                        setDataLabelEnabled={setGraphDataLabelEnabled}
                        barDataLabelEnabled={true}
                        lineDataLabelEnabled={true}
                        lineDataValueIsPercentage={true}
                    />
                </CardGroup>
                <CardGroup
                    title={t('paidMediaOverview.transactions_vs_cpt')}
                    tagIdentifierColor="#00CCAE"
                    isLoading={isLoading}
                    customClassName="cardGroupContainerSecond"
                    ref={cardTransactionsAndCPTRef}
                >
                    <ComposedGraphBarLine
                        data={mediaData}
                        dimension={selectedDimension}
                        showPrimaryBarYAxis={true}
                        showSecondaryLineYAxis={true}
                        primaryBarYAxisConfig={{
                            name: t('common.transactions'),
                            dataKey: "transactions"
                        }}
                        secondaryLineYAxisConfig={{
                            name: t('common.cpt'),
                            dataKey: "cpt"
                        }}
                        formatData={formatTransactionsAndCPTData}
                        formaterBarValue={formatValueToNumber}
                        formaterLineValue={value => formatValueToMoney(value, currency)}
                        graphMarginConfig={{
                            top: 20,
                            right: 35,
                            left:5,
                            bottom: 20
                        }}
                        domainLineYAxis={[0, 100]}
                        showDimensionController={true}
                        setSelectedDimension={setSelectedDimension}
                        disabledDimensionController={mediaData === null || mediaData.length === 0}
                        showFullScreenOption={true}
                        fullScreenEnabled={transactionAndCPTGraphFullScreen}
                        setFullScreenEnabled={setTransactionAndCPTGraphFullScreen}
                        showExportPDFButton={true}
                        graphComponentRefForPDF={cardTransactionsAndCPTRef}
                        showDataLabel={true}
                        dataLabelEnabled={graphDataLabelEnabled}
                        setDataLabelEnabled={setGraphDataLabelEnabled}
                        barDataLabelEnabled={true}
                        lineDataLabelEnabled={true}
                        lineDataValueIsCurrency={true}
                    />
                </CardGroup>
            </Container>
            <ModalPageInformation
                title={t('paidMediaOverview.understand_paid_media_overview_analysis')}
                open={openModalInformation}
                handleClose={() => setOpenModalInformation(false)}
                videoUrl={"https://www.youtube.com/embed/mbpLUdq_kIU?si=3wf68FChC9QHcqXX"}
            />
        </BackgroundNew>
    );
}

export default PaidMediaOverview;
