import React, { useEffect, useCallback, useState, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import '../../i18n';

import { toast } from 'react-toastify';

import BackgroundNew from '../../components/BackgroundNew';
import FilterContainer from '../../components/FilterContainer';
import CardGroup from '../../components/CardGroup';
import ComposedGraphBarLine from '../../components/ComposedGraphBarLine';

import { DIMENSIONS_OPTONS } from '../../options/filterOptions';
import { 
    getSelectedGoalsOverviewAndEvolutionInitialDateRange,
    setCurrentGoalsOverviewAndEvolutionDateRange, 
    formatDate, 
    getAgregatedKeyByDimension 
} from '../../utils/dateUtils';
import { formatValueToMoney, getVariationValue, formatValueToNumber, formatValueToPercentage } from '../../utils/metricsUtils';

import { getGoalIndicatorsEvolutionMetrics } from '../../services/dashboardApiService';

import { routes } from '../../routes';

import { AuthContext } from '../../contexts';

const GoalsEvolution = () => {
    const { t } = useTranslation();
    const { currency } = useContext(AuthContext);

    const navigate = useNavigate();

    const [dateRange, setDateRange] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [selectedChannelsOptions, setSelectedChannelsOptions] = useState();
    const [selectedDimension, setSelectedDimension] = useState(DIMENSIONS_OPTONS.day);
    const [indicatorsEvolutionData, setIndicatorsEvolutionData] = useState(null);

    const [revenueFullScreen, setRevenueFullScreen] = useState(false);
    const [sessionAndConvertionRateFullScreen, setSessionAndConvertionRateFullScreen] = useState(false);
    const [investmentFullScreen, setInvestmentFullScreen] = useState(false);

    const [graphDataLabelEnabled, setGraphDataLabelEnabled] = useState(false);

    const cardSessionAndConversionRateEvolutionRef = useRef();
    const cardRevenueEvolutionRef = useRef();
    const cardInvestmentEvolutionRef = useRef();

    const handleApplyFilter = async () => {
        const [startDate, endDate] = dateRange;
        const channels = selectedChannelsOptions ? selectedChannelsOptions.map(channel => channel.value) : null;

        await getGoalMetricsData(formatDate(startDate), formatDate(endDate), channels);
    };

    const getGoalMetricsData = useCallback(async (initialDate, finalDate, channels) => {
        setIsLoading(true);

        try {
            const response = await getGoalIndicatorsEvolutionMetrics(initialDate, finalDate, channels);

            setIndicatorsEvolutionData(response)
        } catch (error) {
            toast.error(error.message);
            setIndicatorsEvolutionData(null);
        } finally {
          setIsLoading(false);
        }
    }, [setIndicatorsEvolutionData]);

    const formatData = (data, dimension, field) => {
        if (!data) { return null }

        if (dimension === DIMENSIONS_OPTONS.day) { return data[field] }

        const aggregatedData = {};

        data[field].forEach(entry => {
            const key = getAgregatedKeyByDimension(entry.date, dimension);
            if (!aggregatedData[key]) {
                aggregatedData[key] = {
                date: key,
                result: 0,
                goal: 0,
                achieved: 0,
                };
            }
            aggregatedData[key].result += entry.result;
            aggregatedData[key].goal += entry.goal;
        });

        Object.keys(aggregatedData).forEach(key => {
            aggregatedData[key].achieved = getVariationValue(
                aggregatedData[key].result, aggregatedData[key].goal
            );
        });

        return Object.values(aggregatedData);
    };

    const formatSessionAndConversionRateData = (data, dimension) => {
        if(!data) { return null}

        const aggregatedData = {};
        const sessionsData = data.sessions;
        const conversionRateData = data.conversion_rate;
        const transactionsData = data.transactions;

        sessionsData.forEach(entry => {
            const key = getAgregatedKeyByDimension(entry.date, dimension);
            if (!aggregatedData[key]) {
                aggregatedData[key] = {
                    date: key,
                    sessionResult: 0,
                    sessionGoal: 0
                };
            }
            aggregatedData[key].sessionResult += entry.result;
            aggregatedData[key].sessionGoal += entry.goal;
        });

        if (dimension !== DIMENSIONS_OPTONS.day) {
            transactionsData.forEach(entry => {
                const key = getAgregatedKeyByDimension(entry.date, dimension);
                if (!aggregatedData[key].transactionResult) {
                    aggregatedData[key].transactionResult = 0;
                    aggregatedData[key].transactionGoal = 0;
                }
                aggregatedData[key].transactionResult += entry.result;
                aggregatedData[key].transactionGoal += entry.goal;
            });

            Object.keys(aggregatedData).forEach(key => {
                const conversionRateResult = aggregatedData[key].sessionResult > 0 ? aggregatedData[key].transactionResult / aggregatedData[key].sessionResult : 0.0;
                const conversionRateGoal = aggregatedData[key].sessionGoal > 0 ? aggregatedData[key].transactionGoal / aggregatedData[key].sessionGoal : 0.0;

                aggregatedData[key].conversionRateResult = Number((conversionRateResult * 100).toFixed(2));
                aggregatedData[key].conversionRateGoal = Number((conversionRateGoal * 100).toFixed(2));
            })

        } else {
            conversionRateData.forEach(entry => {
                const key = getAgregatedKeyByDimension(entry.date, dimension);
                aggregatedData[key].conversionRateResult = entry.result;
                aggregatedData[key].conversionRateGoal = entry.goal;
            });
        }

        return Object.values(aggregatedData);
    };

    const onRenderComponent = useCallback(async () => {
        const { initialDateFilter, finalDateFilter } = getSelectedGoalsOverviewAndEvolutionInitialDateRange();

        setDateRange([initialDateFilter, finalDateFilter]);

        await getGoalMetricsData(
          formatDate(initialDateFilter),
          formatDate(finalDateFilter)
        );
      }, [getGoalMetricsData]);

      useEffect(() => {
        onRenderComponent();
      }, [onRenderComponent]);

    return (
        <BackgroundNew
          titlePage={t('menu.goals_progress')}
          showButtonAction={true}
          buttonActionOnClick={_ => navigate(routes.mediaPlanner)}
          buttonActionTitle={t('goalsOverview.register_goal')}
        >
            <FilterContainer
                showChannelFilter={true}
                selectedDateRange={dateRange}
                setDateRange={setDateRange}
                selectedChannelsOptions={selectedChannelsOptions}
                setSelectedChannelsOptions={setSelectedChannelsOptions}
                onClickFilter={handleApplyFilter}
                limitDateRangeUntilToday={false}
                setCurrentDateRangeCustomized={setCurrentGoalsOverviewAndEvolutionDateRange}
            />
            <CardGroup
                title={t('goalsEvolution.captured_revenue_progress_ga')}
                tagIdentifierColor="#00CCAE"
                isLoading={isLoading}
                ref={cardRevenueEvolutionRef}
            >
                <ComposedGraphBarLine
                    data={indicatorsEvolutionData}
                    dimension={selectedDimension}
                    showPrimaryBarYAxis={true}
                    showSecondaryBarYAxis={true}
                    showSecondaryLineYAxis={true}
                    primaryBarYAxisConfig={{
                        name: t('common.captured_revenue'),
                        dataKey: "result"
                    }}
                    secondaryBarYAxisConfig={{
                        name: t('common.goal'),
                        dataKey: "goal"
                    }}
                    secondaryLineYAxisConfig={{
                        name: t('goalsEvolution.achievement'),
                        dataKey: "achieved"
                    }}
                    formatData={(data, dimension) => formatData(data, dimension, "revenue")}
                    formaterBarValue={value => formatValueToMoney(value, currency)}
                    formaterLineValue={formatValueToPercentage}
                    graphMarginConfig={{
                        top: 20,
                        right: 0,
                        left:64,
                        bottom: 20
                    }}
                    domainLineYAxis={[-100, 100]}
                    showDimensionController={true}
                    setSelectedDimension={setSelectedDimension}
                    disabledDimensionController={indicatorsEvolutionData === null}
                    showFullScreenOption={true}
                    fullScreenEnabled={revenueFullScreen}
                    setFullScreenEnabled={setRevenueFullScreen}
                    showExportPDFButton={true}
                    graphComponentRefForPDF={cardRevenueEvolutionRef}
                    showDataLabel={true}
                    dataLabelEnabled={graphDataLabelEnabled}
                    setDataLabelEnabled={setGraphDataLabelEnabled}
                    barDataLabelEnabled={true}
                    barDataValueIsCurrency={true}
                    lineDataLabelEnabled={true}
                    lineDataValueIsPercentage={true}
                />
            </CardGroup>
            <CardGroup
                title={t('goalsEvolution.sessions_vs_conversion_rate_progress_ga')}
                tagIdentifierColor="#00CCAE"
                isLoading={isLoading}
                ref={cardSessionAndConversionRateEvolutionRef}
            >
                <ComposedGraphBarLine
                    data={indicatorsEvolutionData}
                    dimension={selectedDimension}
                    showPrimaryBarYAxis={true}
                    showSecondaryBarYAxis={true}
                    showPrimaryLineYAxis={true}
                    showSecondaryLineYAxis={true}
                    primaryBarYAxisConfig={{
                        name: t('common.sessions'),
                        dataKey: "sessionResult"
                    }}
                    secondaryBarYAxisConfig={{
                        name: t('goalsEvolution.sessions_goal'),
                        dataKey: "sessionGoal"
                    }}
                    primaryLineYAxisConfig={{
                        name: t('common.conversion_rate'),
                        dataKey: "conversionRateResult"
                    }}
                    secondaryLineYAxisConfig={{
                        name: t('goalsEvolution.conversion_rate_goal'),
                        dataKey: "conversionRateGoal"
                    }}
                    formatData={formatSessionAndConversionRateData}
                    formaterBarValue={formatValueToNumber}
                    formaterLineValue={formatValueToPercentage}
                    graphMarginConfig={{
                        top: 20,
                        right: 0,
                        left:25,
                        bottom: 20
                    }}
                    domainLineYAxis={[0, 5]}
                    showDimensionController={true}
                    setSelectedDimension={setSelectedDimension}
                    disabledDimensionController={indicatorsEvolutionData === null}
                    showFullScreenOption={true}
                    fullScreenEnabled={sessionAndConvertionRateFullScreen}
                    setFullScreenEnabled={setSessionAndConvertionRateFullScreen}
                    showExportPDFButton={true}
                    graphComponentRefForPDF={cardSessionAndConversionRateEvolutionRef}
                    showDataLabel={true}
                    dataLabelEnabled={graphDataLabelEnabled}
                    setDataLabelEnabled={setGraphDataLabelEnabled}
                    barDataLabelEnabled={true}
                    lineDataLabelEnabled={true}
                    lineDataValueIsPercentage={true}
                />
            </CardGroup>
            <CardGroup
                title={t('goalsEvolution.investment_progress_ga')}
                tagIdentifierColor="#00CCAE"
                isLoading={isLoading}
                ref={cardInvestmentEvolutionRef}
            >
                <ComposedGraphBarLine
                    data={indicatorsEvolutionData}
                    dimension={selectedDimension}
                    showPrimaryBarYAxis={true}
                    showSecondaryBarYAxis={true}
                    showSecondaryLineYAxis={true}
                    primaryBarYAxisConfig={{
                        name: t('common.investment'),
                        dataKey: "result"
                    }}
                    secondaryBarYAxisConfig={{
                        name: t('common.goal'),
                        dataKey: "goal"
                    }}
                    secondaryLineYAxisConfig={{
                        name: t('goalsEvolution.achievement'),
                        dataKey: "achieved"
                    }}
                    formatData={(data, dimension) => formatData(data, dimension, "investment")}
                    formaterBarValue={value => formatValueToMoney(value, currency)}
                    formaterLineValue={formatValueToPercentage}
                    graphMarginConfig={{
                        top: 20,
                        right: 0,
                        left:64,
                        bottom: 20
                    }}
                    domainLineYAxis={[-100, 100]}
                    showDimensionController={true}
                    setSelectedDimension={setSelectedDimension}
                    disabledDimensionController={indicatorsEvolutionData === null}
                    showFullScreenOption={true}
                    fullScreenEnabled={investmentFullScreen}
                    setFullScreenEnabled={setInvestmentFullScreen}
                    showExportPDFButton={true}
                    graphComponentRefForPDF={cardInvestmentEvolutionRef}
                    showDataLabel={true}
                    dataLabelEnabled={graphDataLabelEnabled}
                    setDataLabelEnabled={setGraphDataLabelEnabled}
                    barDataLabelEnabled={true}
                    barDataValueIsCurrency={true}
                    lineDataLabelEnabled={true}
                    lineDataValueIsPercentage={true}
                />
            </CardGroup>
        </BackgroundNew>
    );
}

export default GoalsEvolution;
