import React, { useContext } from "react";
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';

import { RiMessage3Fill } from "react-icons/ri";

import { SupportButtonContainer } from "./styles";

import { AuthContext } from '../../contexts';

import { LANGUAGE } from '../../options/preference';

export const SupportButton = () => {
    const { t } = useTranslation();
    const { lang } = useContext(AuthContext);
    
    const FORMS_URL_PORTUGUESE = 'https://forms.monday.com/forms/ea0d51bd0ef7a45914beb82827a700ea?r=use1';
    const FORMS_URL_ENGLISH = 'https://forms.monday.com/forms/842b157309c294f3b8774f65cae2f38c?r=use1';
    const FORMS_URL_SPANISH = 'https://forms.monday.com/forms/7d1f3e5f87f9937d619469975c0329ee?r=use1';

    const handleClick = () => {
        const forms_url = lang === LANGUAGE.en_US ? FORMS_URL_ENGLISH : lang === LANGUAGE.es ? FORMS_URL_SPANISH : FORMS_URL_PORTUGUESE;

        window.open(forms_url, '_blank');
    }

    return (
        <Tooltip title={t('common.contact_us')} placement="left" arrow>
            <SupportButtonContainer onClick={handleClick}>
                <RiMessage3Fill size={30} color="#fff" />
            </SupportButtonContainer>
        </Tooltip>
    );
}