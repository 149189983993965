import { darken } from 'polished';
import styled from 'styled-components';

export const TagContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 30px;

  .disabled {
    background-color: #999;
    border-color: #999;
    color: #fff;
    cursor: not-allowed;
    box-shadow: none;

    &:hover {
        background-color: #999;
        border-color: #999;
        color: #fff;
    }
  }
`;

export const TagWrapper = styled.button`
  padding: 6px 8px;
  margin-left: 10px;
  color: #fff;
  font-size: 9px;
  letter-spacing: 1px;
  text-transform: uppercase;
  background: #ff0068;
  border-radius: 50px;
  border: none;

  &:hover {
    background-color: ${darken(0.05, '#ff0068')};
    border-color: ${darken(0.05, '#ff0068')};
    color: #fff;
  }
`;

export const TagWrapperFullScreen = styled.button`
  padding: 6px 8px;
  margin-left: 10px;
  color: #fff;
  font-size: 9px;
  letter-spacing: 1px;
  text-transform: uppercase;
  background: #00CCAE;
  border-radius: 50px;
  border: none;

  &:hover {
    background-color: ${darken(0.05, '#00CCAE')};
    border-color: ${darken(0.05, '#00CCAE')};
    color: #fff;
  }
`;
export const TagWrapperExportPDFButton = styled.button`
  padding: 6px 8px;
  margin-left: 10px;
  color: #fff;
  font-size: 9px;
  letter-spacing: 1px;
  text-transform: uppercase;
  background: #009BD0;
  border-radius: 50px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${darken(0.05, '#009BD0')};
    border-color: ${darken(0.05, '#009BD0')};
    color: #fff;
  }
`;

export const TagWrapperExportCSVButton = styled.button`
  padding: 6px 8px;
  margin-left: 10px;
  color: #fff;
  font-size: 9px;
  letter-spacing: 1px;
  text-transform: uppercase;
  background: #009BD0;
  border-radius: 50px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${darken(0.05, '#009BD0')};
    border-color: ${darken(0.05, '#009BD0')};
    color: #fff;
  }
`;

export const TagWrapperDataLabelButton = styled.button`
  padding: 6px 8px;
  margin-left: 10px;
  color: #fff;
  font-size: 9px;
  letter-spacing: 1px;
  text-transform: uppercase;
  background: #00637A;
  border-radius: 50px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${darken(0.05, '#00637A')};
    border-color: ${darken(0.05, '#00637A')};
    color: #fff;
  }
`;

export const TagNewFeature = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 14px;
  background-color: #00CCAE;
  padding: 4px;
  color: #FFFFFF;
  font-size: 8px;
  font-weight: 400;
  line-height: 10px;
  border-radius: 8px;
  margin-left: 10px;
`;
