import React, { useEffect, useState, useContext } from 'react';

import {
    Bar, XAxis, YAxis, CartesianGrid, Tooltip,
    Legend, ResponsiveContainer, ComposedChart
} from 'recharts';

import { useTranslation } from 'react-i18next';
import '../../i18n';

import GraphCustomTooltip from '../GraphCustomTooltip';
import GraphController from '../GraphController';
import GraphContainer from '../GraphContainer';

import { abbreviateNumber } from '../../utils/metricsUtils';
import { abbreviateText } from '../../utils/textUtils';

import { TagWrapperControllerPage, TagContainer } from './styles';

import { AuthContext } from '../../contexts';

const GraphBarHorizontal = ({
    data,
    formatData,
    formaterBarValue,
    YAxisDataKey,
    showPrimaryBarXAxis,
    showSecondaryBarXAxis = false,
    primaryBarXAxisConfig,
    secondaryBarXAxisConfig,
    secondaryBarIsStacked = false,
    graphMarginConfig,
    height = '50vh',
    showDimensionController = false,
    disabledDimensionController = false,
    showFullScreenOption = false,
    fullScreenEnabled = false,
    setFullScreenEnabled,
    maxDataToRender = 5,
    hiddenLegend = false,
    graphComponentRefForPDF,
    showExportPDFButton,
    showDataLabel = false,
    dataLabelEnabled = false,
    setDataLabelEnabled,
    dataValueIsCurrency = false,
    dataValueIsPercentage = false
}) => {
    const { t } = useTranslation();
    const { lang, currency } = useContext(AuthContext);

    const INITIAL_PAGE = 1;
    const MAX_CATEGORY_NAME_LENGTH = 15;

    const [formattedData, setFormattedData] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(INITIAL_PAGE);
    const [dataToRender, setDataToRender] = useState([]);
    const [maxAxisValue, setMaxAxisValue] = useState(0);

    const handlePageChange = (page) => {
        if (page < INITIAL_PAGE || page > totalPages) {
            return;
        }

        setCurrentPage(page);
        setDataToRender(formattedData.slice((page - 1) * maxDataToRender, page * maxDataToRender));
    };

    useEffect(() => {
        if (data && data.length > 0) {
            const formattedDataResponse = formatData(data);
            setFormattedData(formattedDataResponse);
            setTotalPages(Math.ceil(formattedDataResponse.length / maxDataToRender));
            setDataToRender(formattedDataResponse.slice(0, maxDataToRender));

            const maxAxisDataKey = primaryBarXAxisConfig ? primaryBarXAxisConfig.dataKey : secondaryBarXAxisConfig.dataKey;

            setMaxAxisValue(formattedDataResponse[0][maxAxisDataKey]);
        }
    }, [data, formatData, primaryBarXAxisConfig, secondaryBarXAxisConfig, maxDataToRender]);

    const renderGraph = (formattedData) => {
        return (
            <ResponsiveContainer height="100%">
                <ComposedChart
                    layout="vertical"
                    data={formattedData}
                    margin={{
                        top: graphMarginConfig.top,
                        right: graphMarginConfig.right,
                        left: graphMarginConfig.left,
                        bottom: graphMarginConfig.bottom
                    }}
                    >
                    <CartesianGrid strokeDasharray="3 0 0"/>
                    <XAxis type="number" tickFormatter={formaterBarValue} domain={[0, maxAxisValue]}/>
                    <YAxis
                        dataKey={YAxisDataKey}
                        type="category"
                        scale="band"
                        style={{
                            fontSize: "13px"
                        }}
                        tickFormatter={(value) => {
                            return abbreviateText(value, MAX_CATEGORY_NAME_LENGTH);
                        }}
                        width={150}
                    />
                    <Tooltip content={GraphCustomTooltip}/>
                    {showPrimaryBarXAxis && (
                        <Bar
                            name={primaryBarXAxisConfig.name}
                            dataKey={primaryBarXAxisConfig.dataKey}
                            stackId="a"
                            fill="rgba(0, 155, 208, 0.4)"
                            formatter={formaterBarValue}
                            radius={[0, 4, 4, 0]}
                            label={dataLabelEnabled && {
                                position: 'insideLeft',
                                fill: "#272B30",
                                fontWeight: "bold",
                                fontSize: fullScreenEnabled ? 13 : 10,
                                formatter: (value) => (parseFloat(value) !== 0 ? abbreviateNumber(value, lang, (dataValueIsCurrency ? currency : null), dataValueIsPercentage) : null)
                            }}
                        />
                    )}
                    {showSecondaryBarXAxis && (
                        <Bar
                            name={secondaryBarXAxisConfig.name}
                            dataKey={secondaryBarXAxisConfig.dataKey}
                            stackId={secondaryBarIsStacked ? "a" : "b"}
                            fill="rgba(255, 0, 104, 0.5)"
                            formatter={formaterBarValue}
                            radius={[0, 4, 4, 0]}
                            label={dataLabelEnabled && {
                                position: 'insideLeft',
                                fill: "#272B30",
                                fontWeight: "bold",
                                fontSize: fullScreenEnabled ? 13 : 10,
                                formatter: (value) => (parseFloat(value) !== 0 ? abbreviateNumber(value, lang, (dataValueIsCurrency ? currency : null), dataValueIsPercentage) : null)
                            }}
                        />
                    )}
                    {!hiddenLegend && <Legend verticalAlign="bottom"/>}
                </ComposedChart>
            </ResponsiveContainer>
        );
    };

    const stylesComponents = {
        mainContainerFullScreen: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100vh',
            backgroundColor: 'white',
            zIndex: 9999,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        graphContainerFullScreen: {
            height: '80vh'
        }
    }

    return (
        <div style={fullScreenEnabled ? stylesComponents.mainContainerFullScreen : {}}>
            {showDimensionController && (
                <GraphController
                    showDimensionController={false}
                    disabled={disabledDimensionController}
                    showFullScreenOption={showFullScreenOption}
                    fullScreenEnabled={fullScreenEnabled}
                    setFullScreenEnabled={setFullScreenEnabled}
                    graphComponentRefForPDF={graphComponentRefForPDF}
                    showExportPDFButton={showExportPDFButton}
                    showDataLabel={showDataLabel}
                    dataLabelEnabled={dataLabelEnabled}
                    setDataLabelEnabled={setDataLabelEnabled}
                />
            )}
            {totalPages > 1 &&
                <TagContainer className='ignorePdf'>
                    <TagWrapperControllerPage
                        disabled={currentPage === INITIAL_PAGE}
                        className={currentPage === INITIAL_PAGE ? 'disabled' : ''}
                        onClick={() => handlePageChange(currentPage - 1)}
                    >
                        {t('common.previous')}
                    </TagWrapperControllerPage>
                    <TagWrapperControllerPage
                        disabled={currentPage === totalPages}
                        className={currentPage === totalPages ? 'disabled' : ''}
                        onClick={() => handlePageChange(currentPage + 1)}
                    >
                        {t('common.next')}
                    </TagWrapperControllerPage>
                </TagContainer>
            }
            {fullScreenEnabled && (
                <GraphContainer
                    style={stylesComponents.graphContainerFullScreen}
                >
                    {renderGraph(dataToRender)}
                </GraphContainer>
            )}
            {!fullScreenEnabled && (
                <GraphContainer
                    style={{height: height}}
                >
                    {renderGraph(dataToRender)}
                </GraphContainer>
            )}
        </div>
    )

}

export default GraphBarHorizontal;
