import styled from 'styled-components';

export const ContainerConversionFunnel = styled.div`
    display: flex;
    flex-direction: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    @media (max-width: 838px) {
        flex-direction: column;
    }

    .containerStepFunnel {
        display: flex;
        flex-direction: row;
        margin: 10px;
        align-items: baseline;
        justify-content: center;
        width: 100%;

        .labelStepFunnel {
            font-size: 18px;
            font-weight: bold;
            margin-right: 40px;
            width: 20%;

            @media (max-width: 838px) {
                font-size: 16px;
            }

            .labelInnerContentStepFunnel {
                border-bottom: 4px solid #F4F4F4;
                padding-bottom: 5px;
                text-align: baseline;
            }
        }

        .dataStepFunnel {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
        }

        .dataInnerContentStepFunnel {
            display: flex;
            font-size: 18px;
            font-weight: bold;
            background-color: rgba(0, 158, 131, 0.4);
            width: 100%;
            padding: 25px;
            border-radius: 8px;
            align-items: center;
            justify-content: center;

            @media (max-width: 838px) {
                font-size: 16px;
            }
        }

        .clicksStepData {
            width: 80%;
        }

        .sessionsStepData {
            width: 50%;
        }

        .transactionsStepData {
            width: 30%;
        }
    }
`;

export const ConversionFunnelComplement = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    margin: 10px;
    border-right: 3px solid #F4F4F4;

    /* width */
      ::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        box-shadow: #f1f1f1;
        border-radius: 10px;
        width: 5px;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #DEDEDE;
        border-radius: 10px;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #C8C8C8;
      }

    @media (max-width: 838px) {
        width: 100%;
        border-right: none;
        flex-direction: row;
        overflow-x: auto;
    }

    @media (max-width: 838px) {

    }

    .containerIndicatorFunnelComplement {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding: 20px;
        border-radius: 8px;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 10px;

        .iconIndicatorFunnelComplement {
            margin-right: 10px;
            background-color: #F4F4F4;
            padding: 10px;
            border-radius: 50%;
        }

        .iconCTR {
            background-color: rgba(0, 99, 122, 0.4);
        }

        .iconBounceRate {
            background-color: rgba(255, 0, 104, 0.4);
        }

        .iconConversionRate {
            background-color: rgba(0, 204, 174, 0.4);
        }

        .containerIndicatorValueFunnelComplement {
            display: flex;
            align-items: flex-start;
            flex-direction: column;

            .labelIndicadorFunnelComplement {
                font-size: 16px;
                color: #6F767E;
                font-weight: 600;
            }

            .dataIndicadorFunnelComplement {
                display: flex;
                justify-content: left;
                align-items: center;
                font-size: 34px;
                font-weight: bold;
            }
        }

        @media (max-width: 838px) {
            justify-content: center;
        }
    }
`;

export const ConversionFunnel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 3;
  margin: 10px;
  padding-left: 30px;

  @media (max-width: 838px) {
    width: 100%;
    padding-left: 0;
  }
`;
