import React, { useEffect, useCallback, useState, useRef, useContext } from 'react';

import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import '../../i18n'; 

import CardGroup from '../../components/CardGroup';
import BackgroundNew from '../../components/BackgroundNew';
import FilterContainer from '../../components/FilterContainer';
import ComposedGraphBarLine from '../../components/ComposedGraphBarLine';


import { DIMENSIONS_OPTONS} from '../../options/filterOptions';
import { isValidAvailableFilter } from '../../utils/validationUtils';
import { getSelectedInitialDateRange, formatDate, getAgregatedKeyByDimension } from '../../utils/dateUtils';
import { formatValueToPercentage, formatValueToMoney, customRound, formatValueToNumber } from '../../utils/metricsUtils';

import { 
    getPromotionsDailyOverview, 
    getPromotionsCustomerUsageFrequency,
    sendPromotionsOverviewReportByEmail,
    getCouponDailyOverview,
    sendCouponsDailyOverviewReportByEmail
} from '../../services/dashboardApiService';

import GraphPie from '../../components/GraphPie';
import CustomTableApiPagination from '../../components/CustomTableApiPagination';

import { AuthContext } from '../../contexts';

const MAX_NUMBER_OF_PROMOTIONS_VISIBLE_ON_GRAPH = 10;

const PromotionPerformance = () => {
    const { t } = useTranslation();
    const { timezone, currency } = useContext(AuthContext);

    const [dateRange, setDateRange] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [selectedDimension, setSelectedDimension] = useState(DIMENSIONS_OPTONS.day);

    const [marketplaceChannelsOptions, setMarketplaceChannelsOptions] = useState([]);
    const [selectedMarketplaceChannelsOptions, setSelectedMarketplaceChannelsOptions] = useState([]);

    const [affiliatesOptions, setAffiliatesOptions] = useState([]);
    const [selectedAffiliatesOptions, setSelectedAffiliatesOptions] = useState([]);

    const [originsOptions, setOriginsOptions] = useState([]);
    const [selectedOriginsOptions, setSelectedOriginsOptions] = useState([]);

    const [couponsOptions, setCouponsOptions] = useState([]);
    const [selectedCouponsOptions, setSelectedCouponsOptions] = useState([]);

    const [weekDaysOptions, setWeekDaysOptions] = useState([]);
    const [selectedWeekDaysOptions, setSelectedWeekDaysOptions] = useState([]);

    const [promotionNameOptions, setPromotionNameOptions] = useState([]);
    const [selectedPromotionNameOptions, setSelectedPromotionNameOptions] = useState([]);

    const [promotionTypeOptions, setPromotionTypeOptions] = useState([]);
    const [selectedPromotionTypeOptions, setSelectedPromotionTypeOptions] = useState([]);

    const [promotionStatusOptions, setPromotionStatusOptions] = useState([]);
    const [selectedPromotionStatusOptions, setSelectedPromotionStatusOptions] = useState([]);

    const [promotionsDailyOverviewData, setPromotionsDailyOverviewData] = useState ([]);
    const [promotionsCustomerUsageFrequencyData, setPromotionsCustomerUsageFrequencyData] = useState ([]);
    const [couponsDailyOverviewData, setCouponsDailyOverviewData] = useState ([]);

    const [couponAnalysisMetricsTableFullScreen, setCouponAnalysisMetricsTableFullScreen] = useState(false);
    const [promotionsPerformanceTableFullScreen, setPromotionsPerformanceTableFullScreen] = useState(false);
    const [shareOfApprovedOrdersByCouponGraphFullScreen, setShareOfApprovedOrdersByCouponGraphFullScreen] = useState(false);
    const [evolutionOfApprovedOrdersByPromotionsGraphFullScreen, setEvolutionOfApprovedOrdersByPromotionsGraphFullScreen] = useState(false);
    const [frequencyofUseOfPromotionsByCustomerGraphFullScreen, setFrequencyofUseOfPromotionsByCustomerGraphFullScreen] = useState(false);

    const [graphDataLabelEnabled, setGraphDataLabelEnabled] = useState(false);

    const [exportReportLoading, setExportReportLoading] = useState(false);

    const cardShareOfApprovedOrdersByCouponRef = useRef(null);
    const cardEvolutionOfApprovedOrdersByPromotionsRef = useRef(null);
    const cardFrequencyofUseOfPromotionsByCustomerRef = useRef(null);

    const [visiblePromotionsName, setVisiblePromotionsName] = useState([]);

    const getDataByAppliedFilter = async () => {
        const filter = getActiveFilters()

        await getPromotionPerformanceData(filter);
    };

    const getActiveFilters = () => {
        const [startDate, endDate] = dateRange;
        const promotionNames = selectedPromotionNameOptions.length > 0 ? selectedPromotionNameOptions : null;
        const promotionTypes = selectedPromotionTypeOptions.length > 0 ? selectedPromotionTypeOptions : null;
        const promotionStatus = selectedPromotionStatusOptions.length > 0 ? selectedPromotionStatusOptions : null;
        const coupons = selectedCouponsOptions.length > 0 ? [...selectedCouponsOptions] : null;
        const salesChannels = selectedMarketplaceChannelsOptions.length ? selectedMarketplaceChannelsOptions : null;
        const origins = selectedOriginsOptions.length > 0 ? selectedOriginsOptions : null;
        const weekDays = selectedWeekDaysOptions.length > 0 ? selectedWeekDaysOptions : null;
        const vtexAffiliates = selectedAffiliatesOptions.length ? selectedAffiliatesOptions : null;


        const filter = {
            initialDate: formatDate(startDate),
            finalDate: formatDate(endDate),
            timezone,
            promotionNames,
            promotionTypes,
            promotionStatus,
            salesChannels,
            origins,
            coupons,
            weekDays,
            vtexAffiliates
        }

        return filter;
    }

    const handleApplyFilter = async () => {
        getDataByAppliedFilter();
    }

    const getWeekDaysFilterCustomLabel = (values) => {
        const labels = {
            1: t('common.sunday'),
            2: t('common.monday'),
            3: t('common.tuesday'),
            4: t('common.wednesday'),
            5: t('common.thursday'),
            6: t('common.friday'),
            7: t('common.saturday')
        };

        return values.map((value) => value in labels ? labels[value] : value);
    };

    const getWeekDaysFilterValueByCustomLabel = (values) => {
        const labels = {
            [t('common.sunday')]: 1,
            [t('common.monday')]: 2,
            [t('common.tuesday')]: 3,
            [t('common.wednesday')]: 4,
            [t('common.thursday')]: 5,
            [t('common.friday')]: 6,
            [t('common.saturday')]: 7
        };

        return values.map((value) => value in labels ? labels[value] : value);
    };

    const sortFilterOptions = (options) => {
        return options.sort((a, b) => a > b ? 1 : -1);
    }

    const getPromotionsNameSortedByApprovedOrders = useCallback((data) => {
        if (!data) { return [] };

        const promotionsApprovedOrdersGroupByPromotionName = data.results.reduce((acc, { promotion_name, approved_orders }) => {
            acc[promotion_name] = (acc[promotion_name] || 0) + approved_orders;
            return acc;
        }, {});

        const sortedPromotions = Object.entries(promotionsApprovedOrdersGroupByPromotionName)
            .sort(([, approvedA], [, approvedB]) => approvedB - approvedA);

        if (sortedPromotions.length <= MAX_NUMBER_OF_PROMOTIONS_VISIBLE_ON_GRAPH) {
            return sortedPromotions.map(([name]) => name);
        }

        return [...sortedPromotions.map(([name]) => name).slice(0, MAX_NUMBER_OF_PROMOTIONS_VISIBLE_ON_GRAPH), t('common.other')];
    }, [t]);

    const getPromotionPerformanceData = useCallback(async (filter) => {
        setIsLoading(true);

        try {
            const response = await Promise.all([
                getPromotionsDailyOverview(filter),
                getPromotionsCustomerUsageFrequency(filter),
                getCouponDailyOverview(filter),
            ])

            const promotionsDailyOverviewResponse = response[0];
            const promotionsCustomerUsageFrequencyResponse = response[1];
            const couponsDailyOverviewResponse = response[2];

            if (!promotionsDailyOverviewResponse || !promotionsCustomerUsageFrequencyResponse || !couponsDailyOverviewResponse) {
                setPromotionsDailyOverviewData([]);
                setPromotionsCustomerUsageFrequencyData([]);
                setCouponsDailyOverviewData([]);
                setPromotionNameOptions([]);
                setPromotionTypeOptions([]);
                setPromotionStatusOptions([]);
                setMarketplaceChannelsOptions([]);
                setOriginsOptions([]);
                setCouponsOptions([]);
                setWeekDaysOptions([]);
                setAffiliatesOptions([]);

                return;
            }

            setPromotionsDailyOverviewData(promotionsDailyOverviewResponse.results);

            setPromotionsCustomerUsageFrequencyData(promotionsCustomerUsageFrequencyResponse.results);

            setCouponsDailyOverviewData(couponsDailyOverviewResponse.results);

            setVisiblePromotionsName(getPromotionsNameSortedByApprovedOrders(promotionsDailyOverviewResponse));
            setPromotionNameOptions(promotionsDailyOverviewResponse.available_promotion_names_to_filter)
            setPromotionTypeOptions(promotionsDailyOverviewResponse.available_promotion_types_to_filter)
            setPromotionStatusOptions(promotionsDailyOverviewResponse.available_promotion_status_to_filter)
            
            setMarketplaceChannelsOptions(promotionsDailyOverviewResponse.available_marketplace_channels_to_filter)
            setOriginsOptions(promotionsDailyOverviewResponse.available_origins_to_filter)
            setCouponsOptions(promotionsDailyOverviewResponse.available_coupons_to_filter);

            setWeekDaysOptions(sortFilterOptions(promotionsDailyOverviewResponse.available_week_days_to_filter))

            if (isValidAvailableFilter(promotionsDailyOverviewResponse.available_vtex_affiliates_to_filter)) {
                setAffiliatesOptions(promotionsDailyOverviewResponse.available_vtex_affiliates_to_filter);
            } else {
                setAffiliatesOptions([])
            }

        } catch (error) {
            toast.error(error.message);
            setPromotionsDailyOverviewData([]);
            setPromotionsCustomerUsageFrequencyData([]);
            setCouponsDailyOverviewData([]);

            setPromotionNameOptions([]);
            setPromotionTypeOptions([]);
            setPromotionStatusOptions([]);
            setCouponsOptions([]);
            setMarketplaceChannelsOptions([]);
            setOriginsOptions([]);
            setWeekDaysOptions([]);
            setAffiliatesOptions([]);

        } finally {
          setIsLoading(false);
        }

    }, [getPromotionsNameSortedByApprovedOrders]);

    const generateFieldKey = (field, complementKey) => {
        return `${field}_${complementKey}`;
    }

    const getGraphConfigs = (fields, complementKey) => {
        if (!fields) { return []; }

        const configs = [];

        fields.forEach(field => {
            const fieldDataKey = generateFieldKey(field, complementKey);

            configs.push({
            name: field || " ",
            dataKey: fieldDataKey
            });
        })

        return configs;
    };
    
    const formatEvolutionOfApprovedOrdersByPromotionsData = (data, dimension) => {
        if (!data) { return null };
    
        const aggregatedData = {};
        const promotionsName = new Set();
        let totalApprovedOrdersByDate = {};
    
        data.forEach(entry => {
            if (!entry.approved_orders) {
                return;
            }
    
            const key = getAgregatedKeyByDimension(entry.date, dimension);

            const promotionName  = visiblePromotionsName.includes(entry.promotion_name) ? entry.promotion_name : t('common.other');
            const approvedOrdersKey = generateFieldKey(promotionName, "approvedOrders");
    
            promotionsName.add(promotionName);
    
            if (!aggregatedData[key]) {
                aggregatedData[key] = { 
                    date: key 
                };
            }
    
            if (!aggregatedData[key][approvedOrdersKey]) {
                aggregatedData[key][approvedOrdersKey] = 0.0;
            }
    
            aggregatedData[key][approvedOrdersKey] += entry.approved_orders;
    
            if (!totalApprovedOrdersByDate[key]) {
                totalApprovedOrdersByDate[key] = 0.0;
            }
    
            totalApprovedOrdersByDate[key] += entry.approved_orders;
        });
    
        return Object.values(aggregatedData);
    };

    const formatShareOfApprovedOrdersByCouponData = (data) => {
        if (!data)  { return null };

        return data.sort((a, b) => b.approved_orders - a.approved_orders);
    };

    const formatFrequencyOfUsePromotionsByCustomerData = (data = []) => {
        if (!data) { return null };
        
        const aggregatedData = [];

        data.forEach(entry => {
            aggregatedData.push({
                binLabel: entry.bin_label,
                totalCustomers: entry.total_customers
            });
        });

        return aggregatedData;
    };

    const promotionsPerformanceTableData = (data) => {
        if (!data) { return [] };
        const aggregatedData = {};

        data.forEach(item => {
            const { promotion_id, promotion_name, promotion_type, promotion_status } = item;

            if (!aggregatedData[promotion_id]) {
                aggregatedData[promotion_id] = {
                    promotion_id,
                    promotion_name,
                    promotion_type,
                    promotion_status,
                    captured_revenue: 0,
                    approved_revenue: 0,
                    approved_orders: 0,
                    canceled_orders: 0,
                    approved_avg_ticket: 0,
                    approval_rate: 0,
                    total_orders: 0
                };
            }

            aggregatedData[promotion_id].captured_revenue += item.captured_revenue;
            aggregatedData[promotion_id].approved_revenue += item.approved_revenue;
            aggregatedData[promotion_id].approved_orders += item.approved_orders;
            aggregatedData[promotion_id].canceled_orders += item.canceled_orders;
            aggregatedData[promotion_id].total_orders += item.total_orders;
        });

        Object.values(aggregatedData).forEach(item => {
            item.approved_avg_ticket = item.approved_orders > 0 
                ? item.approved_revenue / item.approved_orders 
                : 0;

            item.approval_rate = item.total_orders > 0 
                ? customRound((item.approved_orders / item.total_orders) * 100)
                : 0;
        });

        const aggregatedRows = Object.values(aggregatedData);

        return {
            headers: [
                { name: t('promotionPerformance.promotion_name'), value: "promotion_name", minWidth: 100, format: (value) => value },
                { name: t('promotionPerformance.promotion_type'), value: "promotion_type", minWidth: 100, format: (value) => value },
                { name: t('promotionPerformance.promotion_status'), value: "promotion_status", minWidth: 100, format: (value) => value },
                { name: t('common.captured_revenue'), value: "captured_revenue", minWidth: 100, format: value => formatValueToMoney(value, currency) },
                { name: t('common.approved_revenue'), value: "approved_revenue", minWidth: 100, format: value => formatValueToMoney(value, currency) },
                { name: t('common.approved_orders'), value: "approved_orders", minWidth: 100, format: (value) => value },
                { name: t('common.canceled_orders'), value: "canceled_orders", minWidth: 100, format: (value) => value },
                { name: t('common.total_orders'), value: "total_orders", minWidth: 100, format: (value) => value },
                { name: t('common.approved_average_ticket'), value: "approved_avg_ticket", minWidth: 100, format: value => formatValueToMoney(value, currency) },
                { name: t('common.approval_rate'), value: "approval_rate", minWidth: 100, format: formatValueToPercentage },
            ],
            rows: aggregatedRows
        };

    };

    const couponsAnalysisTableData = (data) => {
        if (!data)  { return { headers: [] }};

        return {
            headers: [
                { name: t('common.coupon'), value: "coupon_name", minWidth: 100, format: (value) => value },
                { name: t('common.captured_revenue'), value: "captured_revenue", minWidth: 100, format: value => formatValueToMoney(value, currency) },
                { name: t('common.approved_revenue'), value: "approved_revenue", minWidth: 100, format: value => formatValueToMoney(value, currency) },
                { name: t('common.approved_orders'), value: "approved_orders", minWidth: 100, format: (value) => value },
                { name: t('common.canceled_orders'), value: "canceled_orders", minWidth: 100, format: (value) => value },
                { name: t('common.total_orders'), value: "total_orders", minWidth: 100, format: (value) => value },
                { name: t('common.approved_average_ticket'), value: "approved_avg_ticket", minWidth: 100, format: value => formatValueToMoney(value, currency) },
                { name: t('common.approval_rate'), value: "approval_rate", minWidth: 100, format: formatValueToPercentage },
                
            ],
            rows: data
        };      
    }

    const handleExportCouponsReport = async () => {
        setExportReportLoading(true);

        try {
            toast.info(t('toast.wait_for_report'));

            const filter = getActiveFilters()

            await sendCouponsDailyOverviewReportByEmail(filter)

            toast.success(t('toast.report_preparation'));
        } catch (error) {
            toast.error(t('toast.report_generation_failed'));
        } finally {
            setExportReportLoading(false);
        }
    };

    const handleExportPromotionsReport = async () => {
        setExportReportLoading(true);

        try {
            toast.info(t('toast.wait_for_report'));

            const filter = getActiveFilters()

            await sendPromotionsOverviewReportByEmail(filter)

            toast.success(t('toast.report_preparation'));
        } catch (error) {
            toast.error(t('toast.report_generation_failed'));
        } finally {
            setExportReportLoading(false);
        }
    };

    const getSecondaryFilters = () => {
        return [
            {
                title: `${t('common.source')}`,
                options: originsOptions,
                value: selectedOriginsOptions,
                setValue: setSelectedOriginsOptions
            },
            {
                title: t('common.coupons'),
                options: couponsOptions,
                value: selectedCouponsOptions,
                setValue: setSelectedCouponsOptions
            },
            {
                title: `${t('common.sales_channel')}`,
                options: marketplaceChannelsOptions,
                value: selectedMarketplaceChannelsOptions,
                setValue: setSelectedMarketplaceChannelsOptions
            },
            {
                title: `${t('common.affiliates_vtex')}`,
                options: affiliatesOptions,
                value: selectedAffiliatesOptions,
                setValue: setSelectedAffiliatesOptions
            },
            {
                title: t('common.day_of_the_week'),
                options: getWeekDaysFilterCustomLabel(weekDaysOptions),
                value: getWeekDaysFilterCustomLabel(selectedWeekDaysOptions),
                setValue: (values) => setSelectedWeekDaysOptions(getWeekDaysFilterValueByCustomLabel(values))
            },
            {
                title:  `${t('promotionPerformance.promotion_name')}`,
                options: promotionNameOptions,
                value: selectedPromotionNameOptions,
                setValue: setSelectedPromotionNameOptions
            },
            {
                title: `${t('promotionPerformance.promotion_type')}`,
                options: promotionTypeOptions,
                value: selectedPromotionTypeOptions,
                setValue: setSelectedPromotionTypeOptions
            },
            {
                title: `${t('promotionPerformance.promotion_status')}`,
                options: promotionStatusOptions,
                value: selectedPromotionStatusOptions,
                setValue: setSelectedPromotionStatusOptions
            },
        ]
      }

      const onRenderComponent = useCallback(async () => {
        const { initialDateFilter, finalDateFilter } = getSelectedInitialDateRange();
        setDateRange([initialDateFilter, finalDateFilter]);

        await getPromotionPerformanceData({
            initialDate: formatDate(initialDateFilter),
            finalDate: formatDate(finalDateFilter),
            
        });
      }, [getPromotionPerformanceData]);

      useEffect(() => {
        onRenderComponent();
      }, [onRenderComponent]);
      
    return (
        <BackgroundNew
          titlePage={t('menu.promotions_performance')}
        >
            <FilterContainer
                selectedDateRange={dateRange}
                setDateRange={setDateRange}
                onClickFilter={handleApplyFilter}
                showSecondaryFilters={true}
                secondaryFilters={getSecondaryFilters()}
            />
            <CardGroup
                title={t('promotionPerformance.evolution_of_approved_orders_by_promotions')}
                tagIdentifierColor="#00CCAE"
                isLoading={isLoading}
                customClassName="cardEvolutionDayPerPaymentMethodRef"
                ref={cardEvolutionOfApprovedOrdersByPromotionsRef}
            >
                <ComposedGraphBarLine
                    data={promotionsDailyOverviewData}
                    dimension={selectedDimension}
                    showMultiBarStacked={true}
                    multiBarStackedConfigs={getGraphConfigs(visiblePromotionsName, "approvedOrders")}
                    formatData={(data, dimension) => formatEvolutionOfApprovedOrdersByPromotionsData(data, dimension)}
                    formaterBarValue={value =>formatValueToNumber(value, currency)}
                    graphMarginConfig={{
                        top: 20,
                        right: -30,
                        left: 20,
                        bottom: 20
                    }}
                    showDimensionController={true}
                    setSelectedDimension={setSelectedDimension}
                    disabledDimensionController={!promotionsDailyOverviewData?.length}
                    showFullScreenOption={true}
                    fullScreenEnabled={evolutionOfApprovedOrdersByPromotionsGraphFullScreen}
                    setFullScreenEnabled={setEvolutionOfApprovedOrdersByPromotionsGraphFullScreen}
                    showExportPDFButton={true}
                    graphComponentRefForPDF={cardEvolutionOfApprovedOrdersByPromotionsRef}
                    showDataLabel={true}
                    dataLabelEnabled={graphDataLabelEnabled}
                    setDataLabelEnabled={setGraphDataLabelEnabled}
                    barDataLabelEnabled={true}
                />
            </CardGroup>
            <CardGroup
                title={t('promotionPerformance.frequency_of_use_promotions_by_customer')}
                tagIdentifierColor="#00CCAE"
                isLoading={isLoading}
                customClassName="cardFrequencyofUseOfPromotionsByCustomerRef"
                ref={cardFrequencyofUseOfPromotionsByCustomerRef}
            >
                <ComposedGraphBarLine
                    data={promotionsCustomerUsageFrequencyData}
                    showPrimaryBarYAxis
                    formatData={formatFrequencyOfUsePromotionsByCustomerData}
                    formaterBarValue={value => formatValueToNumber(value, currency)}                    
                    primaryBarYAxisConfig={{
                        name: t('promotionPerformance.total_customers'),
                        dataKey: "totalCustomers"
                    }}
                    graphMarginConfig={{
                        top: 20,
                        right: -30,
                        left: 5,
                        bottom: 20
                    }}
                    xDataKey='binLabel'
                    setSelectedDimension={setSelectedDimension}
                    showFullScreenOption={true}
                    fullScreenEnabled={frequencyofUseOfPromotionsByCustomerGraphFullScreen}
                    setFullScreenEnabled={setFrequencyofUseOfPromotionsByCustomerGraphFullScreen}
                    showExportPDFButton={true}
                    graphComponentRefForPDF={cardFrequencyofUseOfPromotionsByCustomerRef}
                    showDataLabel={true}
                    dataLabelEnabled={graphDataLabelEnabled}
                    setDataLabelEnabled={setGraphDataLabelEnabled}
                    barDataLabelEnabled={true}
                />
            </CardGroup>

                <CardGroup
                    title={t('promotionPerformance.share_of_approved_orders_by_coupon')}
                    tagIdentifierColor="#00CCAE"
                    isLoading={isLoading}
                    customClassName="cardShareOfApprovedOrdersByCouponRef"
                    ref={cardShareOfApprovedOrdersByCouponRef}
                >
                    <GraphPie
                        data={couponsDailyOverviewData}
                        dimension={selectedDimension}
                        dataKeyLabel="coupon_name"
                        dataKeyValue="approved_orders"
                        formatData={data => formatShareOfApprovedOrdersByCouponData(data, 'coupon_name')}
                        formaterValue={value => formatValueToNumber(value)}
                        graphMarginConfig={{
                            top: 20,
                            right: -40,
                            left: -20,
                            bottom: 60
                        }}
                        showDimensionController={true}
                        setSelectedDimension={setSelectedDimension}
                        disabledDimensionController={!couponsDailyOverviewData?.length}
                        showFullScreenOption={true}
                        fullScreenEnabled={shareOfApprovedOrdersByCouponGraphFullScreen}
                        setFullScreenEnabled={setShareOfApprovedOrdersByCouponGraphFullScreen}
                        domainBarYAxis={[0, 100]}
                        showExportPDFButton={true}
                        graphComponentRefForPDF={cardShareOfApprovedOrdersByCouponRef}
                        showDataLabel={true}
                        dataLabelEnabled={graphDataLabelEnabled}
                        setDataLabelEnabled={setGraphDataLabelEnabled}
                    />
                </CardGroup>
                <CardGroup
                    title={t('promotionPerformance.promotions')}
                    tagIdentifierColor="#00CCAE"
                    isLoading={isLoading}
                >
                    <CustomTableApiPagination
                        data={promotionsPerformanceTableData(promotionsDailyOverviewData)}
                        disabledDimensionController={!promotionsDailyOverviewData?.length}
                        showFullScreenOption
                        fullScreenEnabled={promotionsPerformanceTableFullScreen}
                        setFullScreenEnabled={setPromotionsPerformanceTableFullScreen}
                        handleExportCSVReport={handleExportPromotionsReport}
                        showExportCSVReportButton
                        loadingExportCSVReportButton={exportReportLoading}
                        showPagination={false}
                    />
                </CardGroup>
                <CardGroup
                    title={t('common.coupons')}
                    tagIdentifierColor="#00CCAE"
                    isLoading={isLoading}
                >
                    <CustomTableApiPagination
                        data={couponsAnalysisTableData(couponsDailyOverviewData)}
                        disabledDimensionController={!couponsDailyOverviewData?.length}
                        showFullScreenOption
                        fullScreenEnabled={couponAnalysisMetricsTableFullScreen}
                        setFullScreenEnabled={setCouponAnalysisMetricsTableFullScreen}
                        handleExportCSVReport={handleExportCouponsReport}
                        showExportCSVReportButton
                        loadingExportCSVReportButton={exportReportLoading}
                        showPagination={false}
                    />
                </CardGroup>
        </BackgroundNew>
    );

}

export default PromotionPerformance