const currencyFormatter = require('currency-formatter');

export const getSymbolFromCurrency = require('currency-symbol-map');

export const formatValueToMoney = (value, currency) => {
  return currencyFormatter.format(value, { code: currency });
}

export const formatValueToNumber = (value) => {
  const formatter = new Intl.NumberFormat();

  return formatter.format(value);
}

export const formatValueToPercentage = (value) => {
  const formatter = new Intl.NumberFormat();

  return `${formatter.format(value)}%`;
}


export const getVariationValue = (value, goal) => {
  if (value === 0 && goal !== 0) {
    return -100;
  }

  if (value !== 0 && goal === 0) {
    return 100;
  }

  const variation = ((value / goal) - 1) * 100;

  return Number(variation.toFixed(2));
}

export const extractNumbersFromString = (input) => {
  const cleanedInput = input.replace(/[^0-9,]/g, '').replace(',', '.');

  return parseFloat(cleanedInput) || 0;
}

export const customRound = (value) => {
  return (value % 1 >= 0.5) ? Math.ceil(value) : Math.floor(value);
}

export const abbreviateNumber = (value, language = 'pt_br', currency = null, isPercentage = false) => {
  const languageMap = {
    en_us: 'en-US',
    pt_br: 'pt-BR',
    es: 'es',
  };

  const locale = languageMap[language] || 'pt-BR';
  const thresholds = [1e12, 1e9, 1e6, 1e3];
  const suffixes = {
    'en-US': ['T', 'B', 'M', 'K'],
    'pt-BR': [' tri', ' bil', ' mi', ' mil'],
    'es': [' bill', ' mil mill', ' mill', ' mil'],
  };

  for (let i = 0; i < thresholds.length; i++) {
    if (value >= thresholds[i]) {
      const abbreviatedValue = value / thresholds[i];
      const formattedNumber = new Intl.NumberFormat(locale, {
        style: currency ? 'currency' : 'decimal',
        currency: currency || undefined,
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      }).format(abbreviatedValue);

      return `${formattedNumber}${suffixes[locale][i]}${isPercentage ? '%' : ''}`;
    }
  }

  const formattedValue = new Intl.NumberFormat(locale, {
    style: currency ? 'currency' : 'decimal',
    currency: currency || undefined,
    minimumFractionDigits: (currency || isPercentage) ? 2 : 0,
    maximumFractionDigits: (currency || isPercentage) ? 2 : 0,
  }).format(value);

  return `${formattedValue}${isPercentage ? '%' : ''}`;
};
