/* eslint-disable camelcase */
import React, { useState, useEffect, useContext, useMemo } from "react";
import { toast } from "react-toastify";
import Slide from "@mui/material/Slide";
import { MdAdd, MdClose, MdFileDownload, MdFileUpload } from "react-icons/md";
import * as S from "./styles";
import BackgroundNew from "../../components/BackgroundNew";
import UserMenu from "../../components/User/UserMenu";
import { AlertModal, CloseButton } from "../Alerts/styles";
import { readFileAsync, getFileLines } from "../../utils/fileUtils";
import CustomModal from "../../components/CustomModal/CustomModal";
import Loader from "../../components/Loader";

import {
  getUsers,
  getUser,
  editUserSettings,
  createUsers,
  createUser,
  editUserContactInfo,
  deleteUser,
} from "../../services/odinService";

import { useTranslation } from "react-i18next";
import "../../i18n";
import { AuthContext } from "../../contexts";

function Users() {
  const { t } = useTranslation();

  const { user } = useContext(AuthContext);

  const ERRORS_MESSAGE_DICT = useMemo(
    () => ({
      ERR_USERNAME_ALREADY_EXISTS: t("profile.username_already_exists"),
      ERR_OLD_PASSWORD_INCORRECT: t("profile.old_password_incorrect"),
      ERR_PASSWORD_MISSING_UPPERCASE_LOWERCASE: t("profile.password_requirements"),
      ERR_PASSWORD_MISSING_SPECIAL_CHARS: t("profile.password_requirements"),
      ERR_PASSWORD_MISSING_LENGTH: t("profile.password_requirements"),
      ERR_PASSWORD_MISSING_NUMBERS: t("profile.password_requirements"),
    }),
    [t]
  );

  const [userCompanyId, setUserCompanyId] = useState("");
  const [isCompanyOwnerUser, setIsCompanyOwnerUser] = useState(false);
  const [show, setShow] = useState(false);
  const [showUploadUsersList, setShowUploadUsersList] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [userId, setUserId] = useState("");
  const [users, setUsers] = useState([]);
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [username, setUsername] = useState("");
  const [usersListFile, setUsersListFile] = useState(null);
  const [enableSaveButton, setEnableSaveButton] = useState(true);
  const [openModalInformation, setOpenModalInformation] = useState(false);
  const [loading, setLoading] = useState(true);

  const isValidFieldsValues = () => {
    let result = true;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const spaceRegex = /^\s*$/;

    if (!email) {
      toast.error(t("toast.email_required"));
      result = false;
    }

    if (email && !emailRegex.test(email)) {
      toast.error(t("toast.email_invalid"));
      result = false;
    }

    if (!first_name) {
      toast.error(t("toast.name_required"));
      result = false;
    }

    if (first_name && spaceRegex.test(first_name)) {
      toast.error(t("toast.name_invalid"));
      result = false;
    }

    if (!username) {
      toast.error(t("toast.username_required"));
      result = false;
    }

    if (username && spaceRegex.test(username)) {
      toast.error(t("toast.username_invalid"));
      result = false;
    }

    if (!last_name) {
      toast.error(t("toast.last_name_required"));
      result = false;
    }

    if (last_name && spaceRegex.test(last_name)) {
      toast.error(t("toast.last_name_invalid"));
      result = false;
    }

    return result;
  };

  const handleCancel = () => {
    setShow(false);
    setShowEdit(false);
    setShowDelete(false);
    setShowUploadUsersList(false);
    setEnableSaveButton(true);

    setFirst_name("");
    setLast_name("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setUsername("");
    setUsersListFile(null);
  };

  const handleCreatePopup = () => setShow(!show);
  const handleShowPopupUploadUsersListFile = () => setShowUploadUsersList(!showUploadUsersList);

  const handleEditPopup = () => {
    setShowEdit(!showEdit);
  };

  const handleDeletePopup = () => {
    setShowDelete(!showDelete);
  };

  async function listUser() {
    setLoading(true);

    const response = await getUsers();

    setUsers(response);

    setLoading(false);
  }

  useEffect(() => {
    setIsCompanyOwnerUser(user?.is_company_owner_user);
    setUserCompanyId(user?.company_id);
  }, [user]);

  useEffect(() => {
    listUser();
  }, []);

  async function searchUser(id) {
    const response = await getUser(id);
    setFirst_name(response.contact_info.first_name);
    setLast_name(response.contact_info.last_name);
    setEmail(response.contact_info.email);
    setUsername(response.username);
  }

  const handleShow = (user, action) => {
    switch (action) {
      case "delete":
        setDelete(user.id);
        break;

      case "edit":
        setEdit(user.id);
        break;

      default:
        return false;
    }
  };

  async function handleUserStatus(userId, userStatus) {
    const data = {
      inactive: userStatus,
    };

    await editUserSettings(userId, data);

    listUser();
  }

  async function setDelete(id) {
    setUserId(id);
    handleDeletePopup();
  }

  async function setEdit(id) {
    setUserId(id);
    await searchUser(id);
    handleEditPopup();
  }

  async function handleCreateFromFile() {
    if (usersListFile === null) {
      toast.error(t("toast.select_file_import"));
      return;
    }

    setEnableSaveButton(false);

    let contentCSV = await readFileAsync(usersListFile);

    let linesCSVFile = getFileLines(contentCSV);

    linesCSVFile.shift();

    if (linesCSVFile.length === 0) {
      toast.error(t("toast.invalid_file"));

      setEnableSaveButton(true);

      return;
    }

    let users = [];

    for (const line of linesCSVFile) {
      let lineSplited = line.split(",");

      if (lineSplited.length === 0 || lineSplited.length < 5) {
        break;
      }

      let data = {
        username: lineSplited[3],
        password: lineSplited[4],
        role: "regular",
        user_type: "other",
        company_id: userCompanyId,
        contact_info: {
          email: lineSplited[2],
          first_name: lineSplited[0],
          last_name: lineSplited[1],
        },
        telemetry: {
          registration_origin: "platform",
        },
      };

      if (isValidUser(data)) {
        users.push(data);
      } else {
        break;
      }
    }

    if (users.length === 0) {
      toast.error(t("toast.invalid_file"));

      setEnableSaveButton(true);

      return;
    }

    try {
      await createUsers(users);

      toast.success(t("toast.users_created_success"));

      handleCancel();

      listUser();
    } catch (error) {
      toast.error(t("toast.user_creation_error"));

      setEnableSaveButton(true);
    }
  }

  function isValidUser(user) {
    let result = true;

    var mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;

    if (!user.contact_info.email.match(mailformat)) {
      result = false;
    }

    if (user.password.length < 8) {
      result = false;
    }

    if (user.username.length === 0) {
      result = false;
    }

    if (user.contact_info.first_name.length === 0) {
      result = false;
    }

    if (user.contact_info.last_name.length === 0) {
      result = false;
    }

    return result;
  }

  async function handleCreate() {
    try {
      if (!isValidFieldsValues()) {
        return;
      }
      const data = {
        username: username,
        password,
        company_id: userCompanyId,
        role: "regular",
        user_type: "other",
        contact_info: {
          email,
          first_name,
          last_name,
        },
        telemetry: {
          registration_origin: "platform",
        },
      };

      await createUser(data);

      toast.success(t("toast.user_created_success"));
      handleCreatePopup();
      listUser();
      handleCancel();
    } catch (error) {
      toast.error(ERRORS_MESSAGE_DICT[error.message] || error.message);
      toast.error(t("toast.user_creation_error_again"));
    }
  }

  async function handleEdit() {
    try {
      if (!isValidFieldsValues()) {
        return;
      }
      const contactInfoData = {
        email,
        first_name,
        last_name,
      };

      await editUserContactInfo(userId, contactInfoData);

      toast.success(t("toast.user_updated_success"));
      handleEditPopup();
      listUser();
      handleCancel();
    } catch (error) {
      toast.error(ERRORS_MESSAGE_DICT[error.message] || error.message);
      toast.error(t("toast.user_update_error"));
    }
  }

  async function handleDelete() {
    try {
      await deleteUser(userId);

      toast.success(t("toast.user_removed_success"));
      handleDeletePopup();
      listUser();
    } catch (error) {
      toast.error(t("toast.user_remove_error"));
    }
  }
  return (
    <BackgroundNew
      titlePage={t("appBar.manage_users")}
      subTitlePage={t("users.build_your_team")}
      showInformation={true}
      informationTooltipTitle={t("common.click_to_learn_more")}
      informationOnClick={() => setOpenModalInformation(true)}
    >
      <S.Container>
        <div className="header">
          <div className="group">
            {isCompanyOwnerUser && (
              <button className="add_users_list" type="button" onClick={handleShowPopupUploadUsersListFile}>
                {t("users.add_users_csv")}
                <MdFileUpload size={30} />
              </button>
            )}
            {isCompanyOwnerUser && (
              <button type="button" onClick={handleCreatePopup}>
                {t("users.add_user")}
                <MdAdd size={22} />
              </button>
            )}
          </div>
        </div>

        <div className="workSpace">
          {!loading &&
            users &&
            users.length > 0 &&
            users.map((user, index) => (
              <Slide direction="up" in mountOnEnter unmountOnExit>
                <div className="option" key={index}>
                  <div className="user">
                    <p className="word">
                      {user.contact_info.first_name.substring(0, 1)}
                      {user.contact_info.last_name.substring(0, 1)}
                    </p>

                    <div className="descriptionOption">
                      <p className="titleOption">
                        {user.contact_info.first_name} {user.contact_info.last_name}
                      </p>
                      <p>{user.username}</p>
                    </div>
                  </div>

                  <div className="functions">
                    {isCompanyOwnerUser && (
                      <>
                        <S.OnOff
                          checked={!user.settings.inactive}
                          onChange={() => handleUserStatus(user.id, !user.settings.inactive)}
                        />
                        <UserMenu user={user} show={handleShow} />
                      </>
                    )}
                  </div>
                </div>
              </Slide>
            ))}
          {!loading && users.length === 0 && (
            <div className="noUsers">
              <p>Nenhum usuário encontrado</p>
            </div>
          )}
          {loading && (
            <div className="loading">
              <Loader />
            </div>
          )}
        </div>
      </S.Container>

      <S.NewRegister open={showUploadUsersList} onClose={handleCancel}>
        <div className="modal">
          <div className="modal__header">
            <div className="modal__header-inner-wrapper">
              <p className="titleModal">{t("users.create_users_csv")}</p>
              <S.CloseButton type="button" title={t("common.close")} onClick={handleCancel}>
                <MdClose size={25} color="#000" />
              </S.CloseButton>
            </div>
            <div className="line" />
          </div>

          <div className="modal__body">
            <div className="group">
              <div className="input">
                <p>{t("common.upload_file")}*</p>
                <input
                  type="file"
                  id="usersList"
                  accept=".csv"
                  onChange={(event) => setUsersListFile(event.target.files[0])}
                />
              </div>
              <div className="modal__body__download__group">
                <p>{t("common.download_template_file")}</p>
                <a
                  className="modal__body__download__group__button_download"
                  href="https://drive.google.com/uc?export=download&id=1GqHplOmfOJOAZCOx5jBEVczedWeQe5vI"
                  download="modelo_usuarios.csv"
                >
                  {t("common.download_file")} <MdFileDownload size={16} />
                </a>
              </div>
            </div>
          </div>

          <div className="modal__footer">
            <button className="btn btn--cancel" type="button" onClick={handleCancel}>
              {t("common.cancel")}
            </button>
            <button
              className="btn btn--save"
              type="button"
              onClick={handleCreateFromFile}
              disabled={!enableSaveButton}
            >
              {t("common.save")}
            </button>
          </div>
        </div>
      </S.NewRegister>

      <S.NewRegister open={show} onClose={handleCancel}>
        <div className="modal">
          <div className="modal__header">
            <div className="modal__header-inner-wrapper">
              <p className="titleModal">{t("users.new_user")}</p>
              <S.CloseButton type="button" title={t("common.close")} onClick={handleCancel}>
                <MdClose size={25} color="#000" />
              </S.CloseButton>
            </div>
            <div className="line" />
          </div>

          <div className="modal__body">
            <div className="group">
              <div className="input">
                <p>{t("users.first_name")} *</p>
                <input
                  placeholder={t("users.first_name")}
                  value={first_name}
                  onChange={(value) => setFirst_name(value.target.value)}
                />
              </div>
              <div className="input">
                <p>{t("users.middle_name")} *</p>
                <input
                  placeholder={t("users.middle_name")}
                  value={last_name}
                  onChange={(value) => setLast_name(value.target.value)}
                />
              </div>
            </div>
            <div className="inputNormal">
              <p>{t("users.email")} *</p>
              <input
                placeholder={t("users.email")}
                type="email"
                value={email}
                onChange={(value) => setEmail(value.target.value)}
              />
            </div>
            <div className="inputNormal">
              <p>{t("users.user")} *</p>
              <input
                placeholder={t("users.user")}
                value={username}
                onChange={(value) => setUsername(value.target.value)}
              />
            </div>
            <div className="inputNormal">
              <p>{t("users.password")} *</p>
              <input
                placeholder={t("users.password")}
                type="password"
                value={password}
                onChange={(value) => setPassword(value.target.value)}
              />
            </div>
            <div className="inputNormal">
              <p>{t("users.confirm_password")} *</p>
              <input
                placeholder={t("users.confirm_password")}
                type="password"
                value={confirmPassword}
                onChange={(value) => setConfirmPassword(value.target.value)}
              />
            </div>
          </div>

          <div className="modal__footer">
            <button className="btn btn--cancel" type="button" onClick={handleCancel}>
              {t("common.cancel")}
            </button>
            <button className="btn btn--save" type="button" onClick={handleCreate}>
              {t("common.save")}
            </button>
          </div>
        </div>
      </S.NewRegister>

      <S.NewRegister open={showEdit} onClose={handleCancel}>
        <div className="modal">
          <div className="modal__header">
            <div className="modal__header-inner-wrapper">
              <p className="titleModal">{t("users.edit_user")}</p>
              <S.CloseButton type="button" title={t("common.close")} onClick={handleCancel}>
                <MdClose size={25} color="#000" />
              </S.CloseButton>
            </div>
            <div className="line" />
          </div>

          <div className="modal__body">
            <div className="group">
              <div className="input">
                <p>{t("users.first_name")} *</p>
                <input
                  placeholder={t("users.first_name")}
                  value={first_name}
                  onChange={(value) => setFirst_name(value.target.value)}
                />
              </div>
              <div className="input">
                <p>{t("users.middle_name")} *</p>
                <input
                  placeholder={t("users.middle_name")}
                  value={last_name}
                  onChange={(value) => setLast_name(value.target.value)}
                />
              </div>
            </div>
            <div className="inputNormal">
              <p>{t("users.email")} *</p>
              <input
                placeholder={t("users.email")}
                type="email"
                value={email}
                onChange={(value) => setEmail(value.target.value)}
              />
            </div>
          </div>

          <div className="modal__footer">
            <button className="btn btn--cancel" type="button" onClick={handleCancel}>
              {t("common.cancel")}
            </button>
            <button
              className="btn btn--save"
              type="button"
              onClick={() => {
                handleEdit();
              }}
            >
              {t("alerts.edit")}
            </button>
          </div>
        </div>
      </S.NewRegister>

      <AlertModal open={showDelete} onClose={handleCancel}>
        <div className={`modal is--delete`}>
          <div className="modal__header">
            <div className="modal__header-inner-wrapper">
              <p className="titleModal">{t("users.delete_user")}</p>
              <CloseButton type="button" title={t("common.close")} onClick={handleCancel}>
                <MdClose size={25} color="#000" />
              </CloseButton>
            </div>
            <div className="line" />
          </div>
          <div className="modal__body">
            <p>
              {t("users.confirm_delete_user")} <br />
              <strong>{t("common.this_action_cannot_be_undone")}</strong>
            </p>
          </div>
          <div className="modal__footer">
            <button className="btn btn--cancel" type="button" onClick={handleCancel}>
              {t("common.cancel")}
            </button>
            <button
              className="btn btn--save"
              type="button"
              onClick={() => {
                handleDelete();
              }}
            >
              {t("common.confirm")}
            </button>
          </div>
        </div>
      </AlertModal>
      <CustomModal
        title={t("users.understand_users_feature")}
        open={openModalInformation}
        handleClose={() => setOpenModalInformation(false)}
        hiddenConfirmButton={true}
        cancelButtonLabel={t("common.close")}
      >
        <div className="video">
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/seSRxGOV6cc?si=E5m6ejlSGfBVPv3G"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
            allowFullScreen
          ></iframe>
        </div>
      </CustomModal>
    </BackgroundNew>
  );
}

export default Users;
