import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import '../../../i18n';

import Slide from '@mui/material/Slide';

import { Container } from './styles';

import { abbreviateText } from '../../../utils/textUtils';

import { BisoTooltip } from '../../../components/Tooltip';

import { FiArrowRight } from "react-icons/fi";
import { ReactComponent as LampIcon } from '../../../assets/lamp.svg';

export const InsightCard = ({ insight, index, onClickSeeMore }) => {
    const { t } = useTranslation();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const hasUsersPositiveFeedback = () => {
        if (!insight.company_users_feedbacks || insight.company_users_feedbacks.length === 0) {
            return false;
        }

        return insight.company_users_feedbacks.some(feedback => feedback.like);
    }

    const hasManyUsersPositiveFeedback = () => {
        if (!insight.company_users_feedbacks || insight.company_users_feedbacks.length === 0) {
            return false;
        }

        return insight.company_users_feedbacks.filter(feedback => feedback.like).length > 1;
    }

    const getUsersNamePositiveFeedback = () => {
        const positive_feedbacks = insight.company_users_feedbacks.filter(feedback => feedback.like);

        const users_name = positive_feedbacks.map(feedback => feedback.profile_name);

        if (users_name.length > 4) {
            return `${users_name[4].join(', ')} e mais ${users_name.length - 4} pessoas`;
        }

        return users_name.join(', ');
    }

    const defineAbbreviateTextMaxLenghtByWindowWidth = () => {
        if (windowWidth > 1200) {
            return 100;
        }

        if (windowWidth > 768) {
            return 80;
        }

        return 60;
    }

    useEffect(() => {
        function handleResize() {
          setWindowWidth(window.innerWidth)
        }
    
        window.addEventListener('resize', handleResize)
    }, []);

    return (
        <Slide direction="up" in mountOnEnter unmountOnExit>
            <Container key={index}>
                <div className="insightItem">
                    <div className="insightInfo">
                        <div className="description">
                            <span className='tagIdentifier'/>
                            <div className='descriptionContent'>
                                <p className="title">
                                    {insight.title} 
                                    {hasUsersPositiveFeedback() && (
                                        <BisoTooltip 
                                            title={`${getUsersNamePositiveFeedback()} ${hasManyUsersPositiveFeedback() ? t('bisoInsights.like_this_insight') : t('bisoInsights.liked_this_insight')} `}
                                            placement={'right-start'}
                                            startOpen={index === 0 ? true : false}
                                            delayForStartOpen={index === 0 ? 1000 : 0}
                                        >
                                            <LampIcon/>
                                        </BisoTooltip>
                                    )}
                                    {!hasUsersPositiveFeedback() && <LampIcon className='titleIcon'/>}
                                </p>
                                <p>
                                    {abbreviateText(insight.analysis.what_analysis, defineAbbreviateTextMaxLenghtByWindowWidth())}
                                </p>
                            </div>
                        </div>
                        <div className='seeMoreGroup'>
                            <button className="seeMore" onClick={_ => onClickSeeMore(insight)}>{t('bisoInsights.view_report')} <FiArrowRight className='seeMoreIcon' size={20}/></button>
                        </div>
                    </div>
                </div>
            </Container>
        </Slide>
    );
}