import React, { useEffect, useCallback, useState, useRef, useContext } from 'react';

import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import '../../i18n';

import CardGroup from '../../components/CardGroup';
import BackgroundNew from '../../components/BackgroundNew';
import FilterContainer from '../../components/FilterContainer';
import ComposedGraphBarLine from '../../components/ComposedGraphBarLine';
import CustomModalLogisticCallToAction from '../../components/CustomModal/CustomModalLogisticCallToAction';

import { Container } from './styles';

import { DIMENSIONS_OPTONS} from '../../options/filterOptions';
import { getSelectedInitialDateRange, formatDate, getAgregatedKeyByDimension } from '../../utils/dateUtils';
import { formatValueToNumber, formatValueToPercentage, formatValueToMoney } from '../../utils/metricsUtils';
import { getTranslatedShippingTypeOptionsList, reverseTranslatedShippingTypeOptionsList } from '../../utils/logisticPagesUtils';

import { getLogisticShippingTypeEvolutionMetric, getLogisticAverageShippingCostByShippingTypePerOrder} from '../../services/dashboardApiService';

import { AuthContext } from '../../contexts';

const LogisticShippingTypeEvolution = () => {
    const { t } = useTranslation();
    const { timezone, currency } = useContext(AuthContext);

    const [dateRange, setDateRange] = useState();
    const [selectedDimension, setSelectedDimension] = useState(DIMENSIONS_OPTONS.day);

    const [shippingMethodsOptions, setShippingMethodsOptions] = useState([]);
    const [citiesOptions, setCitiesOptions] = useState ([]);
    const [statesOptions, setStatesOptions] = useState ([])
    const [originsOptions, setOriginsOptions] = useState([]);
    const [salesChannelsOptions, setSalesChannelsOptions] = useState([]);
    const [shippingTypesOptions, setShippingTypesOptions] = useState([]);
    const [selectedShippingTypesOptions, setSelectedShippingTypesOptions] = useState([]);
    const [selectedShippingMethodsOptions, setSelectedShippingMethodsOptions] = useState([]);
    const [selectedStatesOptions, setSelectedStatesOptions] = useState([]);
    const [selectedCitiesOptions, setSelectedCitiesOptions] = useState([]);
    const [selectedOriginsOptions, setSelectedOriginsOptions] = useState([]);
    const [selectedSalesChannelsOptions, setSelectedSalesChannelsOptions] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [shippingTypeEvolutionMetricData, setShippingTypeEvolutionMetricData] = useState(null);
    const [averageShippingTypeData, setAverageShippingTypeData] = useState(null);

    const [shippingTypeShareGraphFullScreen, setShippingTypeShareGraphFullScreen] = useState(false);
    const [shippingTypeOrderQuantityGraphFullScreen, setShippingTypeOrderQuantityGraphFullScreen] = useState(false);
    const [openCustomModalLogisticCallToAction, setOpenCustomModalLogisticCallToAction] = useState(false);
    const [shippingTypeAverageShippingCostGraphFullScreen, setShippingTypeAverageShippingCostGraphFullScreen] = useState(false);

    const [graphDataLabelEnabled, setGraphDataLabelEnabled] = useState(false);

    const cardShippingTypeShareRef = useRef(null);
    const cardShippingTypeOrderQuantityRef = useRef(null);
    const cardShippingTypeAverageShippingCostRef = useRef(null);


    const handleApplyFilter = async () => {
        const [startDate, endDate] = dateRange;
        const shippingMethods = selectedShippingMethodsOptions.length > 0 ? [...selectedShippingMethodsOptions] : null;
        const shippingTypes = selectedShippingTypesOptions.length > 0 ? reverseTranslatedShippingTypeOptionsList([...selectedShippingTypesOptions]) : null;
        const states = selectedStatesOptions.length > 0 ? [...selectedStatesOptions] : null;
        const cities = selectedCitiesOptions.length > 0 ? [...selectedCitiesOptions] : null;
        const origins = selectedOriginsOptions.length > 0 ? [...selectedOriginsOptions] : null;
        const salesChannels = selectedSalesChannelsOptions.length > 0 ? [...selectedSalesChannelsOptions] : null;

        await getLogisticShippingTypeEvolutionMetricsData(
            formatDate(startDate),
            formatDate(endDate),
            shippingMethods,
            states,
            cities,
            origins,
            salesChannels,
            shippingTypes
        );
    };

    const getLogisticShippingTypeEvolutionMetricsData = useCallback(async (
        initialDate,
        finalDate,
        shippingMethods,
        states,
        cities,
        origins,
        salesChannels,
        shippingTypes
    ) => {
        setIsLoading(true);

        try {
            const responses = await Promise.all([
                getLogisticShippingTypeEvolutionMetric({
                    initialDate,
                    finalDate,
                    timezone,
                    shippingMethods,
                    cities,
                    states,
                    origins,
                    salesChannels,
                    shippingTypes
                }),
                getLogisticAverageShippingCostByShippingTypePerOrder({
                    initialDate,
                    finalDate,
                    timezone,
                    shippingMethods,
                    cities,
                    states,
                    origins,
                    salesChannels,
                    shippingTypes
                })

            ]);

           const shippingTypeEvolutionMetricResponse = responses[0]
           const averageShippingTypeResponse = responses[1]
            if (!shippingTypeEvolutionMetricResponse || !averageShippingTypeResponse) {
                setShippingTypeEvolutionMetricData(null);
                setAverageShippingTypeData(null);
                setShippingMethodsOptions([]);
                setShippingTypesOptions([]);
                setCitiesOptions([]);
                setStatesOptions([]);
                setOriginsOptions([]);
                setSalesChannelsOptions([]);

                return;
            }

            setShippingTypeEvolutionMetricData(shippingTypeEvolutionMetricResponse.results)
            setAverageShippingTypeData(averageShippingTypeResponse.results)
            setShippingMethodsOptions(shippingTypeEvolutionMetricResponse.available_shipping_methods_to_filter)
            setShippingTypesOptions(getTranslatedShippingTypeOptionsList(shippingTypeEvolutionMetricResponse.available_shipping_types_to_filter))
            setCitiesOptions(shippingTypeEvolutionMetricResponse.available_cities_to_filter)
            setStatesOptions(shippingTypeEvolutionMetricResponse.available_states_to_filter)
            setOriginsOptions(shippingTypeEvolutionMetricResponse.available_origins_to_filter)
            setSalesChannelsOptions(shippingTypeEvolutionMetricResponse.available_marketplace_channels_to_filter)

        } catch (error) {
            toast.error(error.message);
            setShippingTypeEvolutionMetricData(null);
            setAverageShippingTypeData(null);
            setShippingMethodsOptions([]);
            setShippingTypesOptions([]);
            setCitiesOptions([]);
            setStatesOptions([]);
            setOriginsOptions([]);
            setSalesChannelsOptions([]);
        } finally {
          setIsLoading(false);
        }

    }, [timezone]);

    const generateShippingTypesKey = (shippingType, complementKey) => {
        return `${shippingType}_${complementKey}`;
    }

    const formatDataByDateAndShippingTypes = (data, dimension) => {
        if(!data) {return null}

        const aggregatedData = {};

        const shippingTypes = new Set();

        let totalOrderQuantityByKey = {};

        data.forEach(entry => {
            const key = getAgregatedKeyByDimension(entry.date, dimension);

            const shippingTypeShareKey = generateShippingTypesKey(entry.shipping_type, "share");
            const shippingTypeOrdersQuantityKey = generateShippingTypesKey(entry.shipping_type, "ordersQuantity");

            shippingTypes.add(entry.shipping_type);

            if(!aggregatedData[key]) {
                aggregatedData[key] = {
                    date: key,
                };
            }

            if (!aggregatedData[key][shippingTypeOrdersQuantityKey]) {
                aggregatedData[key][shippingTypeShareKey] = 0;
                aggregatedData[key][shippingTypeOrdersQuantityKey] = 0;
            }

            aggregatedData[key][shippingTypeOrdersQuantityKey] += entry.orders_quantity;
            aggregatedData[key][shippingTypeShareKey] = entry.share;

            if (!totalOrderQuantityByKey[key]) {
                totalOrderQuantityByKey[key] = 0;
            }

            totalOrderQuantityByKey[key] += entry.orders_quantity;
        });

        Object.keys(aggregatedData).forEach(key => {
            let shippingTypeSharePercentagePreviousTotal = 0.0;
            let shippingTypeShareLastKey = null;

            shippingTypes.forEach(shippingType => {
                const shippingTypeShareKey = generateShippingTypesKey(shippingType, "share");
                const shippingTypeOrdersQuantityKey = generateShippingTypesKey(shippingType, "ordersQuantity");

                if (!aggregatedData[key][shippingTypeOrdersQuantityKey]) { return }

                aggregatedData[key][shippingTypeShareKey] = totalOrderQuantityByKey[key] > 0 ? Number(((aggregatedData[key][shippingTypeOrdersQuantityKey] / totalOrderQuantityByKey[key]) * 100).toFixed(2)) : 0;

                shippingTypeSharePercentagePreviousTotal += aggregatedData[key][shippingTypeShareKey];
                shippingTypeShareLastKey = shippingTypeShareKey;
            });

            // Adjusting the last marketplace approved revenue percentage to be 100%
            if (shippingTypeSharePercentagePreviousTotal !== 100) {
                const diff = 100 - (shippingTypeSharePercentagePreviousTotal);
                aggregatedData[key][shippingTypeShareLastKey] += diff;
                aggregatedData[key][shippingTypeShareLastKey] = Number((aggregatedData[key][shippingTypeShareLastKey]).toFixed(2));
            }
        })

        return Object.values(aggregatedData);
    };

    const formatAverageShippingCostDataByDateAndShippingTypes = (data, dimension) => {
        if(!data) {return null}

        const aggregatedData = {};

        const shippingTypes = new Set();

        data.forEach(entry => {
            const key = getAgregatedKeyByDimension(entry.date, dimension);

            const shippingTypeAverageShippingCostKey = generateShippingTypesKey(entry.shipping_type, "averageShippingCost");

            shippingTypes.add(entry.shipping_type);

            if(!aggregatedData[key]) {
                aggregatedData[key] = {
                    date: key,
                };
            }

            if (!aggregatedData[key][shippingTypeAverageShippingCostKey]) {
                aggregatedData[key][shippingTypeAverageShippingCostKey] = [];
            }

            aggregatedData[key][shippingTypeAverageShippingCostKey].push(entry.average_shipping_cost);

        });

        Object.keys(aggregatedData).forEach(key => {
            shippingTypes.forEach(shippingType =>{
                const shippingTypeAverageShippingCostKey = generateShippingTypesKey(shippingType, "averageShippingCost")

                if(!aggregatedData[key][shippingTypeAverageShippingCostKey]){
                    return
                }

                const averageShippingCostTotal = aggregatedData[key][shippingTypeAverageShippingCostKey].reduce((total,currentValue) => total + currentValue, 0.0)
                aggregatedData[key][shippingTypeAverageShippingCostKey] = Number((averageShippingCostTotal / aggregatedData[key][shippingTypeAverageShippingCostKey].length).toFixed(2))
            });
        })

        return Object.values(aggregatedData);
    };

    const getSecondaryFilters = () => {
        return [
            {
                title: t('logisticShippingMethodEvolution.shipping_method'),
                options: shippingMethodsOptions,
                value: selectedShippingMethodsOptions,
                setValue: setSelectedShippingMethodsOptions
            },
            {
                title: t('logisticShippingMethodEvolution.shipping_type'),
                options: shippingTypesOptions,
                value: selectedShippingTypesOptions,
                setValue: setSelectedShippingTypesOptions
            },
            {
                title: t('logisticShippingMethodEvolution.cities'),
                options: citiesOptions,
                value: selectedCitiesOptions,
                setValue: setSelectedCitiesOptions
            },
            {
                title: t('common.states'),
                options: statesOptions,
                value: selectedStatesOptions,
                setValue: setSelectedStatesOptions
            },
            {
                title: `${t('common.source')}`,
                options: originsOptions,
                value: selectedOriginsOptions,
                setValue: setSelectedOriginsOptions
            },
            {
                title: t('common.sales_channel'),
                options: salesChannelsOptions,
                value: selectedSalesChannelsOptions,
                setValue: setSelectedSalesChannelsOptions
            }
        ]
      }

      const onRenderComponent = useCallback(async () => {
        const { initialDateFilter, finalDateFilter } = getSelectedInitialDateRange();
        setDateRange([initialDateFilter, finalDateFilter]);

        await getLogisticShippingTypeEvolutionMetricsData(
          formatDate(initialDateFilter),
          formatDate(finalDateFilter)
        );
      }, [getLogisticShippingTypeEvolutionMetricsData]);

      useEffect(() => {
        onRenderComponent();
      }, [onRenderComponent]);

    return (
        <BackgroundNew
          titlePage={t('menu.types_of_freight')}
        >
            <FilterContainer
                selectedDateRange={dateRange}
                setDateRange={setDateRange}
                onClickFilter={handleApplyFilter}
                showSecondaryFilters={true}
                secondaryFilters={getSecondaryFilters()}
            />
            <Container>
                <CardGroup
                    title={t('logisticShippingTypeEvolution.share_of_approved_orders_by_shipping_type')}
                    tagIdentifierColor="#00CCAE"
                    isLoading={isLoading}
                    customClassName="cardGroupContainerFirst"
                    ref={cardShippingTypeShareRef}
                >
                    <ComposedGraphBarLine
                        data={shippingTypeEvolutionMetricData}
                        dimension={selectedDimension}
                        showPrimaryBarYAxis={true}
                        showSecondaryBarYAxis={true}
                        secondaryBarIsStacked={true}
                        primaryBarYAxisConfig={{
                            name: t('logisticShippingTypeEvolution.paid_shipping'),
                            dataKey: "Frete pago_share"
                        }}
                        secondaryBarYAxisConfig={{
                            name: t('logisticShippingTypeEvolution.free_shipping'),
                            dataKey: "Frete grátis_share"
                        }}
                        formatData={formatDataByDateAndShippingTypes}
                        formaterBarValue={formatValueToPercentage}
                        graphMarginConfig={{
                            top: 20,
                            right: -30,
                            left: 5,
                            bottom: 20
                        }}
                        showDimensionController={true}
                        setSelectedDimension={setSelectedDimension}
                        disabledDimensionController={shippingTypeEvolutionMetricData === null || shippingTypeEvolutionMetricData.length === 0}
                        showFullScreenOption={true}
                        fullScreenEnabled={shippingTypeShareGraphFullScreen}
                        setFullScreenEnabled={setShippingTypeShareGraphFullScreen}
                        domainBarYAxis={[0, 100]}
                        showExportPDFButton={true}
                        graphComponentRefForPDF={cardShippingTypeShareRef}
                        showDataLabel={true}
                        dataLabelEnabled={graphDataLabelEnabled}
                        setDataLabelEnabled={setGraphDataLabelEnabled}
                        barDataLabelEnabled={true}
                        barDataValueIsPercentage={true}
                    />
                </CardGroup>
                <CardGroup
                    title={t('logisticShippingTypeEvolution.number_of_approved_orders_by_shipping_type')}
                    tagIdentifierColor="#00CCAE"
                    isLoading={isLoading}
                    customClassName="cardGroupContainerFirst"
                    ref={cardShippingTypeOrderQuantityRef}
                >
                    <ComposedGraphBarLine
                        data={shippingTypeEvolutionMetricData}
                        dimension={selectedDimension}
                        showPrimaryBarYAxis={true}
                        showSecondaryBarYAxis={true}
                        secondaryBarIsStacked={true}
                        primaryBarYAxisConfig={{
                            name: t('logisticShippingTypeEvolution.paid_shipping'),
                            dataKey: "Frete pago_ordersQuantity"
                        }}
                        secondaryBarYAxisConfig={{
                            name: t('logisticShippingTypeEvolution.free_shipping'),
                            dataKey: "Frete grátis_ordersQuantity"
                        }}
                        formatData={formatDataByDateAndShippingTypes}
                        formaterBarValue={formatValueToNumber}
                        graphMarginConfig={{
                            top: 20,
                            right: -30,
                            left:0,
                            bottom: 20
                        }}
                        showDimensionController={true}
                        setSelectedDimension={setSelectedDimension}
                        disabledDimensionController={shippingTypeEvolutionMetricData === null || shippingTypeEvolutionMetricData.length === 0}
                        showFullScreenOption={true}
                        fullScreenEnabled={shippingTypeOrderQuantityGraphFullScreen}
                        setFullScreenEnabled={setShippingTypeOrderQuantityGraphFullScreen}
                        showExportPDFButton={true}
                        graphComponentRefForPDF={cardShippingTypeOrderQuantityRef}
                        showDataLabel={true}
                        dataLabelEnabled={graphDataLabelEnabled}
                        setDataLabelEnabled={setGraphDataLabelEnabled}
                        barDataLabelEnabled={true}
                    />
                </CardGroup>
            </Container>
            <Container>
                <CardGroup
                    title={t('logisticShippingTypeEvolution.average_shipping_cost_by_shipping_type_per_order')}
                    tagIdentifierColor="#00CCAE"
                    isLoading={isLoading}
                    customClassName="cardGroupContainerFirst"
                    ref={cardShippingTypeAverageShippingCostRef}
                >
                    <ComposedGraphBarLine
                        data={averageShippingTypeData}
                        dimension={selectedDimension}
                        showPrimaryLineYAxis={true}
                        showSecondaryLineYAxis={true}
                        primaryLineYAxisConfig={{
                            name: t('logisticShippingTypeEvolution.paid_shipping'),
                            dataKey: "Frete pago_averageShippingCost"
                        }}
                        secondaryLineYAxisConfig={{
                            name: t('logisticShippingTypeEvolution.free_shipping'),
                            dataKey: "Frete grátis_averageShippingCost"
                        }}
                        formatData={formatAverageShippingCostDataByDateAndShippingTypes}
                        formaterLineValue={value => formatValueToMoney(value, currency)}
                        graphMarginConfig={{
                            top: 20,
                            right: 20,
                            left: 0,
                            bottom: 20
                        }}
                        showDimensionController={true}
                        setSelectedDimension={setSelectedDimension}
                        disabledDimensionController={averageShippingTypeData === null || averageShippingTypeData.length === 0}
                        showFullScreenOption={true}
                        fullScreenEnabled={shippingTypeAverageShippingCostGraphFullScreen}
                        setFullScreenEnabled={setShippingTypeAverageShippingCostGraphFullScreen}
                        showExportPDFButton={true}
                        graphComponentRefForPDF={cardShippingTypeAverageShippingCostRef}
                        showDataLabel={true}
                        dataLabelEnabled={graphDataLabelEnabled}
                        setDataLabelEnabled={setGraphDataLabelEnabled}
                        lineDataLabelEnabled={true}
                        lineDataValueIsCurrency={true}
                    />
                </CardGroup>
            </Container>
            <CustomModalLogisticCallToAction
                open={openCustomModalLogisticCallToAction}
                handleClose={_ => setOpenCustomModalLogisticCallToAction(false)}
            />
        </BackgroundNew>
    );
}

export default LogisticShippingTypeEvolution
