import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Tooltip } from '@mui/material';
import './styles.css';

export const BisoTooltip = ({ 
  title, 
  placement, 
  children, 
  startOpen = false, 
  disabled = false, 
  manualOpen = false,
  delayForStartOpen = 0
}) => {
  const [open, setOpen] = useState(false);
  const [hasShown, setHasShown] = useState(false);
  const tooltipRef = useRef(null);

  const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleShow = useCallback(async () => {
    setOpen(true);
    await wait(4000);
    setOpen(false);
  }, []);

  useEffect(() => {
    if (!startOpen || hasShown) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          (async () => {
            await wait(delayForStartOpen);
            handleShow();
          })();
          setHasShown(true);
          observer.disconnect();
        }
      },
      { threshold: 0.2 } // 20% of the element is visible
    );

    const currentRef = tooltipRef.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [startOpen, hasShown, handleShow, delayForStartOpen]);

  useEffect(() => {
    if (manualOpen) {
      handleShow();
    }

    if (disabled) {
      setOpen(false);
    }
  }, [handleShow, manualOpen, disabled]);

  return (
      <Tooltip 
        title={title} 
        placement={placement} 
        open={open}
        onMouseEnter={() => setOpen(true)}
        onClose={() => setOpen(false)}
        arrow
        ref={tooltipRef}
      >
        {children}
      </Tooltip>
  );
};