import styledComponents from 'styled-components';
import { 
  Switch,
  styled
} from '@mui/material';


export const Container = styledComponents.div`

  .workSpace {

    .option {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 18px 15px 18px 15px;
      justify-content: space-between;
      min-height: 76px;
      box-shadow: 0 0 20px rgba(215, 222, 227, 0.39);
      border-radius: 8px;
      background-color: #ffffff;

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      .notification {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: calc(100% - 103px);
        overflow: hidden;
                
        .icon {
          width: 33px;
          height: 33px;
          flex-shrink: 0;
          border-radius: 50%;
          background-color: #ff0068;
          box-shadow: 0 0 10px rgba(207, 217, 223, 0.5);
          color: #ffffff;
          font-size: 16px;
          font-weight: 700;
          text-transform: uppercase;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 25px;
          margin-right: 1px;
        }
        
        .functions {
          .toggle-wrapper {
            position: relative;
            width: 36px;    
            height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

        }

        .descriptionOption {
          margin-left: 27px;
          width: calc(100% - 60px);

          .titleOption {            
            white-space: normal; /* Permitir múltiplas linhas */
            overflow-wrap: break-word; /* Quebra palavras longas */
            overflow: visible; /* Mostra o texto em várias linhas */
            color: #242e42;            
            font-size: 16px;
            font-weight: 700;  
          }

          p {
            color: #90a4ae;
            font-size: 12.5px;
            white-space: normal;
            word-wrap: break-word;
            overflow: visible;
          }
        }
        
      }
    }
  }
`;


export const OnOff = styled(Switch)(({ theme }) => ({
  width: 36,
  height: 18,
  padding: 0,
  display: 'flex',
  overflow: 'initial',

  '& .MuiSwitch-switchBase': {
    padding: 0,
    color: '#D3D3D3',
    '&.Mui-checked': {
      transform: 'translateX(18px)',
      color: '#ff0068',
      boxShadow: '0 0 10px #ff0068',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#fff',
        borderColor: '#D3D3D3',
      },
    },
  },

  '& .MuiSwitch-thumb': {
    width: 18,
    height: 18,
    boxShadow: 'none',
  },

  '& .MuiSwitch-track': {
    border: `1px solid #D3D3D3`,
    borderRadius: 25,
    opacity: 1,
    backgroundColor: '#fff',
  },
}));

