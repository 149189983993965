import React, { useState, useEffect } from "react";
import {
    Table, TableContainer, TableHead, TableRow,
    TableCell, TableBody, Paper, TablePagination,
    styled, TableSortLabel
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import '../../i18n';

import { sortTableData }from '../../utils/sortTableDataUtils.js';
import GraphController from '../GraphController';
import { Container } from './styles';

const CustomTableApiPagination = ({
    data,
    disabledDimensionController,
    showFullScreenOption = false,
    fullScreenEnabled = false,
    setFullScreenEnabled,
    currentPage,
    setCurrentPage,
    rowsPerPage,
    setRowsPerPage,
    totalItems,
    rowsPerPageOptions,
    showPagination = true,
    showTableFooter = false,
    tableFooterDescription,
    showExportCSVReportButton,
    handleExportCSVReport,
    loadingExportCSVReportButton,
    sortable = true
}) => {
    const { t } = useTranslation();

    const [headers, setHeaders] = useState([]);
    const [rows, setRows] = useState([]);
    const [orderDirection, setOrderDirection] = useState('asc');
    const [orderBy, setOrderBy] = useState(null);


    useEffect(() => {
        if (data) {
            setRows(data.rows || []);
            setHeaders(data.headers || []);
        }
    }, [data]);
    
    const handleSort = (column) => {
        const isAsc = orderBy === column && orderDirection === 'asc';
        setOrderDirection(isAsc ? 'desc' : 'asc');
        setOrderBy(column);
        
        if (showPagination && typeof setCurrentPage === 'function') {
            setCurrentPage(1);
        }
    };
    
    const getPagedRows = (data) => {
        const sortedData = sortTableData(data, orderBy, orderDirection);
    
        if (showPagination) {
            const startIndex = (currentPage - 1) * rowsPerPage;
            const endIndex = startIndex + rowsPerPage;
            return sortedData.slice(startIndex, endIndex);
        } else {
            return sortedData;
        }
    };
    
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: '#F4F4F4',
        },
    }));
    
    const pagedRows = getPagedRows(rows);
    
    const handleRowsPerPageChange = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
    };
    
    const stylesComponents = {
        mainContainerFullScreen: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100vh',
            backgroundColor: 'white',
            zIndex: 1200
        },
        tableGroupFullScreen: {
            padding: '20px'
        },
        tableContainerFullScreen: {
            maxHeight: 'calc(100vh - 200px)',
        },
        tableFooter: {
            width: '100%',
            height: '30px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '20px'
        }
    };

    return (
        <Container style={fullScreenEnabled ? stylesComponents.mainContainerFullScreen : {}}>
            <div className="tableGroup" style={fullScreenEnabled ? stylesComponents.tableGroupFullScreen : {}}>
                <GraphController
                    disabled={disabledDimensionController}
                    showDimensionController={false}
                    showFullScreenOption={showFullScreenOption}
                    fullScreenEnabled={fullScreenEnabled}
                    setFullScreenEnabled={setFullScreenEnabled}
                    style={{ marginRight: '0px' }}
                    handleExportCSVReport={handleExportCSVReport}
                    showExportCSVReportButton={showExportCSVReportButton}
                    loadingExportCSVReportButton={loadingExportCSVReportButton}
                />
                <TableContainer
                    component={Paper}
                    className="tableContainer"
                    style={fullScreenEnabled ? stylesComponents.tableContainerFullScreen : {}}
                >
                    <Table className="table">
                        <TableHead className="stickyHeader">
                            <TableRow>
                                {headers.map((header, index) => (
                                    <TableCell
                                        key={index}
                                        className={header.stickyCell ? "stickyCell" : ""}
                                        style={{ minWidth: header.minWidth, fontWeight: 'bold' }}
                                    >
                                        {header.sortable !== false && sortable ? (
                                            <TableSortLabel
                                                active={orderBy === header.value}
                                                direction={orderBy === header.value ? orderDirection : 'asc'}
                                                onClick={() => handleSort(header.value)}
                                            >
                                                {header.name}
                                            </TableSortLabel>
                                        ) : (
                                            header.name
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody className="tableBody">
                            {pagedRows.length > 0 && pagedRows.map((row, index) => (
                                <StyledTableRow role="checkbox" tabIndex={-1} key={index}>
                                    {headers.map((header, headerIndex) => (
                                        <TableCell
                                            key={headerIndex}
                                            className={`${headerIndex === 0 ? 'firstTableCell' : ''}${header.stickyCell ? " stickyCell" : ""}`}
                                            style={{
                                                minWidth: header.minWidth,
                                                backgroundColor: header.stickyCell ? (index % 2 === 0 ? '#F4F4F4' : '#fff') : 'inherit',
                                                color: header.defineColor ? header.defineColor(row[header.value]) : null,
                                                fontWeight: header.fontWeight
                                            }}
                                        >
                                            {header.format ? header.format(row[header.value]) : row[header.value]}
                                        </TableCell>
                                    ))}
                                </StyledTableRow>
                            ))}
                            {pagedRows.length === 0 && (
                                <StyledTableRow>
                                    <TableCell colSpan={headers.length} style={{ textAlign: 'center' }}>
                                        {t('common.no_data')}
                                    </TableCell>
                                </StyledTableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {showPagination && (
                    <TablePagination
                        labelRowsPerPage={t('common.lines_per_page')}
                        labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('common.of')} ${count}`}
                        rowsPerPageOptions={rowsPerPageOptions}
                        component="div"
                        count={totalItems}
                        rowsPerPage={rowsPerPage}
                        page={currentPage - 1}
                        onPageChange={(event, newPage) => setCurrentPage(newPage + 1)}
                        onRowsPerPageChange={handleRowsPerPageChange}
                    />
                )}
                {showTableFooter && (
                    <div style={stylesComponents.tableFooter}>
                        <p style={{ fontWeight: "bold", color: "#ff0068", textAlign: "center" }}>{tableFooterDescription}</p>
                    </div>
                )}
            </div>
        </Container>
    );
};

export default CustomTableApiPagination;
