import React from 'react'

import { useTranslation } from 'react-i18next';
import '../../../i18n';

import { CardGroupContainer, Card, SecondaryCard, TagNewFeature } from './styles';

import Loader from "../../../components/Loader";
import { BisoTooltip } from "../../../components/Tooltip";

import GraphPrediction from "./GraphPrediction";

import { ImArrowDown, ImArrowUp } from "react-icons/im";
import { RiInformationLine } from "react-icons/ri";

import {
  formatValueToMoney,
  formatValueToPercentage,
  formatValueToNumber,
} from "../../../utils/metricsUtils";

const CardGroup = ({
  principalCardsData,
  title,
  tagIdentifierColor,
  secondaryCardsData,
  isLoading,
  showActionButton,
  handleClickActionButton,
  actionButtonTitle,
  disableActionButton,
  graphData,
  currency,
  showTagIsNew = false,
  showTagIsNewTooltip = false,
  tagIsNewTooltipMessage,
}) => {
  const { t } = useTranslation();

  const defineCardBackgroundColor = (value) => {
    if (value === 0) {
      return "#fff";
    } else if (value > 0) {
      return "rgba(89, 172, 92, 0.2)";
    } else if (value >= -50) {
      return "rgba(212, 176, 81, 0.2)";
    } else {
      return "rgba(203, 118, 118, 0.2)";
    }
  };

  const defineCardBorder = (value) => {
    if (value === 0) {
      return "1px solid rgba(215, 222, 227, 0.39)";
    } else {
      return "none";
    }
  };

  const renderSecondaryCardsData = (cardData) => {
      const { keys, titles, types, metricInfoConfig, data } = cardData
      
      return keys.map((key, index) => {
          let value = data ? data[key] : 0;

          const formattersByType = {
            currency: value => formatValueToMoney(value, currency),
            decimal: formatValueToNumber,
            percent: formatValueToPercentage
          }

          return (
              <SecondaryCard key={index}>
                  <p className='cardTitle'>
                      {titles[key]}
                      {metricInfoConfig[key]["visible"] && (
                          <BisoTooltip 
                              title={t('common.understand_how_metric_is_calculated')}
                              placement='right-start'
                          >
                          <span className='metricInfoIcon' onClick={metricInfoConfig[key]["onClick"]}><RiInformationLine size={16} color="#f90c68"/></span>
                        </BisoTooltip>
                      )}
                  </p>
                  <p className='totalValue'>
                    {formattersByType[types[key]](value)}
                  </p>
              </SecondaryCard>
          )
      });
  }

  const renderComparativeRow = (value, text) => {
    return (
      <span className="comparativeValue">
        {value >= 0 ? (
          <ImArrowUp className="arrowCardValue" color="#1A1D1F" size={10} />
        ) : (
          <ImArrowDown className="arrowCardValue" color="#1A1D1F" size={10} />
        )}
        {formatValueToPercentage(value)}
        <span className="comparativeText">{text}</span>
      </span>
    );
  };

  const renderPrincipalCardsData = (cardData, graphData) => {
    const keys = [
      "captured_revenue",
      "sessions",
      "conversion_rate",
      "avg_ticket",
      "approved_revenue",
      "approved_orders",
      "approval_rate",
      "approved_average_ticket",
    ];
    const titles = {
      captured_revenue: t('common.captured_revenue'),
      sessions: t('common.sessions'),
      conversion_rate: t('common.conversion_rate'),
      avg_ticket: t('common.average_ticket'),
      approved_revenue: t('common.approved_revenue'),
      approved_orders: t('common.approved_orders'),
      approval_rate: t('common.approval_rate'),
      approved_average_ticket: t('common.approved_average_ticket'),
    };

    const formatters = {
      captured_revenue: value => formatValueToMoney(value, currency),
      sessions: formatValueToNumber,
      conversion_rate: formatValueToPercentage,
      avg_ticket: value => formatValueToMoney(value, currency),
      approval_rate: formatValueToPercentage,
      approved_revenue: value => formatValueToMoney(value, currency),
      approved_orders: formatValueToNumber,
      approved_average_ticket: value => formatValueToMoney(value, currency),
    };

    return keys.map((key, index) => {
      if (!cardData[key]) return null;

      const cardTitle = titles[key];
      const cardTotalValue = cardData[key].total_value;

      const comparativePreviousYearInPercentage = cardData[key].comparative_previous_year_in_percentage;
      const comparativePreviousPeriodInPercentage = cardData[key].comparative_previous_period_in_percentage;
      const comparativeGoalInPercentage = cardData[key].comparative_goal_in_percentage;

      const graphDailyData = graphData ? graphData[key] : null;
      const showGraphPrediction = graphDailyData ? graphDailyData.some((item) => {
       return item.has_prediction || item.real_value > 0;
      }) : false;
      const showComparativeGoal = comparativeGoalInPercentage !== undefined ? true : false;

      return (
        <Card
          key={index}
          style={{
            backgroundColor: defineCardBackgroundColor(showComparativeGoal ? comparativeGoalInPercentage : 0),
            border: defineCardBorder(showComparativeGoal ? comparativeGoalInPercentage : 0),
          }}
        >
          <h3 className="cardTitle">{cardTitle}</h3>
          <div className="cardInfo">
            <div className="cardInfoItem">
              <p className="totalValue">
                {formatters[key](cardTotalValue)}
              </p>
              <div className="comparativeGroup">
                {showComparativeGoal && renderComparativeRow(comparativeGoalInPercentage, t('common.goal'))}
                {renderComparativeRow(comparativePreviousYearInPercentage, t('common.previous_year'))}
                {renderComparativeRow(comparativePreviousPeriodInPercentage, t('common.previous_period'))}
              </div>
            </div>
            {showGraphPrediction && (
              <div className="cardInfoItem" style={{ width: "220px" }}>
                <GraphPrediction graphData={graphDailyData} formatter={formatters[key]} />
              </div>
            )}
          </div>
        </Card>
      );
    });
  };

  return (
    <CardGroupContainer>
      {title && (
        <div className="cardGroupTitle">
          <span
            className="tagIdentifier"
            style={{ backgroundColor: tagIdentifierColor ? tagIdentifierColor : "#00CCAE" }}
          ></span>
          {title}
          {showTagIsNew && 
            <BisoTooltip 
                title={tagIsNewTooltipMessage}
                placement='right-start'
                disabled={!showTagIsNewTooltip}
                startOpen={true}
            >
              <TagNewFeature>{t('common.new')}</TagNewFeature>
            </BisoTooltip>
          }
          {showActionButton && (
            <div className="containerActionButtonButton">
              <button
                className={`actionButton ${disableActionButton ? "disableActionButton" : ""}`}
                onClick={handleClickActionButton}
                disabled={disableActionButton}
              >
                {actionButtonTitle}
              </button>
            </div>
          )}
        </div>
      )}
      <div className="cardGroupContent">
        {isLoading && <Loader />}
        {!isLoading && principalCardsData && renderPrincipalCardsData(principalCardsData, graphData)}
        {!isLoading && !secondaryCardsData && !principalCardsData && (
          <div className="noData">{t('common.no_data_to_display')}</div>
        )}
      </div>
      <div className="cardGroupContent">
        {!isLoading && secondaryCardsData && renderSecondaryCardsData(secondaryCardsData)}
      </div>
    </CardGroupContainer>
  );
};

export default CardGroup;
