import React, { useContext } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import { AuthProvider, AuthContext } from "./contexts";

import Menu from "./components/Menu";

import SignIn from "./pages/SignIn";
import Alerts from "./pages/Alerts";
import Dashboard from "./pages/Dashboard";
import Anomalies from "./pages/Anomalies";
import User from "./pages/User";
import ConnectionAndChannel from "./pages/ConnectionAndChannel";
import MediaPlanner from "./pages/MediaPlanner";
import Notifications from "./pages/Notifications";
import AdTextCreator from "./pages/AdTextCreator";
import SmartReportMinimumStock from "./pages/SmartReportMinimumStock";
import GoalsOverview from "./pages/GoalsOverview";
import GoalsManager from "./pages/GoalsManager";
import GoalsEvolution from "./pages/GoalsEvolution";
import ServiceMarketplace from "./pages/ServiceMarketplace";
import PaidMediaOverview from "./pages/PaidMediaOverview";
import PaidMediaCampaign from "./pages/PaidMediaCampaign";
import MailMarketing from "./pages/MailMarketing";
import AudienceOverview from "./pages/AudienceOverview";
import Geolocation from "./pages/Geolocation";
import Decil from "./pages/Decil";
import Rfv from "./pages/Rfv";
import Cohort from "./pages/Cohort";
import CustomerBehavior from "./pages/CustomerBehavior";
import MarketplaceOverview from "./pages/MarketplaceOverview";
import GoogleOAuthAnalytics from "./pages/GoogleOAuthAnalytics";
import GoogleOAuthAds from "./pages/GoogleOAuthAds";
import FacebookOAuthAds from "./pages/FacebookOAuthAds";
import ProductPerformance from "./pages/ProductPerformance";
import BisoInsights from "./pages/BisoInsights";
import Profile from "./pages/Profile";
import LogisticIntelipostMostCommunDeliveryTime from "./pages/LogisticIntelipostMostCommunDeliveryTime";
import LogisticShippingMethodEvolution from "./pages/LogisticShippingMethodEvolution";
import LogisticAverageShippingCostEvolution from "./pages/LogisticAverageShippingCostEvolution";
import AllChannel from "./pages/AllChannel";
import LogisticIntelipostAddressesWithDeliveryFailures from "./pages/LogisticIntelipostAddressesWithDeliveryFailures";
import LogisticShippingTypeEvolution from "./pages/LogisticShippingTypeEvolution";
import OperationOverview from "./pages/OperationOverview";
import DataSyncInfo from "./pages/DataSyncInfo";
import ProductBehaviorAbc from "./pages/ProductBehaviorCurveAbc";
import Preference from "./pages/Preference";
import PaymentOverview from "./pages/PaymentOverview";
import PromotionPerformance from "./pages/PromotionPerformance";
import NotificationManagement from "./pages/NotificationManagement";

export const routes = {
  signIn: "/",
  oldDashboard: "/old_dashboard",
  notifications: "/notifications",
  serviceMarketplace: "/service_marketplace",
  profile: "/profile",
  bisoInsights: "/biso_insights",
  dataSyncInfo: "/data_sync",
  users: "/settings/users",
  connectionsAndChannels: "/settings/connections_and_channels",
  preferences: "/settings/preferences",
  alerts: "/biso_intelligence/alerts",
  mediaPlanner: "/biso_intelligence/media_planner",
  goalsManager: "/biso_intelligence/goals_manager",
  adTextCreator: "/biso_intelligence/ad_text_creator",
  smartReportMinimumStock: "/biso_intelligence/smart_report_minimum_stock",
  anomalies: "/biso_intelligence/anomalies",
  customerBehavior: "/biso_intelligence/customer_behavior",
  goalsOverview: "/sales_panel/goals_overview",
  goalsEvolution: "/sales_panel/goals_evolution",
  marketplaceOverview: "/sales_panel/marketplace_overview",
  operationOverview: "/sales_panel/operation_overview",
  paidMediaOverview: "/channels/paid_media_overview",
  paidMediaCampaigns: "/channels/paid_media_campaigns",
  allChannel: "/channels/all_channel",
  mailMarketing: "/channels/mail_marketing",
  audienceOverview: "/audience/overview",
  audienceGeolocation: "/audience/geolocation",
  audienceDecil: "/audience/decile",
  audienceRfv: "/audience/rfv",
  audienceCohort: "/audience/cohort",
  productPerformance: "/product_and_logistic/product/performance",
  logisticIntelipostMostCommunDeliveryTime:
    "/product_and_logistic/logistic/intelipost/most_common_delivery_time",
  logisticAddressesWithDeliveryFailures:
    "/product_and_logistic/logistic/intelipost/addresses_with_delivery_failures",
  logisticShippingMethodEvolution: "/product_and_logistic/logistic/shipping_method_evolution",
  logisticAverageShippingCostEvolution:
    "/product_and_logistic/logistic/average_shipping_cost_representation_evolution_metrics",
  logisticShippingTypeEvolution: "/product_and_logistic/logistic/shipping_type_evolution_metrics",
  googleOAuthAnalytics: "/oauth/google/analytics",
  googleOAuthAds: "/oauth/google/ads",
  facebookOAuthAds: "/oauth/facebook/ads",
  productBehaviorCurveAbc: "/product_and_logistic/product_behavior/abc",
  paymentOverview: "/payment/overview",
  promotionPerformance: "/promotion/performance",
  notificationManagement: "/notification_management",
};

export default function AppRoutes() {
  const Private = ({ children }) => {
    const { authenticated } = useContext(AuthContext);

    if (authenticated) {
      return children;
    }

    return;
  };

  return (
    <BrowserRouter>
      <AuthProvider>
        <Private>
          <Menu />
        </Private>
        <Routes>
          <Route path={routes.signIn} exact element={<SignIn />} />
          <Route path={routes.alerts} element={<Alerts />} />
          <Route path={routes.oldDashboard} element={<Dashboard />} />
          <Route path={routes.users} element={<User />} />
          <Route path={routes.connectionsAndChannels} element={<ConnectionAndChannel />} />
          <Route path={routes.preferences} element={<Preference />} />
          <Route path={routes.mediaPlanner} element={<MediaPlanner />} />
          <Route path={routes.goalsManager} element={<GoalsManager />} />
          <Route path={routes.notifications} element={<Notifications />} />
          <Route path={routes.adTextCreator} element={<AdTextCreator />} />
          <Route path={routes.smartReportMinimumStock} element={<SmartReportMinimumStock />} />
          <Route path={routes.goalsOverview} element={<GoalsOverview />} />
          <Route path={routes.goalsEvolution} element={<GoalsEvolution />} />
          <Route path={routes.serviceMarketplace} element={<ServiceMarketplace />} />
          <Route path={routes.paidMediaOverview} element={<PaidMediaOverview />} />
          <Route path={routes.paidMediaCampaigns} element={<PaidMediaCampaign />} />
          <Route path={routes.allChannel} element={<AllChannel />} />
          <Route path={routes.mailMarketing} element={<MailMarketing />} />
          <Route path={routes.audienceOverview} element={<AudienceOverview />} />
          <Route path={routes.audienceGeolocation} element={<Geolocation />} />
          <Route path={routes.audienceDecil} element={<Decil />} />
          <Route path={routes.audienceRfv} element={<Rfv />} />
          <Route path={routes.audienceCohort} element={<Cohort />} />
          <Route path={routes.customerBehavior} element={<CustomerBehavior />} />
          <Route path={routes.marketplaceOverview} element={<MarketplaceOverview />} />
          <Route path={routes.googleOAuthAnalytics} element={<GoogleOAuthAnalytics />} />
          <Route path={routes.googleOAuthAds} element={<GoogleOAuthAds />} />
          <Route path={routes.facebookOAuthAds} element={<FacebookOAuthAds />} />
          <Route path={routes.productPerformance} element={<ProductPerformance />} />
          <Route path={routes.productBehaviorCurveAbc} element={<ProductBehaviorAbc />} />
          <Route path={routes.paymentOverview} element={<PaymentOverview />} />
          <Route path={routes.promotionPerformance} element={<PromotionPerformance />} />
          <Route
            path={routes.logisticIntelipostMostCommunDeliveryTime}
            element={<LogisticIntelipostMostCommunDeliveryTime />}
          />
          <Route
            path={routes.logisticAddressesWithDeliveryFailures}
            element={<LogisticIntelipostAddressesWithDeliveryFailures />}
          />
          <Route
            path={routes.logisticShippingMethodEvolution}
            element={<LogisticShippingMethodEvolution />}
          />
          <Route
            path={routes.logisticAverageShippingCostEvolution}
            element={<LogisticAverageShippingCostEvolution />}
          />
          <Route path={routes.logisticShippingTypeEvolution} element={<LogisticShippingTypeEvolution />} />
          <Route path={routes.bisoInsights} element={<BisoInsights />} />
          <Route path={routes.profile} element={<Profile />} />
          <Route path={routes.operationOverview} element={<OperationOverview />} />
          <Route path={routes.anomalies} element={<Anomalies />} />
          <Route path={routes.dataSyncInfo} element={<DataSyncInfo />} />
          <Route path={routes.notificationManagement} element={<NotificationManagement />} />
          <Route path="*" element={<Navigate to={routes.signIn} />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}
