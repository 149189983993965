import React, { useEffect, useCallback, useState, useRef, useContext } from 'react';

import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import '../../i18n';

import BackgroundNew from '../../components/BackgroundNew';
import FilterContainer from '../../components/FilterContainer';
import CardGroup from '../../components/CardGroup';
import ComposedGraphBarLine from '../../components/ComposedGraphBarLine';
import CardIndicator from '../../components/CardIndicator';
import GraphPie from '../../components/GraphPie';
import CustomTableApiPagination from '../../components/CustomTableApiPagination';

import { Container } from './styles';

import { DIMENSIONS_OPTONS } from '../../options/filterOptions';
import { getSelectedInitialDateRange, formatDate, getAgregatedKeyByDimension } from '../../utils/dateUtils';
import { formatValueToMoney, formatValueToPercentage, formatValueToNumber } from '../../utils/metricsUtils';
import { isValidAvailableFilter } from '../../utils/validationUtils';

import {
  getMarketplaceOverview,
  getMarketplaceComparative,
  sendReportToEmailMarketplaceComparative
} from '../../services/dashboardApiService';

import { AuthContext } from '../../contexts';

const MarketplaceOverview = () => {
    const { t } = useTranslation();
    const { timezone, currency } = useContext(AuthContext);

    const [dateRange, setDateRange] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [marketplaceChannelsOptions, setMarketplaceChannelsOptions] = useState([]);
    const [selectedMarketplaceChannelsOptions, setSelectedMarketplaceChannelsOptions] = useState([]);
    const [affiliatesOptions, setAffiliatesOptions] = useState([]);
    const [selectedAffiliatesOptions, setSelectedAffiliatesOptions] = useState([]);
    const [statesOptions, setStatesOptions] = useState([]);
    const [selectedStatesOptions, setSelectedStatesOptions] = useState([]);
    const [selectedDimension, setSelectedDimension] = useState(DIMENSIONS_OPTONS.day);
    const [marketplaceData, setMarketplaceData] = useState(null);
    const [marketplaceComparative, setMarketplaceComparative] = useState([]);
    const [bigNumbersData, setBigNumbersData] = useState(null);

    const [revenueShareGraphFullScreen, setRevenueShareGraphFullScreen] = useState(false);
    const [revenueAndAvgTicketGraphFullScreen, setRevenueAndAvgTicketGraphFullScreen] = useState(false);
    const [revenueGraphFullScreen, setRevenueGraphFullScreen] = useState(false);
    const [revenueShareByStateGraphFullScreen, setRevenueShareByStateGraphFullScreen] = useState(false);
    const [tableMarketplaceComparativeFullScreen, setTableMarketplaceComparativeFullScreen] = useState(false);

    const [graphDataLabelEnabled, setGraphDataLabelEnabled] = useState(false);

    const [visibleMarketplaceChannels, setVisibleMarketplaceChannels] = useState([]);

    const cardRevenueAndAvgTicketRef = useRef()
    const cardRevenueRef = useRef()
    const cardRevenueShareRef = useRef()
    const cardRevenueShareByStateRef = useRef()

    const [exportReportLoading, setExportReportLoading] = useState(false);

    const handleApplyFilter = async () => {
        const [startDate, endDate] = dateRange;
        const marketplaceChannels = selectedMarketplaceChannelsOptions.length > 0 ? [...selectedMarketplaceChannelsOptions] : null;
        const states = selectedStatesOptions.length > 0 ? [...selectedStatesOptions] : null;
        const affiliates = selectedAffiliatesOptions.length > 0 ? [...selectedAffiliatesOptions] : null;

        await getMarketplaceDataMetrics(
            formatDate(startDate),
            formatDate(endDate),
            states,
            marketplaceChannels,
            affiliates
        );
    };

    const getMarketplaceDataMetrics = useCallback(async (initialDate, finalDate, states, marketplaceChannels, affiliates) => {
        setIsLoading(true);

        try {
            const responses = await Promise.all([
                getMarketplaceOverview(
                    initialDate,
                    finalDate,
                    timezone,
                    states,
                    marketplaceChannels,
                    affiliates
                ),
                getMarketplaceComparative(
                    initialDate,
                    finalDate,
                    timezone,
                    states,
                    marketplaceChannels,
                    affiliates
                )
            ]);

            const marketplaceOverviewResponse = responses[0]
            const marketplaceComparativeResponse = responses[1]

            if (!marketplaceOverviewResponse || !marketplaceComparativeResponse) {
                setMarketplaceData(null);
                setMarketplaceComparative([]);
                setBigNumbersData(null);
                setStatesOptions([]);
                setMarketplaceChannelsOptions([]);
                setAffiliatesOptions([]);
                setVisibleMarketplaceChannels([]);

                return;
            }

            setMarketplaceData(marketplaceOverviewResponse.results)
            setMarketplaceComparative(marketplaceComparativeResponse.results)
            setBigNumbersData(marketplaceOverviewResponse.big_numbers)
            setStatesOptions(marketplaceOverviewResponse.available_states_to_filter)
            setMarketplaceChannelsOptions(marketplaceOverviewResponse.available_marketplace_channel_to_filter)
            setVisibleMarketplaceChannels(getVisibleMarketplaceChannels(marketplaceOverviewResponse.results))

            if (isValidAvailableFilter(marketplaceOverviewResponse.available_affiliates_to_filter)) {
                setAffiliatesOptions(marketplaceOverviewResponse.available_affiliates_to_filter)
            } else {
                setAffiliatesOptions([])
            }
        } catch (error) {
            toast.error(error.message);
            setMarketplaceData(null);
            setMarketplaceComparative([])
            setBigNumbersData(null);
            setStatesOptions([])
            setMarketplaceChannelsOptions([])
            setAffiliatesOptions([])
            setVisibleMarketplaceChannels([])
        } finally {
          setIsLoading(false);
        }
    }, [timezone]);

    const handleExportReport = async() => {
        setExportReportLoading(true);
        try {
            toast.info(t('toast.wait_for_report'))

            const [startDate, endDate] = dateRange;
            const marketplaceChannels = selectedMarketplaceChannelsOptions.length > 0 ? [...selectedMarketplaceChannelsOptions] : null;
            const states = selectedStatesOptions.length > 0 ? [...selectedStatesOptions] : null;
            const affiliates = selectedAffiliatesOptions.length > 0 ? [...selectedAffiliatesOptions] : null;

            await sendReportToEmailMarketplaceComparative(
                formatDate(startDate),
                formatDate(endDate),
                timezone,
                states,
                marketplaceChannels,
                affiliates
            )

            toast.success(t('toast.report_preparation'));
        } catch (error) {
            toast.error(t('toast.report_generation_failed'));
        } finally {
            setExportReportLoading(false);
        }
    }

    const getVisibleMarketplaceChannels = (data) => {
        if (!data) { return []; }

        const visibleMarketplaceChannels = [];

        data.forEach(entry => {
            if (!visibleMarketplaceChannels.includes(entry.marketplace_channel)) {
                visibleMarketplaceChannels.push(entry.marketplace_channel);
            }
        });

        return visibleMarketplaceChannels;
    }

    const generateMarketplaceKey = (marketplace, complementKey) => {
        return `${marketplace}_${complementKey}`;
    }

    const getMarketplaceGraphConfigs = (marketplaces, complementKey) => {
        if (!marketplaces) { return []; }

        const configs = [];

        marketplaces.forEach(marketplace => {
            const marketplaceDataKey = generateMarketplaceKey(marketplace, complementKey);

            configs.push({
                name: marketplace || " ",
                dataKey: marketplaceDataKey
            });
        })

        return configs;
    }

    const formatDataByState = (data) => {
        if(!data) { return null}

        const aggregatedData = {};

        data.forEach(entry => {
            const key = entry.state;
            if (!aggregatedData[key]) {
                aggregatedData[key] = {
                    state: key,
                    approvedRevenue: 0.0
                };
            }

            aggregatedData[key].approvedRevenue += entry.approved_revenue;
        });

        Object.values(aggregatedData).forEach(entry => {
            entry.approvedRevenue = Number((entry.approvedRevenue).toFixed(2));
        });

        return Object.values(aggregatedData).sort((a, b) => b.approvedRevenue - a.approvedRevenue);
    };

    const formatDataByDateAndMarketplace = (data, dimension) => {
        if(!data) { return null}

        const aggregatedData = {};

        const marketplaces = new Set();

        let totalRevenueByKey = {};

        data.forEach(entry => {
            const key = getAgregatedKeyByDimension(entry.date, dimension);

            const marketplaceApprovedRevenueKey = generateMarketplaceKey(entry.marketplace_channel, "approvedRevenue");
            const marketplaceApprovedOrdersKey = generateMarketplaceKey(entry.marketplace_channel, "approvedOrders");
            const marketplaceApprovedAvgTicketKey = generateMarketplaceKey(entry.marketplace_channel, "approvedAvgTicket");
            const marketplaceApprovedRevenuePercentageKey = generateMarketplaceKey(entry.marketplace_channel, "approvedRevenuePercentage");

            marketplaces.add(entry.marketplace_channel);

            if (!aggregatedData[key]) {
                aggregatedData[key] = {
                    date: key
                };
            }

            if (!aggregatedData[key][marketplaceApprovedRevenueKey]) {
                aggregatedData[key][marketplaceApprovedRevenueKey] = 0.0;
                aggregatedData[key][marketplaceApprovedOrdersKey] = 0;
                aggregatedData[key][marketplaceApprovedAvgTicketKey] = 0.0;
                aggregatedData[key][marketplaceApprovedRevenuePercentageKey] = 0.0;
            }

            aggregatedData[key][marketplaceApprovedRevenueKey] += entry.approved_revenue;
            aggregatedData[key][marketplaceApprovedOrdersKey] += entry.approved_orders;

            if (!totalRevenueByKey[key]) {
                totalRevenueByKey[key] = 0.0;
            }

            totalRevenueByKey[key] += entry.approved_revenue;
        });

        Object.keys(aggregatedData).forEach(key => {
            let marketplaceApprovedRevenuePercentagePreviousTotal = 0;
            let marketplaceApprovedRevenuePercentageLastKey = null;

            marketplaces.forEach(marketplace => {
                const marketplaceApprovedRevenueKey = generateMarketplaceKey(marketplace, "approvedRevenue");
                const marketplaceApprovedOrdersKey = generateMarketplaceKey(marketplace, "approvedOrders");
                const marketplaceApprovedAvgTicketKey = generateMarketplaceKey(marketplace, "approvedAvgTicket");
                const marketplaceApprovedRevenuePercentageKey = generateMarketplaceKey(marketplace, "approvedRevenuePercentage");

                if (!aggregatedData[key][marketplaceApprovedRevenueKey]) { return }

                const approved_avg_ticket = aggregatedData[key][marketplaceApprovedOrdersKey] > 0
                        ? aggregatedData[key][marketplaceApprovedRevenueKey] / aggregatedData[key][marketplaceApprovedOrdersKey]
                        : 0.0;

                aggregatedData[key][marketplaceApprovedAvgTicketKey] = Number((approved_avg_ticket).toFixed(2));

                aggregatedData[key][marketplaceApprovedRevenuePercentageKey] = totalRevenueByKey[key] > 0 ? Number(((aggregatedData[key][marketplaceApprovedRevenueKey] / totalRevenueByKey[key]) * 100).toFixed(2)) : 0.0;
                aggregatedData[key][marketplaceApprovedRevenueKey] = Number((aggregatedData[key][marketplaceApprovedRevenueKey]).toFixed(2));

                marketplaceApprovedRevenuePercentagePreviousTotal += aggregatedData[key][marketplaceApprovedRevenuePercentageKey];
                marketplaceApprovedRevenuePercentageLastKey = marketplaceApprovedRevenuePercentageKey;
            });

            // Adjusting the last marketplace approved revenue percentage to be 100%
            if (marketplaceApprovedRevenuePercentagePreviousTotal !== 100) {
                const diff = 100 - (marketplaceApprovedRevenuePercentagePreviousTotal);
                aggregatedData[key][marketplaceApprovedRevenuePercentageLastKey] += diff;
                aggregatedData[key][marketplaceApprovedRevenuePercentageLastKey] = Number((aggregatedData[key][marketplaceApprovedRevenuePercentageLastKey]).toFixed(2));
            }
        })

        return Object.values(aggregatedData);
    };

    const getCardIndicatorData = (data) => {
        return {
          keys: ["approved_revenue", "approved_orders", "approved_avg_ticket", "canceled_orders"],
          titles: {
            approved_revenue: t('common.approved_revenue'),
            approved_orders: t('common.approved_orders'),
            approved_avg_ticket: t('common.approved_average_ticket'),
            canceled_orders: t('common.canceled_orders')
          },
          types: {
            approved_revenue: 'currency',
            approved_orders: 'decimal',
            approved_avg_ticket: 'currency',
            canceled_orders: 'decimal'
          },
          displayFlexSpaceOcupation: {
            approved_revenue: 1,
            approved_orders: 1,
            approved_avg_ticket: 1,
            canceled_orders: 1
          },
          maxHeights: {
            approved_revenue: 200,
            approved_orders: 200,
            approved_avg_ticket: 200,
            canceled_orders: 200
          },
          minWidths: {
            approved_revenue: 225,
            approved_orders: 225,
            approved_avg_ticket: 225,
            canceled_orders: 225
          },
          comparativeValues: {
            approved_revenue: {
                previous_year: data ? data.approved_revenue.comparative_previous_year_in_percentage : 0,
                previous_period: data ? data.approved_revenue.comparative_previous_period_in_percentage : 0
            },
            approved_orders: {
                previous_year: data ? data.approved_orders.comparative_previous_year_in_percentage : 0,
                previous_period: data ? data.approved_orders.comparative_previous_period_in_percentage : 0
            },
            approved_avg_ticket: {
                previous_year: data ? data.approved_avg_ticket.comparative_previous_year_in_percentage : 0,
                previous_period: data ? data.approved_avg_ticket.comparative_previous_period_in_percentage : 0
            },
            canceled_orders: {
                previous_year: data ? data.canceled_orders.comparative_previous_year_in_percentage : 0,
                previous_period: data ? data.canceled_orders.comparative_previous_period_in_percentage : 0
            }
          },
          data: {
            approved_revenue: data ? data.approved_revenue.total_value : 0,
            approved_orders: data ? data.approved_orders.total_value : 0,
            approved_avg_ticket: data ? data.approved_avg_ticket.total_value : 0,
            canceled_orders: data ? data.canceled_orders.total_value : 0
          }
        }
    }

    const formatMarketplaceComparativeData = (data) => {
        if (data.length === 0) { return data; }

        const formattedData = [];

        data.forEach(entry => {
            formattedData.push({
                marketplaceChannel: entry.marketplace_channel,
                approvedRevenue: entry.approved_revenue.total_value,
                approvedRevenueComparativePreviousYear: entry.approved_revenue.comparative_previous_year_in_percentage,
                approvedRevenueComparativePreviousPeriod: entry.approved_revenue.comparative_previous_period_in_percentage,
                approvedOrders: entry.approved_orders.total_value,
                approvedOrdersComparativePreviousYear: entry.approved_orders.comparative_previous_year_in_percentage,
                approvedOrdersComparativePreviousPeriod: entry.approved_orders.comparative_previous_period_in_percentage,
                approvedRevenueShare: entry.approved_revenue_share.total_value,
                approvedRevenueShareComparativePreviousYear: entry.approved_revenue_share.comparative_previous_year_in_percentage,
                approvedRevenueShareComparativePreviousPeriod: entry.approved_revenue_share.comparative_previous_period_in_percentage
            });
        })

        return formattedData;
    }

    const getMarketplaceComparativeTableData = (data) => {
        return {
            headers: [
                {name: "Marketplace", value: "marketplaceChannel", minWidth: 160, stickyCell: true, format: (value) => value},
                {name: t('common.approved_revenue'), value: "approvedRevenue", minWidth: 160, format: value => formatValueToMoney(value, currency)},
                {name: t('marketplaceOverview.previous_year_approved_revenue'), value: "approvedRevenueComparativePreviousYear", minWidth: 160, format: formatValueToPercentage},
                {name: t('marketplaceOverview.previous_period_approved_revenue'), value: "approvedRevenueComparativePreviousPeriod", minWidth: 160, format: formatValueToPercentage},
                {name: t('common.approved_orders'), value: "approvedOrders", minWidth: 160, format: formatValueToNumber},
                {name: t('marketplaceOverview.previous_year_approved_orders'), value: "approvedOrdersComparativePreviousYear", minWidth: 160, format: formatValueToPercentage},
                {name: t('marketplaceOverview.previous_period_approved_orders'), value: "approvedOrdersComparativePreviousPeriod", minWidth: 160, format: formatValueToPercentage},
                {name: t('marketplaceOverview.approved_revenue_share'), value: "approvedRevenueShare", minWidth: 160, format: formatValueToPercentage},
                {name: t('marketplaceOverview.previous_year_approved_revenue_share_percentage'), value: "approvedRevenueShareComparativePreviousYear", minWidth: 160, format: formatValueToPercentage},
                {name: t('marketplaceOverview.previous_period_approved_revenue_share_percentage'), value: "approvedRevenueShareComparativePreviousPeriod", minWidth: 160, format: formatValueToPercentage}
            ],
            rows: formatMarketplaceComparativeData(data)
        }
    }

    const getSecondaryFilters = () => {
        return [
            {
                title: `${t('common.states')}`,
                options: statesOptions,
                value: selectedStatesOptions,
                setValue: setSelectedStatesOptions
            },
            {
                title: `${t('common.marketplaces')}`,
                options: marketplaceChannelsOptions,
                value: selectedMarketplaceChannelsOptions,
                setValue: setSelectedMarketplaceChannelsOptions
            },
            {
                title: `${t('common.affiliates_vtex')}`,
                options: affiliatesOptions,
                value: selectedAffiliatesOptions,
                setValue: setSelectedAffiliatesOptions
            }
        ]
    }

    const onRenderComponent = useCallback(async () => {
        const { initialDateFilter, finalDateFilter } = getSelectedInitialDateRange();
        setDateRange([initialDateFilter, finalDateFilter]);

        await getMarketplaceDataMetrics(
          formatDate(initialDateFilter),
          formatDate(finalDateFilter)
        );
      }, [getMarketplaceDataMetrics]);

      useEffect(() => {
        onRenderComponent();
      }, [onRenderComponent]);

    return (
        <BackgroundNew
          titlePage={t('menu.marketplace_overview')}
        >
            <FilterContainer
                selectedDateRange={dateRange}
                setDateRange={setDateRange}
                showSecondaryFilters={true}
                secondaryFilters={getSecondaryFilters()}
                onClickFilter={handleApplyFilter}
            />
            <CardIndicator
                data={getCardIndicatorData(bigNumbersData)}
                currency={currency}
                isLoading={isLoading}
            />
            <CardGroup
                title={t('marketplaceOverview.approved_orders_vs_approved_average_ticket')}
                tagIdentifierColor="#00CCAE"
                isLoading={isLoading}
                ref={cardRevenueAndAvgTicketRef}
            >
                <ComposedGraphBarLine
                    data={marketplaceData}
                    dimension={selectedDimension}
                    showMultiBarStacked={true}
                    showMultiLine={true}
                    multiBarStackedConfigs={getMarketplaceGraphConfigs(visibleMarketplaceChannels, "approvedOrders")}
                    multiLineConfigs={getMarketplaceGraphConfigs(visibleMarketplaceChannels, "approvedAvgTicket")}
                    formatData={formatDataByDateAndMarketplace}
                    formaterBarValue={formatValueToNumber}
                    formaterLineValue={value => formatValueToMoney(value, currency)}
                    graphMarginConfig={{
                        top: 20,
                        right: 40,
                        left: 10,
                        bottom: 20
                    }}
                    showDimensionController={true}
                    setSelectedDimension={setSelectedDimension}
                    disabledDimensionController={marketplaceData === null || marketplaceData.length === 0}
                    showFullScreenOption={true}
                    fullScreenEnabled={revenueAndAvgTicketGraphFullScreen}
                    setFullScreenEnabled={setRevenueAndAvgTicketGraphFullScreen}
                    showExportPDFButton={true}
                    graphComponentRefForPDF={cardRevenueAndAvgTicketRef}
                    showDataLabel={true}
                    dataLabelEnabled={graphDataLabelEnabled}
                    setDataLabelEnabled={setGraphDataLabelEnabled}
                    barDataLabelEnabled={true}
                />
            </CardGroup>
            <CardGroup
                title={t('common.approved_revenue')}
                tagIdentifierColor="#00CCAE"
                isLoading={isLoading}
                ref={cardRevenueRef}
            >
                <ComposedGraphBarLine
                    data={marketplaceData}
                    dimension={selectedDimension}
                    showMultiBarStacked={true}
                    multiBarStackedConfigs={getMarketplaceGraphConfigs(visibleMarketplaceChannels, "approvedRevenue")}
                    formatData={formatDataByDateAndMarketplace}
                    formaterBarValue={value => formatValueToMoney(value, currency)}
                    graphMarginConfig={{
                        top: 20,
                        right: -35,
                        left:50,
                        bottom: 20
                    }}
                    showDimensionController={true}
                    setSelectedDimension={setSelectedDimension}
                    disabledDimensionController={marketplaceData === null || marketplaceData.length === 0}
                    showFullScreenOption={true}
                    fullScreenEnabled={revenueGraphFullScreen}
                    setFullScreenEnabled={setRevenueGraphFullScreen}
                    showExportPDFButton={true}
                    graphComponentRefForPDF={cardRevenueRef}
                    showDataLabel={true}
                    dataLabelEnabled={graphDataLabelEnabled}
                    setDataLabelEnabled={setGraphDataLabelEnabled}
                    barDataLabelEnabled={true}
                    barDataValueIsCurrency={true}
                />
            </CardGroup>
            <Container>
                <CardGroup
                    title={t('marketplaceOverview.approved_revenue_share')}
                    tagIdentifierColor="#00CCAE"
                    isLoading={isLoading}
                    customClassName="cardGroupContainerFirst"
                    ref={cardRevenueShareRef}
                >
                    <ComposedGraphBarLine
                        data={marketplaceData}
                        dimension={selectedDimension}
                        showMultiBarStacked={true}
                        multiBarStackedConfigs={getMarketplaceGraphConfigs(visibleMarketplaceChannels, "approvedRevenuePercentage")}
                        formatData={formatDataByDateAndMarketplace}
                        formaterBarValue={formatValueToPercentage}
                        graphMarginConfig={{
                            top: 25,
                            right: -20,
                            left: 1,
                            bottom: 20
                        }}
                        showDimensionController={true}
                        setSelectedDimension={setSelectedDimension}
                        disabledDimensionController={marketplaceData === null || marketplaceData.length === 0}
                        showFullScreenOption={true}
                        fullScreenEnabled={revenueShareGraphFullScreen}
                        setFullScreenEnabled={setRevenueShareGraphFullScreen}
                        domainBarYAxis={[0, 100]}
                        showExportPDFButton={true}
                        graphComponentRefForPDF={cardRevenueShareRef}
                        showDataLabel={true}
                        dataLabelEnabled={graphDataLabelEnabled}
                        setDataLabelEnabled={setGraphDataLabelEnabled}
                        barDataLabelEnabled={true}
                        barDataValueIsPercentage={true}
                    />
                </CardGroup>
                <CardGroup
                    title={t('marketplaceOverview.approved_revenue_share_by_state')}
                    tagIdentifierColor="#00CCAE"
                    isLoading={isLoading}
                    customClassName="cardGroupContainerSecond"
                    ref={cardRevenueShareByStateRef}
                >
                    <GraphPie
                        data={marketplaceData}
                        dataKeyLabel="state"
                        dataKeyValue="approvedRevenue"
                        formatData={formatDataByState}
                        formaterValue={value => formatValueToMoney(value, currency)}
                        graphMarginConfig={{
                            top: 20,
                            right: -40,
                            left: -20,
                            bottom: 60
                        }}
                        showDimensionController={true}
                        showFullScreenOption={true}
                        fullScreenEnabled={revenueShareByStateGraphFullScreen}
                        setFullScreenEnabled={setRevenueShareByStateGraphFullScreen}
                        multiColor={true}
                        showExportPDFButton={true}
                        graphComponentRefForPDF={cardRevenueShareByStateRef}
                        disabledDimensionController={marketplaceData === null || marketplaceData.length === 0}
                        showDataLabel={true}
                        dataLabelEnabled={graphDataLabelEnabled}
                        setDataLabelEnabled={setGraphDataLabelEnabled}
                        dataValueIsCurrency={true}
                    />
                </CardGroup>
            </Container>
            <CardGroup
                title={t('marketplaceOverview.comparison_by_marketplace')}
                tagIdentifierColor="#00CCAE"
                isLoading={isLoading}
            >
                <CustomTableApiPagination
                    data={getMarketplaceComparativeTableData(marketplaceComparative)}
                    disabledDimensionController={marketplaceComparative == null || marketplaceComparative.length === 0}
                    showFullScreenOption={true}
                    fullScreenEnabled={tableMarketplaceComparativeFullScreen}
                    setFullScreenEnabled={setTableMarketplaceComparativeFullScreen}
                    showPagination={false}
                    showExportCSVReportButton={true}
                    handleExportCSVReport={handleExportReport}
                    loadingExportCSVReportButton={exportReportLoading}
                />
            </CardGroup>
        </BackgroundNew>
    );
}

export default MarketplaceOverview;
