import React, { useState, useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import '../../../i18n';

import ContainerTwoCardGroup from '../../../components/ContainerTwoCardGroup';
import CardGroup from '../../../components/CardGroup';
import {
  formatValueToMoney,
  formatValueToPercentage,
  formatValueToNumber,
} from '../../../utils/metricsUtils';

import GraphAnomaly from './GraphAnomaly';

const CardGroups = ({ 
  isLoading, 
  currency, 
  graphsData = {}
}) => {
  const { t } = useTranslation();

  const [graphsStructure, setGraphsStructure] = useState([]);
  const [graphDataLabelEnabled, setGraphDataLabelEnabled] = useState(false);

  const capturedRevenueRef = useRef();
  const sessionsRef = useRef();
  const conversionRateRef = useRef();
  const avgTicketRef = useRef();

  useEffect(() => {
    const graphTitles = {
      captured_revenue: t('common.captured_revenue'),
      sessions: t('common.sessions'),
      conversion_rate: t('common.conversion_rate'),
      avg_ticket: t('common.average_ticket'),
    };

    const formatters = {
      captured_revenue: value => formatValueToMoney(value, currency),
      sessions: formatValueToNumber,
      conversion_rate: formatValueToPercentage,
      avg_ticket: value => formatValueToMoney(value, currency),
    };

    const refs = {
      captured_revenue: capturedRevenueRef,
      sessions: sessionsRef,
      conversion_rate: conversionRateRef,
      avg_ticket: avgTicketRef,
    };

    const marginsLeftAxisX = {
      captured_revenue: 60,
      sessions: 5,
      conversion_rate: 8,
      avg_ticket: 35,
    };

    const isCurrency = {
      captured_revenue: true,
      sessions: false,
      conversion_rate: false,
      avg_ticket: true,
    };

    const isPercentage = {
      captured_revenue: false,
      sessions: false,
      conversion_rate: true,
      avg_ticket: false,
    };

    setGraphsStructure(
      Object.keys(graphsData).map((key, index) => {
        return {
          key: key,
          title: graphTitles[key],
          data: graphsData[key],
          formatter: formatters[key],
          marginLeftAxisX: marginsLeftAxisX[key],
          ref: refs[key],
          isCurrency: isCurrency[key],
          isPercentage: isPercentage[key],
        };
      })
    );
  }, [graphsData, t, currency]);

  const rows =
    graphsStructure?.reduce(function (rows, key, index) {
      return (index % 2 === 0 ? rows.push([key]) : rows[rows.length - 1].push(key)) && rows;
    }, []) ?? [];

  return rows.map((row, index) => {
    const element1 = row[0];
    const element2 = row[1];
    return (
      <ContainerTwoCardGroup key={`anomaly-graph-pair-${element1.key}${`-${element2.key}` ?? ''}`}>
        <CardGroup
          title={element1.title}
          isLoading={isLoading}
          customClassName="cardGroupContainerFirst"
          ref={element1.ref}
        >
          {element1.data?.length ? (
            <GraphAnomaly
              graphData={element1.data}
              formatter={element1.formatter}
              marginLeftAxisX={element1.marginLeftAxisX}
              graphRef={element1.ref}
              dataLabelEnabled={graphDataLabelEnabled}
              setDataLabelEnabled={setGraphDataLabelEnabled}
              dataValueIsCurrency={element1.isCurrency}
              dataValueIsPercentage={element1.isPercentage}
            />
          ) : null}
        </CardGroup>
        {element2 ? (
          <CardGroup
            title={element2.title}
            isLoading={isLoading}
            customClassName="cardGroupContainerSecond"
            ref={element2?.ref}
          >
            {element2?.data?.length ? (
              <GraphAnomaly
                graphData={element2?.data}
                formatter={element2?.formatter}
                marginLeftAxisX={element2?.marginLeftAxisX}
                graphRef={element2?.ref}
                dataLabelEnabled={graphDataLabelEnabled}
                setDataLabelEnabled={setGraphDataLabelEnabled}
                dataValueIsCurrency={element2.isCurrency}
                dataValueIsPercentage={element2.isPercentage}
              />
            ) : null}
          </CardGroup>
        ) : null}
      </ContainerTwoCardGroup>
    );
  });
};

export default CardGroups;
