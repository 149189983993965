import { routes } from "../routes";
import { getCurrentUser } from "../services/odinService";
import { getConnectorsLastSyncDate } from "../services/dashboardApiService";

const hasToken = () => {
  const token = localStorage.getItem("token");
  if (token) {
    return true;
  }
  return false;
};

const getUserToken = () => {
  return localStorage.getItem("token");
};

const getUserInfo = () => {
  const user = localStorage.getItem("user");
  return user ? JSON.parse(user) : null;
};

const saveToken = (token) => {
  localStorage.setItem("token", token);
};

async function setUserInfo() {
  if (!hasToken()) {
    return;
  }

  const userInfo = await getCurrentUser();

  if (!userInfo) {
    return;
  }

  localStorage.setItem(
    "user",
    JSON.stringify({
      id: userInfo.id,
      username: userInfo.username || `${userInfo.first_name} ${userInfo.last_name}`,
      role: userInfo.role,
      company_id: userInfo.company?.id ?? null,
      company_name: userInfo.company?.name ?? null,
      user_type: userInfo.user_type ?? null,
      trello: userInfo.settings_trello?.board_id ? true : false,
      dashboard: userInfo.dashboard_url,
      email: userInfo.contact_info.email,
      dashboard_group: userInfo.dashboard_group,
      dashboards: userInfo.dashboards,
      is_company_owner_user: userInfo.role.toLowerCase() === "owner" || userInfo.user_type === "biso",
      first_access_user: userInfo.telemetry?.first_access_user ?? false,
      app_token: userInfo.settings?.app_token ?? "",
      first_name: userInfo.contact_info.first_name,
      last_name: userInfo.contact_info.last_name,
      product_analysis_report: userInfo.settings?.notification_settings.product_analysis_report ?? false,
      cro_analysis_report: userInfo.settings?.notification_settings?.cro_analysis_report ?? false,
      campaign_media_analysis_report: userInfo.settings?.notification_settings.campaign_media_analysis_report ?? false,
      campaigns_analysis_report: userInfo.settings?.notification_settings.campaigns_analysis_report ?? false,
      minimum_stock_analysis_report: userInfo.settings?.notification_settings.minimum_stock_analysis_report ?? false,
      biso_insights: userInfo.settings?.notification_settings.biso_insights ?? false,
      anomaly_detector: userInfo.settings?.notification_settings.anomaly_detector ?? false,

    })
  );
}

const sessionUserIsCompanyOwner = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user.is_company_owner_user;
};

const getCurrentUserInfo = () => {
  const {
    id,
    username,
    role,
    company_name,
    company_id,
    user_type,
    trello,
    dashboard,
    email,
    dashboard_group,
    dashboards,
    is_company_owner_user,
    first_access_user,
    app_token,
    first_name,
    last_name,
    product_analysis_report,
    cro_analysis_report,
    campaign_media_analysis_report,
    campaigns_analysis_report,
    minimum_stock_analysis_report,
    biso_insights,
    anomaly_detector
  } = getUserInfo() ?? {};
  return {
    id,
    username,
    role,
    company_name,
    company_id,
    user_type,
    trello,
    dashboard,
    email,
    dashboard_group,
    dashboards,
    is_company_owner_user,
    first_access_user,
    app_token,
    first_name,
    last_name,
    product_analysis_report,
    cro_analysis_report,
    campaign_media_analysis_report,
    campaigns_analysis_report,
    minimum_stock_analysis_report,
    biso_insights,
    anomaly_detector
  };
};

const handleSignOut = (savePreviousPath = true) => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  localStorage.removeItem("features");

  const notAllowedRoutes = [
    routes.signIn,
    routes.googleOAuthAds,
    routes.googleOAuthAnalytics,
    routes.facebookOAuthAds,
  ];

  let currentPath = window.location.pathname;
  if (currentPath.endsWith("/")) {
    currentPath = currentPath.slice(0, -1);
  }

  if (savePreviousPath && !notAllowedRoutes.includes(currentPath)) {
    localStorage.setItem("previousPathV3", window.location.pathname);
  }

  window.location.href = "/";
};

const getPreviousPath = () => {
  return localStorage.getItem("previousPathV3") || null;
};

const removePreviousPath = () => {
  localStorage.removeItem("previousPathV3");
};

const companyHasIntelipostConnector = async () => {
  try {
    const response = await getConnectorsLastSyncDate("America/Sao_Paulo");
    return response.some((connector) => connector.connector_module_name === "Intelipost (Pedidos)");
  } catch (error) {
    return false;
  }
};

const companyHasVtexConnector = async () => {
  try {
    const response = await getConnectorsLastSyncDate("America/Sao_Paulo");
    return response.some((connector) => connector.connector_module_name === "Vtex (Pedidos)");
  } catch (error) {
    return false;
  }
};

export {
  saveToken,
  setUserInfo,
  handleSignOut,
  hasToken,
  sessionUserIsCompanyOwner,
  getPreviousPath,
  removePreviousPath,
  companyHasIntelipostConnector,
  companyHasVtexConnector,
  getUserInfo,
  getUserToken,
  getCurrentUserInfo,
};
