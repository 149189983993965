import React, { useState, useEffect } from "react";
import {
    Table, TableContainer,
    TableHead, TableRow,
    TableCell, TableBody, Paper, TablePagination,
    styled, TableSortLabel
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import '../../i18n';

import GraphController from '../GraphController';
import { Container } from './styles';
import { sortTableData } from '../../utils/sortTableDataUtils';

const CustomTable = ({
    data,
    disabledDimensionController,
    showFullScreenOption = false,
    fullScreenEnabled = false,
    setFullScreenEnabled,
    cardGroupRef,
    sortable = true
}) => {
    const { t } = useTranslation();

    const ROWS_PER_PAGE_OPTIONS = [10, 25, 50, 100];
    const DEFAULT_ROWS_PER_PAGE = 10;

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
    const [allData, setAllData] = useState(null);
    const [headers, setHeaders] = useState([]);
    const [rows, setRows] = useState([]);
    const [orderBy, setOrderBy] = useState(null);
    const [orderDirection, setOrderDirection] = useState('asc');

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, DEFAULT_ROWS_PER_PAGE));
        setPage(0);
    };

    const handleRequestSort = (column) => {
        const isAsc = orderBy === column && orderDirection === 'asc';
        setOrderDirection(isAsc ? 'desc' : 'asc');
        setOrderBy(column);
        setPage(0);
    };

    useEffect(() => {
        setPage(0);
        setAllData(data);
    }, [data]);

    useEffect(() => {
        if (allData === null) return;

        const sortedData = orderBy
            ? sortTableData(allData.rows, orderBy, orderDirection)
            : allData.rows;

        const paginatedRows = sortedData.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
        );

        setRows(paginatedRows);
        setHeaders(allData.headers);
    }, [allData, page, rowsPerPage, orderBy, orderDirection]);

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: '#F4F4F4',
        },
    }));

    const stylesComponents = {
        mainContainerFullScreen: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100vh',
            backgroundColor: 'white',
            zIndex: 1200
        },
        tableGroupFullScreen: {
            padding: '20px'
        },
        tableContainerFullScreen: {
            maxHeight: 'calc(100vh - 200px)',
        }
    };

    return (
        <Container style={fullScreenEnabled ? stylesComponents.mainContainerFullScreen : {}}>
            <div className="tableGroup" style={fullScreenEnabled ? stylesComponents.tableGroupFullScreen : {}}>
                <GraphController
                    disabled={disabledDimensionController}
                    showDimensionController={false}
                    showFullScreenOption={showFullScreenOption}
                    fullScreenEnabled={fullScreenEnabled}
                    setFullScreenEnabled={setFullScreenEnabled}
                    style={{ marginRight: '0px' }}
                    cardGroupRef={cardGroupRef}
                />
                <TableContainer
                    component={Paper}
                    className="tableContainer"
                    style={fullScreenEnabled ? stylesComponents.tableContainerFullScreen : {}}
                >
                    <Table className="table">
                        <TableHead className="stickyHeader">
                            <TableRow>
                                {headers.length > 0 && headers.map((header, index) => (
                                    <TableCell
                                        key={index}
                                        className={header.stickyCell ? "stickyCell" : ""}
                                        style={{ minWidth: header.minWidth, fontWeight: 'bold' }}
                                    >
                                        {header.sortable !== false && sortable ? (
                                            <TableSortLabel
                                                active={orderBy === header.value}
                                                direction={orderBy === header.value ? orderDirection : 'asc'}
                                                onClick={() => handleRequestSort(header.value)}
                                            >
                                                {header.name}
                                            </TableSortLabel>
                                        ) : (
                                            header.name
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody className="tableBody">
                            {rows.length > 0 && rows.map((row, index) => (
                                <StyledTableRow role="checkbox" tabIndex={-1} key={index}>
                                    {headers.map((header, headerIndex) => (
                                        <TableCell
                                            className={headerIndex === 0 ? 'firstTableCell' : ''}
                                            key={headerIndex}
                                            style={{ minWidth: header.minWidth }}
                                        >
                                            {header.format(row[header.value])}
                                        </TableCell>
                                    ))}
                                </StyledTableRow>
                            ))}
                            {rows.length === 0 && (
                                <StyledTableRow role="checkbox" tabIndex={-1} key={0}>
                                    <TableCell colSpan={headers.length} style={{ textAlign: 'center' }}>
                                        {t('common.no_data')}
                                    </TableCell>
                                </StyledTableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    labelRowsPerPage={t('common.lines_per_page')}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('common.of')} ${count}`}
                    rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                    component="div"
                    count={allData ? allData.rows.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </Container>
    );
};

export default CustomTable;
